import { components } from "react-select";
import React from 'react';
import _ from 'lodash';
import moment from "moment";
import Form from 'react-bootstrap/Form';
import { normalizeAttributeName, normalizeChildDatasetName } from "../../utils/attribute_name_utils";
import {MODEL_TYPES} from "../../utils/constant";
// import { json } from "d3";

let MULTI_CLASSIFICATION_EVALUATION =  [{"label":"Confusion matrix", "value":'cMatrix',"type": "all_evaluation"},
                                  {"label":"False Negative", "value":'FN',"type": "all_evaluation"},
                                  {"label":"False Positive", "value":'FP',"type": "all_evaluation"},
                                  {"label":"True Negative", "value":'TN', "type": "all_evaluation"},
                                  {"label":"True Positive", "value":'TP', "type": "all_evaluation"},
                                  ]

let BINARY_CLASSIFICATION_EVALUATION =  [{"label":"Confusion matrix", "value":'cMatrix',"type": "all_evaluation"},
                                  {"label":"False Negative", "value":'FN',"type": "all_evaluation"},
                                  {"label":"False Positive", "value":'FP',"type": "all_evaluation"},
                                  {"label":"True Negative", "value":'TN', "type": "all_evaluation"},
                                  {"label":"True Positive", "value":'TP', "type": "all_evaluation"},
                                  {"label":"Area Under ROC", "value":'AUR', "type": "all_evaluation"},
                                  {"label":"Area Under PR", "value":'AUPR', "type": "all_evaluation"},
                                  {"label":"Area Under ROC Error", "value":'AURE', "type": "all_evaluation"},
                                  {"label":"Area Under PR Error", "value":'AUPE', "type": "all_evaluation"}]

let MULTI_CLASSIFICATION_ACCURACY = [ {"label":"Accuracy", "value":'accuracy', "type": "all_accuracy"},
                                {"label":"F1 score", "value":'f1score', "type": "all_accuracy"},
                                {"label":'Precision vs Recall', "value":'precisionVsRecall', "type": "all_accuracy"},
                                {"label": "Sensitivity vs Specificity", "value": "sensitivityVsSpecificity", "type": "all_accuracy"},
                                {"label":'Weighted F(1) Score', "value":'weightedF1Score', "type": "all_accuracy"},
                                {"label":'Weighted Precision', "value":'weightedPrecision', "type": "all_accuracy"},
                                {"label":'Weighted Recall', "value":'weightedRecall',"type": "all_accuracy"},
                                {"label":"Matthews Correlation Coefficient", "value":'Matthews correlation coefficient', "type": "all_accuracy"}
                              ]
let BINARY_CLASSIFICATION_ACCURACY = [ {"label":"Accuracy", "value":'accuracy', "type": "all_accuracy"},
                                {"label":"F1 score", "value":'f1score', "type": "all_accuracy"},
                                {"label":'Precision vs Recall', "value":'precisionVsRecall', "type": "all_accuracy"},
                                {"label": "Sensitivity vs Specificity", "value": "sensitivityVsSpecificity", "type": "all_accuracy"},
                                {"label":'Weighted F(1) Score', "value":'weightedF1Score', "type": "all_accuracy"},
                                {"label":'Weighted Precision', "value":'weightedPrecision', "type": "all_accuracy"},
                                {"label":'Weighted Recall', "value":'weightedRecall',"type": "all_accuracy"},
                                {"label":"Matthews Correlation Coefficient", "value":'Matthews correlation coefficient', "type": "all_accuracy"}
                              ]

let REGRESSION = [{"label": "Mean Squared Error", "value": "mse", "type": "all_accuracy"},
              {"label": "Mean Absolute Error", "value": "mae", "type": "all_accuracy"},
              {"label": "Root Mean Squared Error", "value": "rmse", "type": "all_accuracy"},
              {"label": "R-squared", "value": "r2", "type": "all_accuracy"},
              {"label": "Spearman Correlation Coefficient", "value": "Spearman correlation coefficient", "type": "all_accuracy"},
              {"label": "Pearson's Correlation Coefficient", "value": "Pearson's correlation coefficient", "type": "all_accuracy"},]

let RECOMMENDATION = [{"label": "Mean Squared Error", "value": "mse", "type": "all_accuracy"},
              {"label": "Mean Absolute Error", "value": "mae", "type": "all_accuracy"},
              {"label": "Root Mean Squared Error", "value": "Rmse", "type": "all_accuracy"},
              {"label": "R-squared", "value": "r2", "type": "all_accuracy"},
              {"label": "Spearman Correlation Coefficient", "value": "Spearman correlation coefficient", "type": "all_accuracy"},
              {"label": "Pearson's Correlation Coefficient", "value": "Pearson's correlation coefficient", "type": "all_accuracy"},
              {"label": "Mean Average Recall @K", "value": "Mean Average Recall @K", "type": "all_accuracy"},
              {"label": "Mean Average Precision @K", "value": "Mean Average Precision @K", "type": "all_accuracy"}]

let CLUSTERING_WITHOUT_RESPONSE_DATA = [{"label": "Silhouette Score", "value": "silhouetteScore", "type": "all_accuracy"},
              {"label": "Davies Bouldin Score", "value": "daviesBouldinScore", "type": "all_accuracy"},
              {"label": "Calinski Harabasz Score", "value": "calinskiHarabaszScore", "type": "all_accuracy"}]

let CLUSTERING_WITH_RESPONSE_DATA = [{"label": "Silhouette Score", "value": "silhouetteScore", "type": "all_accuracy"},
              {"label": "Davies Bouldin Score", "value": "daviesBouldinScore", "type": "all_accuracy"},
              {"label": "Calinski Harabasz Score", "value": "calinskiHarabaszScore", "type": "all_accuracy"},
              {"label": "Adjusted Rand Score", "value": "adjustedRandScore", "type": "all_accuracy"},
              {"label": "Fowlkes Mallows Score", "value": "fowlkesMallowsScore", "type": "all_accuracy"},
              {"label": "Rand Score", "value": "randScore", "type": "all_accuracy"},
              {"label": "Adjusted Mutual Info Score", "value": "adjustedMutualInfoScore", "type": "all_accuracy"},
              {"label": "Homogeneity Completeness V-measure Score", "value": "homogeneityCompletenessV-measureScore", "type": "all_accuracy"},
              {"label": "Normalized Mutual Info Score", "value": "normalizedMutualInfoScore", "type": "all_accuracy"}]

export function getOptions(selectedModel=[], customMetrics=[]) {
       let options = []
       let modelType = ""
       let modelList = []
       let isMPCompareView = this.props.type === "modelPerformanceCompView";
       if(this.props.type === "modelPerformance" || isMPCompareView) {
          let accuracyMetrics = [{"label": "Model Accuracy Metrics", "value":"all_accuracy","type": "all_accuracy"}]
          let evaluationMetrics = [{"label": "Model Evaluation Metrics", "value":"all_evaluation","type": "all_evaluation"},]
          let modelRuntime = [{"label": "Model Runtime-Profile Metrics", "value": "model_runtime", "type": "model_runtime"},]
          
          for(let i=0;i<selectedModel.length;i++){
              modelType = selectedModel[i]["type"]
              if(modelList.includes(modelType)) {
                continue
              }
              modelList.push(modelType)
              if(modelType === MODEL_TYPES.MULTI_CLASS_CLASSIFICATION) {
                accuracyMetrics = accuracyMetrics.concat(MULTI_CLASSIFICATION_ACCURACY)
                evaluationMetrics = evaluationMetrics.concat(MULTI_CLASSIFICATION_EVALUATION)
              }else if(modelType === MODEL_TYPES.BINARY_CLASSIFICATION) {
                accuracyMetrics = accuracyMetrics.concat(BINARY_CLASSIFICATION_ACCURACY)
                evaluationMetrics = evaluationMetrics.concat(BINARY_CLASSIFICATION_EVALUATION)
              }else if(modelType === MODEL_TYPES.REGRESSION) {
                accuracyMetrics = accuracyMetrics.concat(REGRESSION)
              }else if(modelType === MODEL_TYPES.RECOMMENDATION){
                accuracyMetrics = accuracyMetrics.concat(RECOMMENDATION)
              }else if(modelType === MODEL_TYPES.CLUSTERING_MODEL_WO_RESPONSE){
                accuracyMetrics = accuracyMetrics.concat(CLUSTERING_WITHOUT_RESPONSE_DATA)
              }else if(modelType === MODEL_TYPES.CLUSTERING_MODEL_WITH_RESPONSE){
                accuracyMetrics = accuracyMetrics.concat(CLUSTERING_WITH_RESPONSE_DATA)
              }
          }

          let modelMonitoringData = this.props.modelMonitoringData;
          for(let i=0;i<modelMonitoringData.length;i++) {
             let data = modelMonitoringData[i]
             let filtered = selectedModel.filter(x=>Number(x.value) === Number(data["ml_model_id"]))
             let name = data["name"]
             if(filtered.length > 0 && name !== null && name !== undefined) {
               let labelName = _.startCase(name)
               modelRuntime.push({"label": labelName, "value": data["name"], "type": "model_runtime"})
             }
          }
          options = options.concat(accuracyMetrics)
          
          // For Model Performance Compare View, no need to show Evaluation metrics in Advanced filter
          if(evaluationMetrics.length > 1 && !isMPCompareView) {
            options = options.concat(evaluationMetrics)
          }

          options = options.concat(modelRuntime)
       }else {
          let issueMetrics = [{"label": "Key 'Model Performance Metrics' Crossing Thresholds", "value": "type1", "type": "type1"}]
          let c_metrics = [];
          for(let i=0;i<selectedModel.length;i++){
              modelType = selectedModel[i]["type"]
              if(modelList.includes(modelType)) {
                continue
              }
              let custom = customMetrics.filter(x=>Number(x.ml_model_id) === Number(selectedModel[i]["value"]))
              for (let met of custom){
                    c_metrics.push({"label":met.name, "value":met.name, "type": "type8"});
              }

              modelList.push(modelType);
              if([MODEL_TYPES.MULTI_CLASS_CLASSIFICATION, 
                  MODEL_TYPES.BINARY_CLASSIFICATION].includes(modelType)) {
                issueMetrics = issueMetrics.concat([{"label":"Accuracy", "value":'accuracy', "type": "type1"},
                                {"label":"F1 Score", "value":'f1score', "type": "type1"},
                                {"label":'Precision', "value":'precision', "type": "type1"},
                                {"label":'Recall', "value":'recall', "type": "type1"},])
              }else if(modelType === "regression") {
                issueMetrics = issueMetrics.concat([{"label":'Root Mean Squared Error', "value":'Rmse', "type": "type1"}])
              }else if(modelType === "recommendation"){
                issueMetrics = issueMetrics.concat([{"label": "Mean Squared Error", "value": "mse", "type": "type1"},
              {"label": "Root Mean Squared Error", "value": "Rmse", "type": "type1"},
              {"label": "Mean Average Recall @K", "value": "Mean Average Recall @K", "type": "type1"},
              {"label": "Mean Average Precision @K", "value": "Mean Average Precision @K", "type": "type1"}])
              }
          }

          options = issueMetrics.concat([{"label": "Consistency Errors - Serving data vs Training data", "value": "type2", "type": "type2"},
                                {"label": "Feature Drifts - Entire Serving dataset", "value": "type3", "type": "type3"},
                                {"label": "Feature drifts - By Serving data Attributes", "value": "type4", "type": "type4"},
                                {"label": "Feature drifts - Serving data vs Training data", "value": "type5", "type": "type5"},
                                {"label": "Prediction Metrics - Drifts", "value": "type6", "type": "type6"},
                                {"label": "Business Response Metrics - Drifts", "value": "type7", "type": "type7"}
                               ]);
         if(customMetrics.length > 0 && c_metrics.length>0){
            options = options.concat([{"label": "Custom ML Metrics - Drifts", "value": "type8", "type": "type8"}]);
            options=options.concat(c_metrics);
          }
       }
       options = options.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value && t.type===v.type))===i)
       if (this.props.type === "modelPerformance"){
           let modelExplainability = [{"label": "Model Explainability", "value":"model_explainability","type": "model_explainability"},
                                    {"label": "Feature Contribution Value - Attribute Level", "value" : "mean_shap_value_attribute_level","type": "model_explainability"},
                                    {"label": "Feature Contribution Value - Dataset Level", "value" : "mean_shap_value_ds_level","type": "model_explainability"},
                                    {"label": "Feature Contribution Value - Compare view", "value" : "mean_shap_value_combined_level","type": "model_explainability"}]
            options = options.concat(modelExplainability)
       }
       return options

}

export const typeMappings = {
       "Accuracy": "Accuracy",
       "Mean Average Recall @K": "Mean Average Recall @K",
       "Mean Average Precision @K": "Mean Average Precision @K",
       "F1 Score": "F1 Score",
       "Precision": "Precision",
       "Recall": "Recall",
       "Rmse": "Root Mean Squared Error",
       "ML Data Consistency": "Consistency Errors - Serving data vs Training data",
       "Serving Data Drift": "Feature Drifts - Entire Serving dataset",
       "Feature Drift in Serving Data": "Feature drifts - By Serving data Attributes",
       "Number of rows in serving data drifted from training data":"Feature drifts - Serving data vs Training data",
       "Number of rows in serving data drifting from training data exceeds threshold": "Feature drifts - Serving data vs Training data",
       "% drift between serving and training attribute":"Feature drifts - Serving data vs Training data",
       "% drift between serving and training attribute exceeds threshold": "Feature drifts - Serving data vs Training data",
       "Prediction Drift": "Prediction Metrics - Drifts",
       "Response Drift": "Business Response Metrics - Drifts",
       "Custom Metrics": "Custom ML Metrics - Drifts",
}
export const Option = props => {
  return (
    <div className={props.data.type === undefined || props.data.value === props.data.type || props.selectProps.name === "model" ? "parent-checkbox" : "child-checkbox"}>
      <components.Option {...props}>
        {/* <input
          type="checkbox"
          className={props.type === undefined || props.value === props.type ? "parent-checkbox" : "child-checkbox"}
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label> */}
        <div className="custom-control custom-checkbox custom-control-inline">
            <Form.Control
               type="checkbox"
               id={props.value}
               className="custom-control-input"
               checked={props.isSelected}
               onChange={() => null}
            />{" "}
            <Form.Label className="custom-control-label">{props.label}</Form.Label>
         </div>
      </components.Option>
    </div>
  );
};

export function changeDataSourceAdvanced(dataSourceOption) {
           let datasourceId = dataSourceOption.value;
           let datasets = []

           for (const [key, value] of Object.entries(this.props.mappedDatasetInfo["idDatasetDetail"])) {
                     if(Number(value["dataSourceId"]) === Number(datasourceId)) {
                        datasets.push({"label": normalizeChildDatasetName(value["datasetName"]), "value": key});
                     }
            }


            this.setState({datasets: datasets,
                           attributeOptions: [],
                           originalAttributeOptions: [],
                           selectedDatasource: dataSourceOption,
                           selectedAttributes: [],
                           selectedDataSet: [] });
      }

export function handleChangeDataset(event) {
             let attributes = []
             let groupedlist = [];
             if(event !== null) {
                event.forEach(dataset => {
                      this.props.mappedDatasetInfo.attributeIds.forEach(attribute => {
                              if(attribute["datasetId"] === dataset.value){
                                let reqAttributeName = attribute["attributeName"];
                                reqAttributeName = normalizeAttributeName(reqAttributeName);
                                 attributes.push({"label": reqAttributeName, "value": attribute["attributeId"],"datasetName": normalizeChildDatasetName(attribute["datasetName"])})
                              }

                       });

                });

                let grouped = _.mapValues(_.groupBy(attributes, 'datasetName'));
               for (let [key,value] of Object.entries(grouped)) {
                  groupedlist.push({'label':key,'options':value});
               }   

             }

             this.setState({attributeOptions: groupedlist, originalAttributeOptions: attributes, selectedDataSet: event, selectedAttributes: []});

      }

export function changeAttribute(option){
            this.setState({selectedAttributes: option});
      }

export function changeMetric(option){
            this.setState({selectedMetrics: option});
      }

export function getFilteredData(data, startDate, endDate, considerEndAlone=false) {
          let updatedThumbnailCharts = []
          for (let _singleThumbnailData of data){
                        let name = _singleThumbnailData.name;
                        let filteredMetrics = this.state.selectedMetrics.filter(x=>x.label === name)
                        if(filteredMetrics.length === 0) {
                          continue
                        }
                        const actChartData = _.cloneDeep(_singleThumbnailData.chartData);
                        let timeArr = actChartData.time;
                        let valArr = actChartData.values;
                        let driftPatterns = actChartData.drift_patterns;
                        let newValues = []
                        let newDrift = []
                        if (timeArr === undefined) {
                            continue;
                        }
                        let filteredArr
                        if(considerEndAlone) {
                           filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(endDate));
                        }else {
                           filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(endDate) && moment(x).isSameOrAfter(startDate));
                        }

                        const filteredLength = filteredArr.length;
                        if (filteredLength === 0) {
                            continue;
                        }
                        let startIndex = timeArr.indexOf(filteredArr[0]);
                        let endIndex = timeArr.indexOf(filteredArr[filteredLength - 1]);
                        for(let i=startIndex;i<=endIndex;i++){
                           newValues.push(valArr[i])
                           if(driftPatterns !==undefined) {
                             newDrift.push(driftPatterns[i])
                           }
                        }
                        if(filteredLength === 1) {
                           newValues.push(valArr[startIndex])
                           if(driftPatterns !==undefined) {
                             newDrift.push(driftPatterns[startIndex])
                           }
                        }
                        actChartData.time = filteredArr;
                        actChartData.dq_chart_points = newValues;
                        actChartData.values = newValues;
                        actChartData.drift_patterns = newDrift;
                        _singleThumbnailData.chartData = actChartData;
                        updatedThumbnailCharts.push(_singleThumbnailData);
                    }
        return updatedThumbnailCharts
      }

export function handleSubmit(event){
                this.setState({showDropDown: false});
                let filteredData = [];
                let clonedData = _.cloneDeep(this.state.completeTableData)
                
                for (let rowData of clonedData) {
                    let previousTime = null;
                    let thumbnailData = rowData.find(x => x.type === "thumbnailCharts");
                    let datasetName = rowData.find(x => x.type === "datasetName");
                    let detailedCharts = rowData.find(x => x.type === "action")
                    let datasetId = detailedCharts.value;
                    let attributeId = detailedCharts.attributeId;

                    if(datasetName !== undefined) {
                       // For filtering dataset rows
                       let selectedDataSet = this.state.selectedDataSet.filter(x=>Number(x.value) === Number(datasetId))
                       if(selectedDataSet.length === 0) {
                          continue
                       }
                    }else if(this.state.showAttributes === false){
                        continue

                    }else {
                       // For filtering attribute rows
                       let selectedAttributes = this.state.selectedAttributes.filter(x=>Number(x.value) === Number(attributeId))
                       if(selectedAttributes.length === 0) {
                          continue
                       }
                    }

                    let createdTime = moment(thumbnailData.createdTime).startOf("day").toDate();
                    let noDataAvailable = moment(this.state.startDate).isBefore(createdTime) && moment(this.state.endDate).isBefore(createdTime)

                    if(noDataAvailable === true) {
                        continue;
                    }


                    const thumbnailVal = thumbnailData.value;

                    let issuedAttributes = thumbnailData.issuedAttributes;

                    for (let _cData of thumbnailVal) {
                        let updatedThumbnailCharts = this.getFilteredData(_cData.data,this.state.startDate,this.state.endDate);
                        if(updatedThumbnailCharts.length === 0 || (updatedThumbnailCharts.length === 1 && updatedThumbnailCharts[0]["name"] === "Recency")) {
                           updatedThumbnailCharts = this.getFilteredData(_cData.data, this.state.startDate, this.state.endDate, true)
                           if(issuedAttributes !== undefined) {
                              let previousTimeArr = issuedAttributes.filter(x => moment(x.refresh_time).isSameOrBefore(this.state.endDate))
                              if(previousTimeArr.length > 0) {
                                      previousTime = previousTimeArr[0]["refresh_time"]
                              }

                            }


                        }
                         _cData.data = updatedThumbnailCharts;
                    }

                    if(datasetName !== undefined) {
                        datasetName["previousTime"] = previousTime
                        thumbnailData.noDataAvailable = noDataAvailable;
                    }
                    if(this.state.showpartitions !== true){
                      let check = this.props.filterData.parentDatasets.filter((data)=> 
                          parseInt(data.value) === parseInt(datasetId)
                      ).length;
                      if(check > 0){
                        filteredData.push(rowData);
                      }
                     }else {
                      filteredData.push(rowData);
                    }
              }
              this.setState({dataFilteredValues: filteredData});
     }

export function handleCheckClick() {
         this.setState({showAttributes: !this.state.showAttributes})
      }

export function showDatasets() {
    this.setState({showDatasets: !this.state.showDatasets});
}

export function handleTime(event, picker) {
          let startDate = picker.startDate;
          let endDate = picker.endDate;
          this.setState({startDate: startDate, endDate: endDate});
      }


export function getMetricsOption(selectedDataSet,pmallflag = 0,allchecked=0) {
       let metricsOption = []
       let checked = ""
       if(allchecked === -1){
        checked = true
       }
       if(pmallflag===1){
        metricsOption.push({"label":"All","value":"*","checked":checked})
       }
       this.props.metricsList.forEach(metric => {

          if(metric.metric_name === "Unique Values"){
            metric['metric_name'] = "Uniqueness"
          }
          if(metric.value !=="*"){
            if(metric.mas_type === "dqm" && metric.data_set_id === null) {
              if(allchecked ===1){
                  if(metric.mas_name === this.props.metricType){
                    checked = true
                  }else {
                    checked = ""
                  }
              }
              metricsOption.push({"label": metric.metric_name, "value": metric.metrics_id, "group": metric.mas_name, "checked":checked})
            }else if(metric.mas_type === "dqm" && metric.data_set_id !== null && selectedDataSet!== undefined){
              if(selectedDataSet.length > 0){ 
              let filtered = selectedDataSet.filter(x=>Number(x.value) === Number(metric.data_set_id))
              if(filtered.length > 0) {
                //group === this.props.metricType
                if(allchecked ===1){
                  if(metric.mas_name === this.props.metricType){
                    checked = true
                  }else {
                    checked = ""
                  }
                }
                metricsOption.push({"label": metric.metric_name, "value": metric.metrics_id, "group": metric.mas_name, "checked":checked})
              }
              }
            }
          }
       });

   ///   metricsOptions.filter(x=>x.group === this.props.metricType)

       return metricsOption
    }


export function getPlaceholder(defaultText, selected, data) {
  if (selected === undefined || selected === null) {
    return "";
  }

  if (selected.length === 0) {
    return defaultText
  } else if (selected.length > data.length) {
    return "All selected"
  } else {
    return selected.length + " selected"
  }

}