import React from "react";
import $ from 'jquery';
class WarningMessageHeader extends React.Component {

    hidebar(){
        $('.global-notifications-bar').addClass('d-none');
    }
    render() {

        return (
            (this.props.warning_message !== null && this.props.warning_message !== undefined) &&
            <div className="global-notifications-bar">
                <span className="global-notification-text">
                  <div className="enable-notification-banner-body">{this.props.warning_message}</div>
                </span>
                <div className="global-notification-actions">

                </div>
                <div className="global-notification-btn">
                  {this.props.quick_start===true
                  ?
                  <button type="button" className="close" onClick={this.hidebar}></button>
                  :
                  <button type="button" className="close" onClick={()=>this.props.closeWarning()}></button>
                  }
                </div>
            </div>
        );
    }
}

export default WarningMessageHeader;




