import React from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {postDatasource}  from "../../../../utils/event_handling";
import {userValidation} from "../../../../utils/common_utils"
import {TEST_CONNECTION_MESSAGES, VERSION_FORMATS, FILE_TYPE_FORMATS} from "../../../../utils/constant";
import {  faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';




class S3Form extends React.Component {
        constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postDatasource = postDatasource.bind(this);
        this.postS3Data = this.postS3Data.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeAccessKeyIcon = this.ChangeAccessKeyIcon.bind(this);
        this.state = {
            formType_option:null,
            isChecked: true,
            hideSubmitButton: false,
            fileType: null,
            selectOptionKey:"emptyFileType",
            integration_data: this.props.integration_data,
            refreshType: {"label":"File Replace", "value":"file_replace"},
            versionFormat: null,
            versionOccurence: {"label":"prefix", "value":"prefix"},
            accessKeyIcon:faEye,
            accessKeyType:'password',
        };
        }

        componentDidMount() {
        this.fill_details();
        }

        toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
        }

        fill_details() {
            if (this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) {
                let integration_data =  this.state.integration_data;
                integration_data.integration_name = this.props.ex_integration_details["integration_name"];
                integration_data.integration_id = this.props.ex_integration_details["integration_id"];
                integration_data.bucket_name = this.props.ex_integration_details.integration_config.bucket;
                integration_data.sub_path = this.props.ex_integration_details.integration_config.sub_path;
                integration_data.error_storage = this.props.ex_integration_details.integration_config.error_storage;
                let fileType = this.props.ex_integration_details.integration_config.filetype;
                integration_data.fileType = fileType
                let refreshType = this.props.ex_integration_details.integration_config.refresh_type;
                integration_data.refreshType = refreshType;
                let versionType = this.props.ex_integration_details.integration_config.version_type;
                let versionFormat = this.props.ex_integration_details.integration_config.version_format;
                let versionOccurence = this.props.ex_integration_details.integration_config.version_occurence;
                if (versionType !== "custom") {
                    integration_data.versionFormat = versionFormat;
                    this.setState({versionFormat: {"label": versionType, "value": versionFormat}});
                } else {
                    integration_data.versionFormat = versionType;
                    integration_data.versionFormatRegex = versionFormat;
                    this.setState({versionFormat: {"label": versionType, "value": versionType}});
                }
                let defaultErrorStorage = this.props.ex_integration_details.integration_config.default_error_storage;
                if (defaultErrorStorage === undefined) {
                    defaultErrorStorage = true;
                }
                this.setState({isChecked: defaultErrorStorage,
                            selectOptionKey: "updatedFileType",
                            fileType: {"label": fileType, "value": fileType},
                            versionOccurence: {"label": versionOccurence, "value": versionOccurence},
                            refreshType: {"label": FILE_TYPE_FORMATS[refreshType], "value": refreshType}});

                if (this.props.formType === "s3"){
                    integration_data.accessKey = this.props.ex_integration_details.auth_config.access_key;
                    integration_data.secretAccessKey = this.props.ex_integration_details.auth_config.secret_access_key;
                }

                this.setState({
                            integration_type: this.props.formType,
                            integration_data: integration_data
                            });
            }

        }

        postS3Data(event) {
            this.disableButton();
            const {integration_name,error_storage, accessKey, secretAccessKey,sub_path,bucket_name,
            fileType, versionFormat, versionFormatRegex} = event;
            let version_format;
            let version_type;
            if (versionFormat === "custom") {
                version_format = versionFormatRegex;
                version_type = "custom";
            } else {
                version_format = VERSION_FORMATS[versionFormat];
                version_type = versionFormat;
            }
            let s3Data = {"integration_name":integration_name,
                                    "integration_id":this.state.integration_data.integration_id,
                                    "data_store_type":"filesystem",
                                    "error_storage": error_storage,
                                    "bucket_name": bucket_name,
                                    "sub_path": sub_path,
                                    "filetype": fileType,
                                    "refresh_type": this.state.refreshType.value,
                                    "version_format": version_format,
                                    "version_type": version_type,
                                    "version_occurence": this.state.versionOccurence.value,
                                    "env_id":this.props.env_id
                            }
                s3Data["integration_type"] = "s3"
                s3Data["access_key"] = accessKey;
                s3Data["secret_access_key"] = secretAccessKey;
                s3Data["isFirstIntegration"] = this.props.isFirstIntegration;

            let default_message = TEST_CONNECTION_MESSAGES["s3"];
            this.props.closeTestConnection();
            this.props.setSubmitButton(true);
            this.props.setData(s3Data, default_message);
            this.postDatasource(s3Data);
        }

        getSchema() {
            const subPath = RegExp(/^(?!\/)/);
            let s3Schema = '';
            if(this.props.formType === "s3" && this.state.isChecked === true &&
            this.state.refreshType.value ==="file_replace") {
                    s3Schema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                            let val = userValidation(value,"input");       
                            return val;
                    }
                    ),
                        secretAccessKey: yup.string().required('Secret access key is required'),
                        accessKey: yup.string().required('access key is required'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        fileType: yup.string().required('File type is a required field')
                    });
            }else if(this.props.formType === "s3" && this.state.isChecked === false &&
            this.state.refreshType.value ==="file_replace") {
                    s3Schema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                            let val = userValidation(value,"input");       
                            return val;
                    }
                    ),
                        secretAccessKey: yup.string().required('Secret access key is required'),
                        accessKey: yup.string().required('access key is required'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        error_storage: yup.string().required('Error Storage is a required field'),
                        fileType: yup.string().required('File type is a required field')
                    });
            }else if (this.props.formType === "s3" && this.state.isChecked === true &&
            this.state.refreshType.value !=="file_replace") {
                    s3Schema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                            let val = userValidation(value,"input");       
                            return val;
                    }
                    ),
                        secretAccessKey: yup.string().required('Secret access key is required'),
                        accessKey: yup.string().required('access key is required'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        fileType: yup.string().required('File type is a required field'),
                        versionFormat: yup.string().required('Refresh Format is a required field')
                    });
            }else if(this.props.formType === "s3" && this.state.isChecked === false &&
                this.state.refreshType.value !=="file_replace") {
                    s3Schema = yup.object({
                    integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                        'DatasourceName test',
                        'invalid Datasource name',        
                        function test(value) {        
                            let val = userValidation(value,"input");       
                            return val;
                    }
                    ),
                        secretAccessKey: yup.string().required('Secret access key is required'),
                        accessKey: yup.string().required('access key is required'),
                        bucket_name: yup.string().required('Bucket Name is a required field'),
                        sub_path : yup.string().matches(subPath, 'Invalid Sub Path'),
                        error_storage: yup.string().required('Error Storage is a required field'),
                        fileType: yup.string().required('File type is a required field'),
                        versionFormat: yup.string().required('Refresh Format is a required field')
                    });
            }

            return s3Schema;
        }

        disableButton() {
            this.setState({hideSubmitButton: true});
        }

        ChangeAccessKeyIcon() {
            let state = this.state.accessKeyIcon;
            if (state.iconName === "eye") {
                this.setState({accessKeyIcon: faEyeSlash});
                this.setState({accessKeyType: "text"})
                } else {
                this.setState({accessKeyIcon: faEye});
                this.setState({accessKeyType: "password"})
                }
        }

        render() {
            let s3Schema = this.getSchema();
            let initial_values = '';
            let file_option = [
                    {"label":"csv", "value":"csv"},
                    {"label":"json", "value":"json"},
                    {"label":"parquet", "value":"parquet"},
                    {"label":"avro", "value":"avro"},
                    {"label":"excel", "value":"excel"}
                ];
            let refresh_option = [
                {"label":"File Replace", "value":"file_replace"},
                {"label":"File Version", "value":"file_version"},
                {"label": "Folder Version", "value":"folder_version"}
            ];
            let version_option = [
                {"label":"dd-mm-yyyy", "value":"dd-mm-yyyy"},
                {"label":"yyyy-mm-dd", "value":"yyyy-mm-dd"},
                {"label": "dd-mm-yy", "value":"dd-mm-yy"},
                {"label": "custom", "value": "custom" }
            ];
            let occurence_options = [
            {"label":"prefix", "value":"prefix"},
            {"label":"suffix", "value":"suffix"}
        ]
            initial_values = {integration_name:'',error_storage: '',accessKey: '', secretAccessKey: '',sub_path: '',
            bucket_name: '',fileType: '', refreshType: '', versionFormat: '', versionOccurence: ''}

            let edit_integration = null;
            if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined){
                edit_integration = true;
            }
            else{
                edit_integration = false;
            }
            return (
                    <>
                        <Formik
                                validationSchema={s3Schema}
                                onSubmit={this.postS3Data}
                                validateOnBlur={false}
                                initialValues={(this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined) ? this.state.integration_data : initial_values}
                        >
                        {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                        }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Form.Group controlId="integration_name" className="form-group col-md-6">
                                <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="integration_name"
                                        value={values.integration_name === undefined ? "" : values.integration_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.integration_name && touched.integration_name}
                                        placeholder="Datasource Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                            { touched.integration_name && errors.integration_name }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="bucket_name" className="col-md-6">
                                            <Form.Label>Bucket Name <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                name="bucket_name"
                                                value={values.bucket_name === undefined ? "" : values.bucket_name}
                                                disabled={edit_integration ? true : false}
                                                onChange={handleChange}
                                                placeholder="Bucket Name"
                                                onBlur={handleBlur}
                                                isInvalid={errors.bucket_name && touched.bucket_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            { touched.bucket_name && errors.bucket_name }
                                            </Form.Control.Feedback>
                                    </Form.Group>
                            <Form.Group controlId="sub_path" className="col-md-6">
                                    <Form.Label>Sub Path </Form.Label>
                                    <Form.Control
                                            type="text"
                                            className="form-control"
                                            placeholder="Sub Path"
                                            value={values.sub_path === undefined ? "" : values.sub_path}
                                            disabled={edit_integration ? true : false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="sub_path"
                                            isInvalid={errors.sub_path}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.sub_path }
                                        </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="col-md-6">
                                        <Form.Label>File Type <span className="text-danger">*</span></Form.Label>
                                            <Select name="fileType"
                                                    filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                                    onChange={selectedOption => {
                                                                handleChange("fileType")(selectedOption.value);
                                                    }}
                                                    key={this.state.selectOptionKey}
                                                    isInvalid={errors.fileType && touched.fileType}
                                                    id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "s3FileTypeEdit":"s3FileTypeAdd"}
                                                    options={file_option}
                                                    defaultValue={this.state.fileType}
                                                    placeholder="Choose file type"
                                                    classNamePrefix='select-control'
                                            />
                                        <ErrorMessage component="div" className="error-text" name="fileType" />
                                </Form.Group>
                                <Form.Group controlId="accessKey" className="col-md-6">
                                    <Form.Label>Access key <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                            type="text"
                                            className="form-control"
                                            name="accessKey"
                                            value={values.accessKey === undefined ? "" : values.accessKey}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder='Access key'
                                            isInvalid={errors.accessKey && touched.accessKey}
                                    />
                                        <Form.Control.Feedback type="invalid">
                                        { touched.accessKey && errors.accessKey }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="secretAccessKey" className="col-md-6">
                                    <Form.Label>Secret Access key <span className="text-danger">*</span></Form.Label>
                                    <InputGroup bsPrefix="icon-input-group">
                                        <i>
                                            <FontAwesomeIcon icon={faLock}/>
                                        </i>
                                        <Form.Control
                                            type={this.state.accessKeyType}
                                            className="form-control"
                                            name="secretAccessKey"
                                            value={values.secretAccessKey === undefined ? "" : values.secretAccessKey}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder='Secret Access key'
                                            isInvalid={errors.secretAccessKey && touched.secretAccessKey}
                                            autoComplete={this.props.autocomplete}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        { touched.secretAccessKey && errors.secretAccessKey }
                                        </Form.Control.Feedback>
                                    
                                        <i className="security-eye">
                                            <FontAwesomeIcon onClick={this.ChangeAccessKeyIcon} icon={this.state.accessKeyIcon} className="security-eye"/>
                                        </i>
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group controlId="refreshType" className="col-md-6">
                                    <Form.Label>Refresh Type <span className="text-danger">*</span></Form.Label>
                                    <Select name="refreshType"
                                        filterOption={({label}, query) => label.toLowerCase().includes(query.toLowerCase())}
                                        onChange={selectedOption => {
                                                                    handleChange("refreshType")(selectedOption.value);
                                                                    this.setState ({
                                                                    refreshType: {"label":selectedOption["label"], "value":selectedOption["value"]}});
                                                                    }}
                                        isInvalid={errors.refreshType && touched.refreshType}
                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "s3RefreshTypeEdit" : "s3RefreshTypeAdd"}
                                        key={this.state.selectOptionKey}
                                        defaultValue={this.state.refreshType}
                                        options={refresh_option}
                                        placeholder="Choose refresh type"
                                        classNamePrefix='select-control'
                                    />
                                    <ErrorMessage component="div" className="error-text" name="refreshType" />
                                </Form.Group>
                                { this.state.refreshType !== null && (this.state.refreshType.value === "file_version" || this.state.refreshType.value === "folder_version") ?
                                    <Form.Group controlId="versionFormat"
                                        className="col-md-6">
                                                <Form.Label>Refresh Format <span className="text-danger">*</span></Form.Label>
                                                <Select name="versionFormat"
                                                            filterOption={({label}, query) => label.includes(query)}
                                                            onChange={selectedOption => {
                                                                    this.setState ({
                                                                    versionFormat: {"label": selectedOption["value"]    ,"value": selectedOption["value"]}});
                                                                    handleChange("versionFormat")(selectedOption.value);}}
                                                            isInvalid={errors.versionFormat && touched.versionFormat}
                                                            id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "s3VersionTypeEdit" : "s3VersionTypeAdd"}
                                                            key={this.state.selectOptionKey}
                                                            defaultValue={this.state.versionFormat}
                                                            options={version_option}
                                                            placeholder="Choose Refresh Format"
                                                            classNamePrefix='select-control'
                                                />
                                                <ErrorMessage component="div" className="error-text" name="versionFormat" />
                                        </Form.Group> : ""
                                    }
                                    { this.state.versionFormat !== null && (this.state.versionFormat.value === "custom" && this.state.refreshType.value !== "file_replace") ?
                                        <Form.Group controlId="versionFormatRegex" className="col-md-6">
                                                <Form.Label>Custom Refresh Format</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    name="versionFormatRegex"
                                                    value={values.versionFormatRegex === undefined ? "" : values.versionFormatRegex}
                                                    onChange={handleChange}
                                                    placeholder="Enter the custom regex that matches your version pattern here"
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.versionFormatRegex && touched.versionFormatRegex}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                { touched.versionFormatRegex && errors.versionFormatRegex }
                                                </Form.Control.Feedback>
                                        </Form.Group> : ""
                                        }
                                        { this.state.refreshType !== null && (this.state.refreshType.value === "file_version" || this.state.refreshType.value === "folder_version") ?
                                    <Form.Group controlId="versionOccurence" className="col-md-6">
                                            <Form.Label>Refresh format Occurrence <span className="text-danger">*</span></Form.Label>
                                                <Select name="versionOccurence"
                                                        filterOption={({label}, query) => label.includes(query)}
                                                        onChange={selectedOption => {
                                                                    handleChange("versionOccurence")(selectedOption.value);
                                                                    this.setState ({
                                                                    versionOccurence: {"label":selectedOption["label"], "value":selectedOption["value"]}});
                                                                    }}
                                                        isInvalid={errors.versionOccurence && touched.versionOccurence}
                                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "s3OccurenceTypeEdit" : "s3OccurenceTypeAdd"}
                                                        key={this.state.selectOptionKey}
                                                        defaultValue={this.state.versionOccurence}
                                                        options={occurence_options}
                                                        placeholder="Choose Refresh format Occurrence"
                                                        classNamePrefix='select-control'
                                                />
                                            <ErrorMessage component="div" className="error-text" name="versionOccurence" />
                                    </Form.Group> : ""
                                }

                            <div className="col-md-12 py-2">
                                    <div className="custom-control custom-checkbox">
                                        <Form.Control
                                            type="checkbox"
                                            name="errorColumn"
                                            className="custom-control-input"
                                            onChange={this.toggleChange}
                                            checked={this.state.isChecked}
                                        />
                                        <Form.Label className="custom-control-label">Store Data Issues and Errors in Qualdo™</Form.Label>
                                    </div>
                            </div>
                            {this.state.isChecked === false ?
                            <Form.Group controlId="error_storage" className="col-md-6">
                                <Form.Label>Error File Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="error_storage"
                                        placeholder="Error file Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.error_storage && touched.error_storage}
                                        value={values.error_storage === undefined ? "" : values.error_storage}/>
                                <Form.Control.Feedback type="invalid">
                                            { touched.error_storage && errors.error_storage }
                                </Form.Control.Feedback>
                                </Form.Group> : ''}
                        </div>
                        <hr className="clearfix"/>
                        <div className="text-right">
                                {this.props.showCancel ?
                                    <button type="button"
                                            onClick={() => this.props.ex_integration_details ? this.props.closeModal() : this.props.setPage(false)}
                                            className="btn btn-outline btn-grey btn-circle mr-2">
                                        Cancel
                                    </button> : ''
                                }
                                <button type="submit"
                                        disabled={this.state.hideSubmitButton}
                                        className="btn btn-primary btn-circle mr-2">
                                    Test Connection
                                </button>
                        </div>
                        </Form>)}
                        </Formik>
                </>
            );
        }
}
export default S3Form;