import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import EditActions from "./EditActions";
import TeamEditAction from "./teamEditAction";
import {getViewDetailsForMetrics} from "../utils/common_utils";
import {NO_DATA_SET_FOUND_TEXT, NO_DATA_SET_FOUND_TOOLTIP} from "../utils/constant";

export function IntegrationEdit(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [delete_show, setDelete_show] = useState(false);
    const deleteClose = () => setDelete_show(false);
    const deleteShow = () => setDelete_show(true);

    const [view, setView] = useState(false);
    const viewClose = () => setView(false);
    const viewShow = () => setView(true);

    const [connection_show, setConnection_show] = useState(false);
    const connectionClose = () => setConnection_show(false);
    const ConnectionShow = () => setConnection_show(true);

    const integrationDetails = props.integration_details;
    const showTooltip = integrationDetails !== undefined && integrationDetails.status_text === NO_DATA_SET_FOUND_TEXT;
    let tooltipContent = null;
    if (showTooltip) {
        tooltipContent = NO_DATA_SET_FOUND_TOOLTIP;
    }

    return (
        <EditActions
            tooltipContent={tooltipContent}
            view_details={integrationDetails}
            showToast={props.showToast}
            updateTable={props.updateTable}
            component="Datasource"
            delete_component="delete_integration"
            details={props}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            delete_show={delete_show}
            setDelete_show={setDelete_show}
            deleteClose={deleteClose}
            deleteShow={deleteShow}
            connection_show={connection_show}
            setConnection_show={setConnection_show}
            connectionClose={connectionClose}
            ConnectionShow={ConnectionShow}
            view={view}
            setView={setView}
            viewClose={viewClose}
            viewShow={viewShow}
            handleDelete={props.handleDelete}
            setEnvData={props.setEnvData}
            setAddDS={props.setAddDS}
        />
    );
}

export function TeamEdit(props){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [delete_show, setDelete_show] = useState(false);
    const deleteClose = () => setDelete_show(false);
    const deleteShow = () => setDelete_show(true);


    return (
        <TeamEditAction
            showToast={props.showToast}
            component="teamMate"
            delete_component="delete_team_mate"
            details={props}
            userDetails ={props.userDetails}
            ugmDetails= {props.ugmDetails}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            delete_show={delete_show}
            setDelete_show={setDelete_show}
            deleteClose={deleteClose}
            deleteShow={deleteShow}
            handleDelete={props.handleDelete}
        />
    );
}

export function MLModelEdit(props){
    const viewDetails = props.mlData;

    const[show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const[canShowDelWarning, setDeleteWarning] = useState(false);

    const closeDeleteWarning = () => setDeleteWarning(false);
    const showDeleteWarning = () => setDeleteWarning(true);


    const [delete_show, setDelete_show] = useState(false);
    const deleteClose = () => setDelete_show(false);

    const deleteShow = () => {
        const ml_model_id = viewDetails['ml_model_id'];
        const isABConfigured = props.modelHasModelABConfiguration(ml_model_id);
        if (isABConfigured) {
            showDeleteWarning();
            return;
        }

        setDelete_show(true);
    };

    const [view, setView] = useState(false);
    const viewClose = () => setView(false);
    const viewShow = () => setView(true);

    const [modelABState, setModelAB] = useState(false);
    const modelABClose = () => setModelAB(false);
    const modelABShow = () => setModelAB(true);

    const [modelVersionState, setModelVersion] = useState(false);
    const modelVersionClose = () => setModelVersion(false);
    const modelVersionShow = () => setModelVersion(true);

    return (
        <EditActions
            view_details={viewDetails}
            showToast={props.showToast}
            canShowDelWarning={canShowDelWarning}
            closeDeleteWarning={closeDeleteWarning}
            component="Model"
            delete_component="delete_ml_model"
            details={props}
            show={show}
            getExistingABData={props.getExistingABData}
            showModelABPopup={props.showModelABPopup}
            handleModelABAddition={props.handleModelABAddition}
            availableModels={props.availableModels}
            modelVersionMap={props.modelVersionMap}
            modelsDataMap={props.modelsDataMap}
            modelTypeMap={props.modelTypeMap}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            modelABClose={modelABClose}
            modelABShow={modelABShow}
            modelABState={modelABState}
            modelVersionState={modelVersionState}
            modelVersionClose={modelVersionClose}
            modelVersionShow={modelVersionShow}
            delete_show={delete_show}
            setDelete_show={setDelete_show}
            deleteClose={deleteClose}
            deleteShow={deleteShow}
            view={view}
            setView={setView}
            viewClose={viewClose}
            viewShow={viewShow}
            handleDelete={props.handleDelete}
        />
    );

}

export function AlertEdit(props){
    const[show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [delete_show, setDelete_show] = useState(false);
    const deleteClose = () => setDelete_show(false);
    const deleteShow = () => setDelete_show(true);

    const [view, setView] = useState(false);
    const viewClose = () => setView(false);
    const viewShow = () => setView(true);

    return (
        <EditActions
            view_details={props.alert_item}
            dataset={props.dataset}
            showToast={props.showToast}
            component={props.edit_title}
            delete_component="delete_alert"
            details={props}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            delete_show={delete_show}
            setDelete_show={setDelete_show}
            deleteClose={deleteClose}
            deleteShow={deleteShow}
            view={view}
            setView={setView}
            viewClose={viewClose}
            viewShow={viewShow}
            handleDelete={props.handleDelete}
        />
    );

}

export function MetricsEdit(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [delete_show, setDelete_show] = useState(false);
    const deleteClose = () => setDelete_show(false);
    const deleteShow = () => setDelete_show(true);

    const [view, setView] = useState(false);
    const viewClose = () => setView(false);
    const viewShow = () => setView(true);

    const metricsData = props.metrics_data;
    let viewDetails = getViewDetailsForMetrics(metricsData);
    let isActiveMetric = metricsData.status_code === 1;
    const [metricStatus, setMetricStatus] = useState(isActiveMetric);

    React.useEffect(() => {
      setMetricStatus(isActiveMetric);
    }, [isActiveMetric])

    const changeButtonStatus = () => setMetricStatus(!(metricStatus));
    return (
        <EditActions
            unique_key={props.unique_key}
            view_details={viewDetails}
            showToast={props.showToast}
            component="Metric"
            delete_component="delete_metric"
            details={props}
            modalTitle={props.modalTitle}
            show={show}
            setShow={setShow}
            handleDelete={props.handleDelete}
            handleClose={handleClose}
            handleShow={handleShow}
            delete_show={delete_show}
            setDelete_show={setDelete_show}
            deleteClose={deleteClose}
            deleteShow={deleteShow}
            view={view}
            setView={setView}
            viewClose={viewClose}
            viewShow={viewShow}
            followComponent={"metric_status"}
            followStatus={metricStatus}
            updateFollowStatus={changeButtonStatus}
        />
    );

}


export function ConsumeEdit(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [delete_show, setDelete_show] = useState(false);
    const deleteClose = () => setDelete_show(false);
    const deleteShow = () => setDelete_show(true);

    const [view, setView] = useState(false);
    const viewClose = () => setView(false);
    const viewShow = () => setView(true);
    return (
        <EditActions
            view_details={props.consume_item}
            hideModelAlertsTab={props.hideModelAlertsTab}
            showToast={props.showToast}
            component={props.edit_title}
            delete_component={props.delete_case}
            changeTab={props.changeTab}
            details={props}
            show={show}
            pipelineButtons={true}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            delete_show={delete_show}
            setDelete_show={setDelete_show}
            deleteClose={deleteClose}
            deleteShow={deleteShow}
            view={view}
            setView={setView}
            viewClose={viewClose}
            viewShow={viewShow}
            handleDelete={props.handleDelete}
        />
    );

}