import React from 'react';
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import { getErrorMsg }  from "../utils/event_handling";
import {dataRunStatuses} from "../utils/constant";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTable, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {NO_DATA_SET_FOUND_TEXT, QUALDO_DOCUMENTATION} from "../utils/constant";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import {get_document_link} from "../utils/common_utils";
import { normalizeChildDatasetName } from '../utils/attribute_name_utils';





class DatasetError extends React.Component {
    constructor(props) {
        super(props);
        this.handleErrorModal = this.handleErrorModal.bind(this);
        this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
        this.getErrorMsg = getErrorMsg.bind(this);
        let itemVal = null;
        if(props.item["value"] !== undefined && props.item["value"] !== null) {
            itemVal = props.item["value"];
        }
        this.getErrorText = this.getErrorText.bind(this);
        this.state = {
            show: false,
            children: itemVal
        }
    }

    handleErrorModal(item){
        this.setState({show:true,children: item["value"]});
    }
    handleErrorModalClose(){
        this.setState({show:false});
    }

    getErrorText(item) {
        const dataSourceInfo = item.value;
        const noDatasetFound = dataSourceInfo.status_text === NO_DATA_SET_FOUND_TEXT;
        let defaultErrorMessage = "None";
        if (noDatasetFound) {
            defaultErrorMessage = NO_DATA_SET_FOUND_TEXT;
        }

        return defaultErrorMessage;
    }

    render() {
       let errorList = []
        if(this.state.children !== null) {
            if (this.props.componentName === "metrics") {
                if (this.state.children["status_code"] === 0) {
                    errorList.push(<tr><td>{normalizeChildDatasetName(this.state.children["data_set_name"])}</td>
                                             <td>{this.state.children["modified_time"]}</td>
                                           <td>
                                                        <div className="data-progress-status">
                                                            <label className="text-danger">Failed</label>
                                                            <ProgressBar now={100} />
                                                        </div>
                                                    </td>
                                                    <td>{this.getErrorMsg(this.state.children["computation_status"])}</td>
                                                    </tr>)
                }
            }
            else {
                let childData = this.state.children["dataset_processing_info"];
                if(childData === undefined || childData === null) {
                    childData = [];
                }
                
               for(let i=0;i<childData.length;i++) {
                 let current_processing_info = childData[i];
                 if (current_processing_info["run_status_code"] === dataRunStatuses["failed"]) {
                     let data_set_name = normalizeChildDatasetName(current_processing_info["data_set_name"])
                     errorList.push(<tr key={i}><td>{data_set_name}</td>
                                       {this.props.componentName === "model" ?
                                         <><td>{current_processing_info["dataset_type"]}</td>
                                         <td>{current_processing_info["run_end_time"]}</td></>
                                          :
                                         <td>{this.state.children["last_run_time"][i]["run_end_time"]}</td>
                                       }
                                       <td>
                                                    <div className="data-progress-status">
                                                        <label className="text-danger">Failed</label>
                                                        <ProgressBar now={100} />
                                                    </div>
                                                </td>
                                                <td>{this.getErrorMsg(current_processing_info['run_status_text'])}</td>
                                                </tr>)

                   }
               }
            }
        }

       return(
           <>
               <Button className="btn-circle btn-outline-secondary"
                       variant="secondary" disabled={errorList.length === 0}
                       onClick={this.handleErrorModal.bind(this, this.props.item)}>

                   {
                       errorList.length > 0 ?
                           <>
                               <FontAwesomeIcon className="text-danger" icon={faExclamationCircle}/>
                               Processing Errors
                           </>
                           :
                           this.getErrorText(this.props.item)
                   }

               </Button>

                   <Modal show={this.state.show} size="lg" centered onHide={this.handleErrorModalClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Errors Information
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="react-bootstrap-table table-responsive">
                            {errorList.length > 0 ? <Table>
                                <thead>
                                <tr>
                                    <th>Datasets</th>
                                    {this.props.componentName === "model" ? <th>Type of Dataset</th> : <></>}
                                    <th>Last Processing Time</th>
                                    <th>Current Processing Status</th>
                                    <th>Errors</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {errorList}
                                </tbody>
                            </Table> : <div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4>No error data available</h4>
                                <p>Unable to display the data. For help, check out the <a href={get_document_link("profile_data")} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                            </div> }
                        </div>
                    </Modal.Body>
                </Modal></>);

       }

}

export default DatasetError;