import React from 'react';
import MonitorPortlet from '../components/monitorPortlet';
import Grid from './components/grid';
//import { getApi, setMlModel } from "../utils/event_handling";
//import {updateChartData, getSortedBasedOnError} from "../utils/common_utils";
//import Load from '../components/loadAction';
//import { getMonitorTimeStampMessage } from "../utils/common_utils";
import { connect } from "react-redux";
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {preFetchMlMonitorPreview} from "../utils/monitorDataFetch";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {
    filterChartDataForTime,
    setGridData,
    convertModelDetails,
    canIncludeMetric,
    getModelErrorsOfPerformanceCharts,
    applyDefaultTimeFilter, hasValidNumberOfDatasets
} from "../utils/common_utils";
import NoModelComponent from "./components/noModelComponent";
import { DISPLAY_DATE_FORMAT, NO_DATASET_NO_DATA_PROFILE } from "../utils/constant";
import { getDateObject } from "../charts/browser_utils";


class ModelError extends React.Component {
    constructor(props) {
        super(props);
        this.changeModel = this.changeModel.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.convertModelDetails = convertModelDetails.bind(this);
        this.canIncludeMetric = canIncludeMetric.bind(this);
        this.filterChartDataForTime = filterChartDataForTime.bind(this);
        this.setGridData = setGridData.bind(this);
        this.setMlPreview = this.setMlPreview.bind(this);
        this.getModelErrorsOfPerformanceCharts = getModelErrorsOfPerformanceCharts.bind(this);
        // Error charts obtained based on the model performance threshold errors
        const errorCharts = this.getModelErrorsOfPerformanceCharts(this.props.modelPerformanceData);

        let metadataMap = this.props.dataModule.metaData;
        let modelMapping = this.props.monitorModule.mlModelMapping;

        let gridData = this.setGridData(this.props.monitorModule.mlPreview, metadataMap,
            modelMapping, errorCharts);
        let completeGridData = _.cloneDeep(gridData);
        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
        if (this.props.monitorModule.mlPreview !== null && this.props.monitorModule.mlPreview !== undefined
            && this.props.monitorModule.mlPreview["filterStartDate"] !== null
            && this.props.monitorModule.mlPreview["filterStartDate"] !== undefined) {

            const filterStartDateStr = this.props.monitorModule.mlPreview["filterStartDate"];
            const filterEndDateStr = this.props.monitorModule.mlPreview["filterEndDate"];
            const startDateObj = getDateObject(filterStartDateStr);
            const endDateObj = getDateObject(filterEndDateStr);
            startDate = moment(startDateObj).utc().startOf('day');
            endDate = moment(endDateObj).utc().endOf('day');

        }
        let newGridData = applyDefaultTimeFilter(gridData, startDate, endDate);

        this.state = {
            startDate: startDate,
            endDate: endDate,
            hideLastProfiledTime: false,
            data: newGridData,
            selectedModel: null,
            modelErrorDataCount: 0,
            modelErrorAttributeCount: 0,
            updatedKey: true,
            mlModelOptions: this.props.mlModelOptions,
            completeGridData: completeGridData,
            showMoreData: _.cloneDeep(this.props.showMoreData),
            modelPerformanceData: this.props.modelPerformanceData,
            errorCharts: errorCharts,
            integrationsMap: this.props.integrationsMap,
            selectedDataSource: this.props.dataModule.selectedDataSource
        }

        /*this.getApi = getApi.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.setAvailableModelsAndChartData = this.setAvailableModelsAndChartData.bind(this);
        this.setSelectedModelData = this.setSelectedModelData.bind(this);
        this.setMlModel = setMlModel.bind(this);
        this.getMonitorTimeStampMessage = getMonitorTimeStampMessage.bind(this);
        this.state = {
         mlModel: {loadValue: true},
         mlModelOptions: [],
         closeAction: false,
         lastMonitorTime: null,
         isSuccessMessage: true
         }*/
    }

    /*componentDidMount() {
        this.getData();
    }

    getData() {
        this.getApi("monitor_ml_charts");
    }

    changeModel(model, stateKey, title, startDate, endDate) {
        let details = {...this.state[stateKey]};
        details.loadValue = true;
        this.setState({[stateKey]: details});
        this.setMlModel(model, stateKey, title, startDate, endDate);
    }

    changeTime(model,stateKey, title, startDate, endDate) {
        let details = {...this.state[stateKey]};
        details.loadValue = true;
        this.setState({[stateKey]: details});
        this.setMlModel(model, stateKey, title, startDate, endDate);
    }

    setAvailableModelsAndChartData(models, mlData, mlModel, startDate, endDate) {
        let availableModelOptions = [];
        if(models !== undefined && models.length > 0) {
            availableModelOptions = models.map(function(row) {
                return {"value":row['ml_model_id'], "label": row["model_name"]};
            });
        }
        let metrics = mlData["metrics"];
        let mlModelData = {loadValue:false};
        for (let k = 0; k < metrics.length; k++){
            let row = metrics[k];
            if (row["name"] === "ML Model") {
                let chartValues = updateChartData(row["data"]);
                chartValues = getSortedBasedOnError(chartValues);
                let data = {data: chartValues, loadValue:false, selectedMlModel: mlModel, startDate: startDate, endDate: endDate};
                mlModelData = data;
                break
            }
        }
        this.setState({mlModelOptions: availableModelOptions, mlModel: mlModelData});
    }

    setSelectedModelData(data, key, title, mlModel, startDate, endDate) {
        let metrics = data["metrics"];
        let values = {loadValue: false};
        if (metrics !== undefined && metrics.length > 0) {
           for (let i = 0; i < metrics.length; i++) {
                let row = metrics[i];
                if (row["name"].toLowerCase() === title.toLowerCase()) {
                    let chartData = updateChartData(row["data"]);
                    chartData = getSortedBasedOnError(chartData);
                    values = {data: chartData, loadValue:false, selectedMlModel:mlModel, startDate: startDate, endDate: endDate};
                    break;
                }
            }
        }
         this.setState({[key]: values});
    }

    hideContent() {
        this.setState({
          closeAction: true,
        });
    }*/

    componentDidUpdate(prevProps) {
        if (prevProps.monitorModule.mlModelAttributeInfo !== this.props.monitorModule.mlModelAttributeInfo) {
            const errorCharts = this.getModelErrorsOfPerformanceCharts(this.props.modelPerformanceData);

            let metadataMap = this.props.dataModule.metaData;
            let modelMapping = this.props.monitorModule.mlModelMapping;

            let gridData = this.setGridData(this.props.monitorModule.mlPreview, metadataMap,
                modelMapping, errorCharts);
            let completeGridData = _.cloneDeep(gridData);
            let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
            let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
            if (prevProps.showMoreData !== this.props.showMoreData) {
                this.setState({ showMoreData: _.cloneDeep(this.props.showMoreData) });
            }
            if (this.props.monitorModule.mlPreview !== null && this.props.monitorModule.mlPreview !== undefined && this.props.monitorModule.mlPreview["filterStartDate"] !== null
                && this.props.monitorModule.mlPreview["filterStartDate"] !== undefined) {

                const filterStartDateStr = this.props.monitorModule.mlPreview["filterStartDate"];
                const filterEndDateStr = this.props.monitorModule.mlPreview["filterEndDate"];
                const startDateObj = getDateObject(filterStartDateStr);
                const endDateObj = getDateObject(filterEndDateStr);

                startDate = moment(startDateObj).utc().startOf('day');
                endDate = moment(endDateObj).utc().endOf('day');
            }
            let newGridData = applyDefaultTimeFilter(gridData, startDate, endDate);
            let mlModelOptions = this.props.mlModelOptions
            this.setState({
                data: newGridData,
                startDate: startDate,
                mlModelOptions: mlModelOptions,
                endDate: endDate,
                completeGridData: completeGridData,
                modelPerformanceData: this.props.modelPerformanceData
            });
        }
    }

    closeLastProfiledTime() {
        this.setState({
            hideLastProfiledTime: true,
        });
    }

    static getDerivedStateFromProps(props, state) {
        let modelErrorDataCount = props.monitorModule.mlModelErrorFetch;
        let modelErrorAttributeCount = props.monitorModule.mlModelErrorAttributeFetch;
        const errorCharts = getModelErrorsOfPerformanceCharts(props.modelPerformanceData);

        if (modelErrorDataCount !== state.modelErrorDataCount ||
            modelErrorAttributeCount !== state.modelErrorAttributeCount ||
            state.errorCharts.length !== errorCharts.length) {


            let mlModelMapping = props.monitorModule.mlModelMapping;
            let metadataMap = props.dataModule.metaData;

            let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
            let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
            if (props.monitorModule.mlPreview !== null && props.monitorModule.mlPreview !== undefined
                && props.monitorModule.mlPreview["filterStartDate"] != null && props.monitorModule.mlPreview["filterStartDate"] !== undefined) {

                const filterStartDateStr = props.monitorModule.mlPreview["filterStartDate"];
                const filterEndDateStr = props.monitorModule.mlPreview["filterEndDate"];
                const startDateObj = getDateObject(filterStartDateStr);
                const endDateObj = getDateObject(filterEndDateStr);

                startDate = moment(startDateObj).utc().startOf('day');
                endDate = moment(endDateObj).utc().endOf('day');
            }

            let gridData = setGridData(props.monitorModule.mlPreview, metadataMap, mlModelMapping, errorCharts);
            let completeGridData = _.cloneDeep(gridData);
            let newGridData = applyDefaultTimeFilter(gridData, startDate, endDate);
            return {
                modelErrorDataCount: modelErrorDataCount,
                modelErrorAttributeCount: modelErrorAttributeCount,
                data: newGridData,
                startDate: startDate,
                endDate: endDate,
                completeGridData: completeGridData,
                updatedKey: !state.updatedKey
            };
        }

        if (props.modelPerformanceData.length > state.modelPerformanceData.length) {

            return {
                modelPerformanceData: props.modelPerformanceData
            }
        }

        // Update integrations map from the props
        if (props.integrationsMap !== state.integrationsMap) {
            return {
                integrationsMap: props.integrationsMap
            }
        }

        return null;
    }

    changeModel(event, obj) {
        const clonedData = _.cloneDeep(this.state.completeGridData);
        const modelName = event.label;
        const modelID = event.value;
        let gridData = clonedData.filter(x => String(x.ml_model_id) === String(modelID));
        let filteredGridData = [];
        for (let data of gridData) {
            let updatedData = {};
            updatedData["title"] = data.title;
            updatedData["data"] = [];
            updatedData["key"] = data.key;
            updatedData["ml_model_id"] = modelID;
            for (let innerData of data.data) {
                let copiedData = _.cloneDeep(innerData);
                let result = this.filterChartDataForTime(innerData, this.state.startDate, this.state.endDate);
                let boxPlotDataAvailable = false;
                if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
                    boxPlotDataAvailable = true;
                    copiedData["boxPlotData"] = result.boxPlotData;
                }
                let updatedTime = result.time;
                let yAxis = result.drift;
                if ((copiedData.chartType === "boxPlotZoomable" && boxPlotDataAvailable === false) ||
                    (copiedData.chartType !== "boxPlotZoomable" && updatedTime.length === 0)) {
                    continue;
                }
                copiedData["drift_patterns"] = result.drift_patterns;
                if (result.drift_patterns.length > 0) {
                    let latest_drift_index = result.drift_patterns.length - 1;
                    copiedData["drift_pattern"] = result.drift_patterns[latest_drift_index];
                }
                copiedData["time"] = updatedTime;
                copiedData["drift"] = yAxis;
                updatedData.data.push(copiedData);
            }
            filteredGridData.push(updatedData);
        }
        if (filteredGridData.length === 0) {
            filteredGridData = [{
                "title": `Model Name - ${modelName}`,
                "data": [],
                "key": modelName,
                "ml_model_id": modelID
            }];
        }
        for (let modelDetail of this.props.monitorModule.mlModelDetails) {
            if (Number(modelDetail.ml_model_id) === Number(modelID)) {
                this.setState({ selectedDataSource: modelDetail.integration_id });
            }
        }

        this.setState({ data: filteredGridData });
        this.setState({ selectedModel: event });
    }

    setMlPreview(mlPreview) {
        if (mlPreview === undefined || mlPreview === null) {
            return;
        }
        let errorCharts = this.getModelErrorsOfPerformanceCharts(this.props.modelPerformanceData);
        let mlModelMapping = this.props.monitorModule.mlModelMapping;
        let metadataMap = this.props.dataModule.metaData;
        let gridData = setGridData(mlPreview, metadataMap, mlModelMapping, errorCharts);
        this.setState({
            data: gridData,
        });
    }


    handleTimeFilter(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;

        //        let exStartDate =  moment().subtract(180, 'days');
        const dateFromStr = this.props.monitorModule.mlPreview["dataFrom"];
        const startDateObj = getDateObject(dateFromStr);
        let exStartDate = moment(startDateObj).utc().startOf('day');
        let valuesAvailable = startDate.isSameOrAfter(exStartDate);

        let selectedMLModelID = null;
        if (this.state.selectedModel !== undefined && this.state.selectedModel !== null) {
            selectedMLModelID = this.state.selectedModel["value"];
        }

        this.setState({ startDate: startDate, endDate: endDate });

        let timeFilteredData = [];
        if (valuesAvailable === true) {
            for (let data of this.state.completeGridData) {
                let updatedData = {};
                updatedData["title"] = data.title;
                updatedData["key"] = data.key;
                updatedData["ml_model_id"] = data.ml_model_id;
                updatedData["data"] = [];
                if (selectedMLModelID !== null && String(data.ml_model_id) !== String(selectedMLModelID)) {
                    continue;
                }

                for (let innerData of data.data) {
                    let copiedData = _.cloneDeep(innerData);
                    let result = this.filterChartDataForTime(innerData, startDate, endDate);
                    let boxPlotDataAvailable = false;
                    if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
                        boxPlotDataAvailable = true;
                        copiedData["boxPlotData"] = result.boxPlotData;
                    }
                    let updatedTime = result.time;
                    let yAxis = result.drift;
                    if ((copiedData.chartType === "boxPlotZoomable" && boxPlotDataAvailable === false) ||
                        (copiedData.chartType !== "boxPlotZoomable" && updatedTime.length === 0)) {
                        continue;
                    }
                    copiedData["drift_patterns"] = result.drift_patterns;
                    if (result.drift_patterns.length > 0) {
                        let latest_drift_index = result.drift_patterns.length - 1;
                        copiedData["drift_pattern"] = result.drift_patterns[latest_drift_index];
                    }
                    copiedData["time"] = updatedTime;
                    copiedData["drift"] = yAxis;
                    updatedData.data.push(copiedData);
                }
                timeFilteredData.push(updatedData);
            }
            if (timeFilteredData.length === 0) {
                timeFilteredData = [{
                    "title": `Model Name - ${this.state.selectedModel["label"]}`,
                    "data": [],
                    "key": this.state.selectedModel["label"],
                    "ml_model_id": selectedMLModelID
                }];
            }
            this.setState({ data: timeFilteredData });
        }

    }

    render() {
        let ranges = {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        const selectedDataSource = this.state.selectedDataSource;
        let lastProfiledTimeElement = this.props.dataModule.lastProfilingTime[selectedDataSource];
        const hasValid = hasValidNumberOfDatasets(this.state.integrationsMap, selectedDataSource);

        let lastProfilingTime = lastProfiledTimeElement ?
            `Your data last profiled at ${lastProfiledTimeElement}` : "Data Profiling is In progress ...";
        if (!hasValid) {
            lastProfilingTime = NO_DATASET_NO_DATA_PROFILE;
        }
        let modelProcessStatus = {};
        let noModelConfigured = {}
        if (this.props.monitorModule.mlModelDetails !== undefined && this.props.monitorModule.mlModelDetails.length > 0) {
            for (let mlModel of this.props.monitorModule.mlModelDetails) {
                let modelCreatedTime = mlModel["created_time"];

                const modelCreatedAt = moment(getDateObject(modelCreatedTime)).utc().startOf('day');
                const last_profiled_time = getDateObject(lastProfiledTimeElement).getTime();

                let convertedTime = getDateObject(modelCreatedTime).getTime();
                lastProfiledTimeElement = this.props.dataModule.lastProfilingTime[mlModel.integration_id];

                let status = false;
                let noModelConfiguredStatus = moment(this.state.startDate).isBefore(modelCreatedAt) && moment(this.state.endDate).isBefore(modelCreatedAt)
                noModelConfigured[mlModel["ml_model_id"]] = noModelConfiguredStatus;
                if (convertedTime > last_profiled_time) {
                    status = true;
                }
                modelProcessStatus[mlModel["ml_model_id"]] = status;
            }
        }

        if (this.props.monitorModule.mlModelMapping !== undefined && Object.keys(this.props.monitorModule.mlModelMapping).length > 0) {
            for (let mlModel of Object.keys(this.props.monitorModule.mlModelMapping)) {
                if (!(mlModel in modelProcessStatus)) {
                    modelProcessStatus[mlModel] = true;
                }
            }
        }
        return (
            <>
                {
                    <div className="qd-tab__content-action">
                        <div className="caption">
                            <div className="alert moniker-alert" role="alert">
                                <p className="mb-0">
                                    <strong> {lastProfilingTime}</strong>
                                </p>
                            </div>
                        </div>
                        <div className="actions" >
                            <div className="action-left">
                                <Select
                                    name="models"
                                    filterOption={({ label }, query) => label.includes(query)}
                                    id="chooseModelForMlError"
                                    options={this.state.mlModelOptions}
                                    onChange={this.changeModel}
                                    classNamePrefix='form-control'
                                    placeholder="Choose Model"
                                />
                            </div>
                            <div className="action-right">
                                <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                    startDate={this.state.startDate}
                                    onApply={this.handleTimeFilter}
                                    endDate={this.state.endDate}
                                    ranges={ranges}>
                                    <i>
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </i>
                                    <span className="d-inline-block">
                                        {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                    </span>
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.data.length === 0 ?
                        <NoModelComponent model={true} />
                        :
                        this.state.data.map((modelData, index) => {
                            if(modelData.data.length > 0){
                                modelProcessStatus[modelData.ml_model_id] = false;
                            }
                            return (
                                <MonitorPortlet
                                    key={`mlModel_${modelData.title}_${modelData.ml_model_id}`}
                                    srcOption={this.state.mlModelOptions}
                                    className="pb-0"
                                    video_url="monitor_model"
                                    title={modelData.title}
                                    changeTime={this.changeTime}
                                    index={index + 1}
                                    bodyClassName="pb-0"
                                    id={modelData.key !== undefined ? "mlError_" + modelData.key : "underPerformingMlError"}
                                    name={"Model Errors"}
                                    showHelpIcon={true}
                                    content={
                                        <Grid
                                            key={`mlModel_grid_${modelData.title}_${modelData.ml_model_id}_${this.state.updatedKey}`}
                                            customKey={`mlModel_grid_${modelData.title}_${modelData.ml_model_id}`}
                                            disableShowMore={modelData.disableShowMore}
                                            data={modelData.data.slice(0, 3)}
                                            modalData={this.state.showMoreData}
                                            id="mlModel"
                                            showModelFilter={true}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            setMlModel={this.setMlModel}
                                            //selectedMlModel={this.state.mlModel.selectedMlModel}
                                            mlModelOptions={this.state.mlModelOptions}
                                            variant="mlModel"
                                            attributeOptions={this.props.attributeOptions}
                                            title={modelData.key}
                                            noModelConfigured={noModelConfigured[modelData.ml_model_id]}
                                            underPerformModelIDs={modelData.under_performing_model_ids}
                                            selectedMLModelID={modelData.ml_model_id}
                                            isInProgress={modelProcessStatus[modelData.ml_model_id]}
                                        />}
                                />
                            );
                        })
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(ModelError);