import React from 'react';
// import Filter from '../components/filter';
import ChartBody from '../../components/chartBody';
import BasicPortlet from '../../components/basicPortlet';
import QualdoDataTable from '../../components/bootstrapTable';
import RcTooltip from 'rc-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
    TIMELINESS_METRIC_COLOR,
    TIMELINESS_CARDS,
    ENVIRONMENT,
    STATIC_CARDS,
    DATA_SOURCE,
    DATA_SET,
    ATTRIBUTE,
    DATA_PROFILING_FAILED,
    NO_DATASET_NO_DATA_PROFILE,
    NO_DATA_SET_FOUND_TEXT,
    DEFAULT_METRICS_RUN_FAILURE
} from '../../utils/constant';
import { FilterOptions } from '../components/filterOptions';
import { connect } from "react-redux";
import { setDataSource, setDataSet, setAttribute, setChipData, setFilteredValues, setTimeFilter, setStartDate, setEndDate } from "../../redux/actions";
import moment from 'moment';
import * as d3 from 'd3';
import CardHelpContent from '../../components/cardHelpContent';
import ProgressStatus from '../../components/progressStatus';
import NoDataComponent from "../components/noDataComponent"
import Image from '../../components/image';
import { parseMetaMapping, getDatsetIdForSelectedDatasource, getAttributeNameIdForSelectedDatasource } from '../../utils/common_utils';
import {
    // METADATA_MAP_VALUE_SEPARATOR, 
    normalizeChildDatasetName, 
    QUALDO_CHILD_DATASET_SEPARATOR
} from '../../utils/attribute_name_utils';
//import AdvanceFilter from "./advancedFilter"
import ProfileCardData from "./profileCardData"
import { renderskloader } from "./dq_render_utils"

import { getDatasForTab } from "../../utils/dataProfileEventHandling"
import {  profileTabPrefetch, formTableData } from "../../utils/dataProfileEventHandling"
import TreeFilter from "../data/treeFilter"


const renderTooltip = (props) => (
    <span {...props}>
        Need Help ?
    </span>
);

class Timeliness extends React.Component {

    constructor(props) {
        super(props);
        this.setDataSource = this.setDataSource.bind(this);
        this.formCardData = this.formCardData.bind(this);
        this.formTableData = this.formTableData.bind(this);
        this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.setFilteredValues = this.setFilteredValues.bind(this);
        this.setDefaultValuesInFilter = this.setDefaultValuesInFilter.bind(this);
        this.setChipData = this.setChipData.bind(this);
        this.removeChipData = this.removeChipData.bind(this);
        this.filterInfoData = this.filterInfoData.bind(this);
        this.filterOptions = React.createRef();
        this.setDataSetAndAttribute = this.setDataSetAndAttribute.bind(this);
        // this.renderCards = this.renderCards.bind(this);
        this.filterTimeData = this.filterTimeData.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.getTimeFilteredData = this.getTimeFilteredData.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.skloader = this.skloader.bind(this)

        
        this.profileTabPrefetch = profileTabPrefetch.bind(this);

        this.loadDataSourceRender = this.loadDataSourceRender.bind(this);
        this.getDatasForTab = getDatasForTab.bind(this);

        this.allowPartition = this.allowPartition.bind(this)
        this.allowAttributes = this.allowAttributes.bind(this)
        this.clearAllFilterData = this.clearAllFilterData.bind(this)
        this.handleFilteredData = this.handleFilteredData.bind(this);

        let startDate = moment().subtract(30, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });

        startDate = moment(startDate).format("Y/MM/D");
        endDate = moment(endDate).format("Y/MM/D");


        this.state = {
            hideLastProfiledTime: false,
            last_processed_time:"",
            isFilterMode: true,
            showModal: false,
            cardName: "",
            cardData: [],
            tableData: {
                "headers": ["DataSource", "DataSet", "Metric", "Metric Value"],
                "data": []
            },
            startDate: startDate,
            endDate: endDate,
            selectedDate: [],
            initial_load: 1,
            hideComponent: 'd-none',
            chartTitle: "Data Timeliness Dashboard",
            gridTitle: "Data Timeliness metric values for all datasets",

            environmentOptions: [],
            datasourceOptions: [],
            datasetsOptions: [],
            attributesOptions: [],
            // tableData:{'header':[],'data':[]},
            showLoader: this.props.showLoader,
            newDardData: null,
            relationShipchartData: [],
            chartDatas: [],
            filterData: this.props.filterData,
            seletedDatasourceId: "",
            seletedDatasourceName: "",
            checkedPartition: false,
            checkedAttributes: false,
            datasetLevelChart: [],
            attributeLevelChart: [],
            existingList: this.props.existingList,
            clearAllFilter:false,
            profileData:{},
            filteredDatas:{},
            filteredValues: this.props.filteredValues,
        }
    }

    allowPartition() {
        this.setState({ checkedPartition: !this.state.checkedPartition })
    }

    allowAttributes() {
        this.setState({ checkedAttributes: !this.state.checkedAttributes })
    }

    loadDataSourceRender(params, filteredDatas=null,  dateFilter=null) {
        if(dateFilter === true){
            this.setState({showLoader:true})
            if(Object.keys(this.state.filteredValues).length > 0){
                this.setState({filteredDatas:this.state.filteredValues})
                filteredDatas = this.state.filteredValues
            }
            else{
                filteredDatas = this.state.filteredDatas
            }
            this.setState({ clearAllFilter: false, 
                showLoader: true, 
                seletedDatasourceId: params.integration_id, 
                seletedDatasourceName: params.integration_name ,
                checkedAttributes:false,
                checkedPartition:false
            })
            this.getDatasForTab(params,filteredDatas,  dateFilter)

        }
        else{
            this.setState({ clearAllFilter: false, 
                showLoader: true, 
                seletedDatasourceId: params.integration_id, 
                seletedDatasourceName: params.integration_name ,
                checkedAttributes:false,
                checkedPartition:false
            })
            this.getDatasForTab(params)

        }
    }

    clearAllFilterData(){
        this.setState({ filterData: this.props.filterData,clearAllFilter:true})
    }

    handleFilteredData(data) {
        this.props.handleFilteredData(data)
        this.setState({ filteredValues: data,checkedAttributes:false,checkedPartition:false,allowRelationData:false,allowRelationsFilter:"All" })
        let filterData = formTableData(this.state.profileData['totalDatas'], 4, data)
        if(filterData !== undefined){
            this.setState({newCardData:filterData['cardData'], tableData:filterData['tableData']})
        }
    }


    // componentDidUpdate(prevProps) {

    //     if (this.state.showLoader === false && this.state.tableData.data.length === 0) {
    //         this.formTableData(this.props.dataModule.filteredValues);


    //     }
    //     if (prevProps.dataModule.selectedDataSource === -1) {
    //         if (prevProps.dataModule.selectedDataSource !== this.props.dataModule.selectedDataSource) {
    //             this.formCardData(this.props.dataModule.filteredValues);
    //             this.formTableData(this.props.dataModule.filteredValues);
    //             this.setDefaultValuesInFilter();
    //         }
    //     }
    //     if (prevProps.dataModule.filteredValues !== this.props.dataModule.filteredValues) {
    //         if (this.props.dataModule.filteredValues.length > 0) {
    //             this.setFilteredValues(this.props.dataModule.filteredValues);
    //             this.formCardData(this.props.dataModule.filteredValues);
    //             this.formTableData(this.props.dataModule.filteredValues);
    //         }
    //     }
    // }

    changeTime(selectedValue, startDate, endDate) {
        this.props.setTimeFilter(true);
        endDate = moment(endDate).format('YYYY-MM-DD 23:59:59');
        this.formCardData();
        this.formTableData(this.props.dataModule.filteredValues, -1, startDate, endDate);
        this.setState({ startDate: startDate, endDate: endDate });
    }

    getTimeFilteredData(allMetricInfo, startDate, endDate, metricInfo, key) {
        let timeList = allMetricInfo[metricInfo][`${key}_created_time`];
        let startIndex = -1;
        let endIndex = -1;
        timeList.forEach((time) => {
            if (moment(time).isSameOrBefore(moment(endDate)) && moment(time).isSameOrAfter(moment(startDate))) {
                let index = timeList.indexOf(time);
                if (startIndex === -1 || startIndex > index) {
                    startIndex = index;
                }
                if (endIndex === -1 || endIndex < index) {
                    endIndex = index;
                }
            }
        })
        if (startIndex !== -1 && endIndex !== -1) {
            let tempMetricValueList = allMetricInfo[metricInfo][`${key}_time_value`].slice(startIndex, endIndex + 1);
            tempMetricValueList = tempMetricValueList.filter(x => x !== "NA");
            return tempMetricValueList;
        }
        return [];
    }

    getSelectedTime() {
        let timeList = [];
        let tempStartDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00');
        let tempEndDate = moment().format('YYYY-MM-DD 00:00:00');
        while (moment(tempStartDate).isSameOrBefore(moment(tempEndDate))) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }
        this.setState({ selectedDate: timeList });

    }

    handleClick(data) {
        this.setState({ showModal: true, cardName: data.metric_name });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    }

    renderCards(cardData) {
        return (
            <div className="form-row">
                {
                    this.state.showModal ?
                        <CardHelpContent
                            show={this.state.showModal}
                            onHide={this.hideModal}
                            name={this.state.cardName}
                            page={"data"}
                        /> : ""
                }
                {
                    cardData.map((data) => {
                        let cardBackground = `dashboard-stat ${data.background}`;
                        let textColor = `title ${data.textColor}`;
                        return (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                                key={data.idValue}
                            >
                                <div className={cardBackground}>
                                    <div className="details">
                                        <div className="desc-header">
                                            <div className={textColor}>
                                                {data.metric_name}
                                            </div>
                                            <div>
                                                <RcTooltip 
                                                    placement="top" 
                                                    overlay={renderTooltip}
                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                >
                                                    <div className="help-icon" onClick={this.handleClick.bind(this, data)} data-toggle="modal" data-target="#model__help"><i><FontAwesomeIcon icon={faQuestionCircle} /></i></div>
                                                </RcTooltip>
                                            </div>
                                        </div>
                                        <div className="desc">
                                            <span className="desc-value">{data.metric_value}</span>
                                            <span className="desc-percentage">{data.metric_name === "Data Timeliness" ? "days" : "%"}</span>
                                        </div>
                                        <ChartBody
                                            classValue="chart"
                                            id={data.idValue}
                                            chartType="cardArea"
                                            style={{ height: "250px" }}
                                            chart_data={data.chartData}
                                            colorType={data.textColor}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    filterTimeData(key) {
        let tempStartDate = this.state.startDate;
        let timeList = [];
        for (let i = 0; i < 8; i++) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }

        return { "time": timeList, "values": STATIC_CARDS[key] }
    }


    componentDidMount() {
        if(this.props.startDate !== null){
            let loadparams = this.props.loadparams
            loadparams['tab_type'] = 4
            this.setState({showLoader:true})
            this.getDatasForTab(loadparams,this.state.filteredValues, true)
        }
        else{
            if(this.props.noDataFound !== true && this.props.inProgress !== true){
                this.setState({showLoader:true})
                this.profileTabPrefetch(this.state.filteredValues, 4);
            }             }
        this.formCardData();
        // this.formTableData(this.props.dataModule.filteredValues);
        this.setDefaultValuesInFilter();
        this.getSelectedTime();
    }



    componentWillUnmount() {
        d3.selectAll(".d3-tip").remove();
    }

    setDefaultValuesInFilter() {
        let environment = -1;
        let dataSource = -1;
        if (this.props.dataModule !== undefined && this.props.dataModule.selectedDataSource !== undefined && this.props.dataModule.filteredValues !== undefined && this.props.dataModule.filteredValues.length === 0) {
            for (const [key, value] of Object.entries(this.props.dataModule.integrations)) {
                if (key === this.props.dataModule.selectedDataSource.toString()) {
                    dataSource = { "label": value, "value": key };
                }
            }
            for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
                if (value.includes(this.props.dataModule.selectedDataSource.toString())) {
                    environment = { "label": this.props.dataModule.environments[key], "value": key };
                }
            }
        }
        this.setState({ prefillEnvironment: environment, prefillDataSource: dataSource });
    }

    setChipData(environment, dataSource, dataSets, attributes) {
        let chipData = [];
        if (environment !== -1) {
            chipData.push({ "key": environment.value, "name": environment.label, "type": ENVIRONMENT, "value": environment.value, "label": environment.label });
        }
        if (dataSource !== -1) {
            chipData.push({ "key": dataSource.value, "name": dataSource.label, "type": DATA_SOURCE, "value": dataSource.value, "label": dataSource.label });
        }
        if (dataSets !== -1) {
            dataSets.forEach((dataSet) => {
                chipData.push({ "key": dataSet.value, "name": dataSet.label, "type": DATA_SET, "value": dataSet.value, "label": dataSet.label });
            });
        }
        if (attributes !== -1) {
            attributes.forEach((attribute) => {
                chipData.push({ "key": attribute.value, "name": attribute.label, "type": ATTRIBUTE, "value": attribute.value, "label": attribute.label });
            });
        }
        this.props.setChipData(chipData);
        this.setState({ prefillEnvironment: environment, prefillDataSource: dataSource });
    }

    removeChipData(key) {
        // Chipdata will have list of all the environment, datasource, dataset and attribute level key, value and type
        // [{ key: "2270", value: "postgres check", type: 1 }]
        let chipData = this.props.dataModule.chipData;
        let chipDataAttribute;
        let checkdataSetAttributeMapping = this.props.dataModule.dataSetAttributeMapping;

        // Initially the selected key will be romoved from the chipData
        // Then in furter will reomve the keys with depends on the removing key
        // ex : if user remove dataset here will remove the dataset key alone in futher will remove the dependent attribute key for that dataset 
        chipData = chipData.filter((item) => item.key !== key && (item.type === ENVIRONMENT || item.type === DATA_SOURCE || item.type === DATA_SET || (item.type === ATTRIBUTE && item.key !== "*")));

        if (checkdataSetAttributeMapping[key] !== undefined) {
            // While removing the dataset level key we have to remove the corresponding attributes key
            // Filtering the attribute keys for the particular dataset  
            chipDataAttribute = chipData.filter((item) => checkdataSetAttributeMapping[key].indexOf(item.key) > -1 && item.type === ATTRIBUTE);
            for (let i of chipDataAttribute) {
                // Here will exclude the attribute from chipData 
                chipData = chipData.filter((item) => item.key !== i.key);
            }
        }
        let filteredValues = [];
        let environment = chipData.filter((item) => item.type === ENVIRONMENT);
        let dataSource = chipData.filter((item) => item.type === DATA_SOURCE);
        if (environment.length === 0 || dataSource.length === 0) {
            chipData = [{
                "key": this.props.dataModule.selectedDataSource,
                "name": this.props.dataModule.integrations[this.props.dataModule.selectedDataSource],
                "type": DATA_SOURCE
            }];
            // this.filterOptions.current.setDefaultValuesFromParent(this.state.prefillEnvironment,this.state.prefillDataSource,-1,-1);
        } else {
            let prefix = `${environment[0].key}.${dataSource[0].key}`;

            // 
            let dataSets = chipData.filter((item) => item.type === DATA_SET);
            let attributes = chipData.filter((item) => item.type === ATTRIBUTE);
            // After removing keys from chipData
            // Will form lables and store list of values in filteredValues
            if (dataSets.length !== 0) {
                dataSets.forEach((dataSet) => {
                    if (dataSet.key !== '*') {
                        if (attributes.length !== 0 && attributes.key !== "*") {
                            attributes.forEach((attribute) => {
                                if (checkdataSetAttributeMapping[dataSet.key].filter((item) => item.key !== attribute.key)) {

                                    // chipDataAttribute = chipData.filter((item) => checkdataSetAttributeMapping[dataSet.key].indexOf(item.key) > -1 && item.type === ATTRIBUTE);
                                    // for(let i of chipDataAttribute){
                                    //     // Here will exclude the attribute from chipData 
                                    //     chipData = chipData.filter((item)=> item.key === i.key && (item.type === ENVIRONMENT || item.type === DATA_SOURCE || item.type === DATA_SET || item.type === ATTRIBUTE));
                                    // }

                                    filteredValues.push(`${prefix}.${dataSet.key}.${attribute.key}`);
                                } else {

                                    filteredValues.push(`${prefix}.${dataSet.key}`);
                                }
                            });
                        } else if (attributes.key === '*') {
                            // To handle the All option in Attribute field inside the filter 
                            // All option will be remove by excluding the * from the chipData list
                            chipData = chipData.filter((item) => item.type !== ATTRIBUTE && item.key !== "*");
                            filteredValues.push(`${prefix}.${dataSet.key}`);
                            // this.filterOptions.current.setDefaultValuesFromParent(null, null, null, -1);
                        }
                    }
                    else if (dataSets.length > 1 && dataSet.key === '*') {
                        chipData = chipData.filter((item) => item.key !== dataSet.key && item.key !== "*");
                        filteredValues.push(`${prefix}.${dataSet.key}`);
                        // this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
                    }
                    else if (dataSets.length === 1 && dataSet.key === '*') {
                        // To handle the All option in Dataset field inside the filter
                        // All option will be remove by excluding the * from the chipData list
                        chipData = chipData.filter((item) => item.type !== DATA_SET && item.key !== "*");
                        filteredValues.push(`${prefix}`);
                        // this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
                    }
                    // else{
                    //     // To handle the All option in Dataset field inside the filter
                    //     // All option will be remove by excluding the * from the chipData list
                    //     chipData = chipData.filter((item)=> item.type !== DATA_SET && item.key !=="*");
                    //     // filteredValues.push(prefix);
                    //     filteredValues.push(`${prefix}.${dataSet.key}`);
                    //     this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
                    // }
                });
            } else {
                // Will Removing the environment or datasource level key 
                // We'll clear all datasource and attribute keys from the chipData
                chipData = chipData.filter((item) => item.type !== ATTRIBUTE && item.type !== DATA_SET);
                filteredValues.push(prefix);
                // this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
            }
        }
        this.props.setFilteredValues(filteredValues);
        this.props.setChipData(chipData);
        this.formCardData();
        this.formTableData(filteredValues, this.props.dataModule.selectedDataSource, this.state.startDate, this.state.endDate);
    }


    setDataSource(dataSourceId) {
        let startDate = moment(this.props.dataModule.lastProfilingTime[dataSourceId]).format('YYYY-MM-DD 00:00:00');
        let endDate = moment(this.props.dataModule.lastProfilingTime[dataSourceId]).format('YYYY-MM-DD 23:59:59');
        let datasetId = getDatsetIdForSelectedDatasource(this.props.dataModule, dataSourceId);
        let attributeData = getAttributeNameIdForSelectedDatasource(this.props.dataModule, dataSourceId, datasetId);
        let attributeName = attributeData.attributeName;
        let attributeId = attributeData.attributeId;
        this.props.setDataSource(dataSourceId);
        this.props.setAttribute(attributeId, attributeName);
        this.props.setDataSet(datasetId);
        let environment = -1;
        for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
            if (value.includes(dataSourceId.toString())) {
                environment = { "label": this.props.dataModule.environments[key], "value": key };
            }
        }
        let dataSource = { "value": dataSourceId, "label": this.props.dataModule.integrations[dataSourceId] }
        this.props.setFilteredValues([]);
        this.setChipData(environment, dataSource, -1, -1);
        this.props.setStartDate(startDate);
        this.props.setEndDate(endDate);
        this.setState({ startDate: startDate, endDate: endDate })
        this.formCardData();
        this.formTableData([], dataSourceId, startDate, endDate);
    }


    closeLastProfiledTime() {
        this.setState({
            hideLastProfiledTime: true,
        });
    }

    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }

    resetFilter() {
        this.setState({ formResetFlag: 1 });
    }

    toggleMainFilterApplied() {
        let isMainFilterApplied = this.state.isMainFilterApplied ? false : true;
        this.setState({
            isMainFilterApplied: isMainFilterApplied
        })
    }

    setFilteredValues(filteredValues) {
        this.props.setFilteredValues(filteredValues);
        let dataSourceId = filteredValues[0].split(".")[1];
        this.formCardData();
        this.formTableData(filteredValues, dataSourceId, this.state.startDate, this.state.endDate);
        this.props.setDataSource(dataSourceId);
    }

    renderFilter(environmentOption) {
        let dataSourceOption = [];
        let dataSetOptions = [];
        let preSelectedEnvironment = this.state.prefillEnvironment;
        let preSelectedDataSource = this.state.prefillDataSource;
        let preSelectedDatasets = -1;
        let preSelectedAttributes = -1;
        let attributeOptions = [];
        if (this.props.dataModule.chipData !== undefined && this.props.dataModule.chipData.length > 1) {
            let chipData = this.props.dataModule.chipData;
            let environment = chipData.filter((item) => item.type === ENVIRONMENT);
            preSelectedEnvironment = { "label": environment[0].value, "value": environment[0].key };
            dataSourceOption = []
            let dataSourceList = this.props.dataModule.environmentDataSourceMapping[preSelectedEnvironment.value];
            dataSourceList.forEach((dataSourceId) => {
                dataSourceOption.push({ "value": dataSourceId, "label": this.props.dataModule.integrations[dataSourceId] });
            });
            let dataSource = chipData.filter((item) => item.type === DATA_SOURCE);
            preSelectedDataSource = { "label": dataSource[0].value, "value": dataSource[0].key };
            let datasets = chipData.filter((item) => item.type === DATA_SET);
            preSelectedDatasets = [];
            datasets.forEach((dataSet) => {
                preSelectedDatasets.push({ "label": dataSet.value, "value": dataSet.key })
            });
            let attributes = chipData.filter((item) => item.type === ATTRIBUTE);
            preSelectedAttributes = [];
            attributes.forEach((attribute) => {
                preSelectedAttributes.push({ "label": attribute.value, "value": attribute.key })
            });

            preSelectedDatasets.forEach((item) => {
                let attributesList = this.props.dataModule.dataSetAttributeMapping[item.value];
                if (attributesList !== undefined) {
                    attributesList.forEach((attributeId) => {
                        attributeOptions.push({
                            "value": attributeId, "label": parseMetaMapping(this.props, ATTRIBUTE, item.value,
                                attributeId, preSelectedEnvironment, preSelectedDataSource)
                        });
                    });
                }
            });
            let datasetIds = [];
            dataSetOptions = [];
            let dataSetsList = this.props.dataModule.dataSourceDataSetMapping[preSelectedDataSource.value];
            if (dataSetsList !== undefined) {
                dataSetsList.forEach((dataSetId) => {
                    if (!datasetIds.includes(dataSetId)) {
                        datasetIds.push(dataSetId);
                        dataSetOptions.push({
                            "value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
                                preSelectedDataSource.value, dataSetId, preSelectedEnvironment, preSelectedDataSource)
                        });
                    }
                });
            }
        }
        else {
            let datasetIds = [];
            if (this.props.dataModule !== undefined && preSelectedDataSource !== undefined && this.state.prefillEnvironment !== undefined &&
                this.state.prefillEnvironment !== -1 && this.props.dataModule.dataSourceDataSetMapping !== undefined) {
                let dataSources = this.props.dataModule.environmentDataSourceMapping[preSelectedEnvironment.value];
                dataSources.forEach((dataSourceId) => {
                    if (this.props.dataModule["recencyDetails"][dataSourceId] === undefined) {
                        dataSourceOption.push({ "value": dataSourceId, "label": <div>{this.props.dataModule.integrations[dataSourceId]}<Image width="100" height="25" src="loader" /></div>, "isDisabled": true });
                    } else {
                        dataSourceOption.push({ "value": dataSourceId, "label": this.props.dataModule.integrations[dataSourceId] });
                    }
                });
                let dataSets = this.props.dataModule.dataSourceDataSetMapping[preSelectedDataSource.value];
                if (dataSets !== undefined) {
                    dataSets.forEach((dataSetId) => {
                        if (!datasetIds.includes(dataSetId)) {
                            datasetIds.push(dataSetId);
                            dataSetOptions.push({
                                "value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
                                    preSelectedDataSource.value, dataSetId, this.state.prefillEnvironment, preSelectedDataSource)
                            });
                        }
                    })
                }
            }

        }

        return (
            <FilterOptions
                ref={this.filterOptions}
                show={this.state.isFilterMode}
                resetFilter={this.resetFilter}
                closeFilterModal={this.toggleFilter}
                dataModule={this.props.dataModule}
                setFilteredValues={this.setFilteredValues}
                environments={environmentOption}
                dataSourceOption={dataSourceOption}
                dataSetOptions={dataSetOptions}
                attributeOptions={attributeOptions}
                preSelectedEnvironment={preSelectedEnvironment}
                preSelectedDataSource={preSelectedDataSource}
                preSelectedDatasets={preSelectedDatasets}
                preSelectedAttributes={preSelectedAttributes}
                filterTitle="Data Timeliness Filter"
                setChipData={this.setChipData}
                id="timelinessFilter"
            />
        );
    }


    filterInfoData(filteredValues, infoData) {
        let updatedInfoData = {}
        filteredValues.forEach((filteredValue) => {
            if (infoData !== null && infoData !== undefined) {
                const filtered = Object.keys(infoData)
                    .filter(key => key.startsWith(filteredValue))
                    .reduce((obj, key) => {
                        obj[key] = infoData[key];
                        return obj;
                    }, {});
                updatedInfoData = { ...updatedInfoData, ...filtered }
            }
        });
        return updatedInfoData;
    }

    formTableData(filteredValues, changedDataSource = -1, startDate = this.state.startDate, endDate = this.state.endDate) {
        // if (this.props.dataModule.selectedDataSource !== -1 && this.props.dataModule.selectedDataSource !== undefined) {
        //     let tableInfo = [];
        //     let allMetricInfo;
        //     if (changedDataSource === -1) {
        //         allMetricInfo = _.cloneDeep(this.props.dataModule.recencyDetails[this.props.dataModule.selectedDataSource]);
        //     } else {
        //         allMetricInfo = _.cloneDeep(this.props.dataModule.recencyDetails[changedDataSource]);
        //     }

        //     // let default_data = allMetricInfo;
        //     let restrictchilddata = [];
        //     // if((this.props.dataModule.filteredValues.length === 0) || (this.props.dataModule.filteredValues.length === 1 && this.props.dataModule.filteredValues[0].split(".").length === 2)){
        //     //     for (var key in default_data) {
        //     //         if (default_data.hasOwnProperty(key)) {
        //     //             let metaData = this.props.dataModule.metaData[key];
        //     //             if(!datasetNameFromMappedValue(metaData).includes(QUALDO_CHILD_DATASET_SEPARATOR)){
        //     //                 restrictchilddata[key] =  default_data[key]
        //     //             }
        //     //         }
        //     //     }
        //     // }else {
        //     //     restrictchilddata = default_data;
        //     // } 
        //     // if (filteredValues.length !== 0) {
        //     //     restrictchilddata = this.filterInfoData(filteredValues, restrictchilddata);
        //     // }

        //     restrictchilddata = allMetricInfo

        //     if (allMetricInfo !== undefined && restrictchilddata !== null) {
        //         for (const [key, value] of Object.entries(restrictchilddata)) {
        //             if (key.split(".").length === 3) {
        //                 for (const [attrKey] of Object.entries(this.props.dataModule.metaData)) {
        //                     if (attrKey.startsWith(key)) {
        //                         let metaData = this.props.dataModule.metaData[attrKey];
        //                         if (metaData !== undefined) {
        //                             let reqSplitArrVals = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
        //                             let dataSource = reqSplitArrVals[1];
        //                             let dataSet = normalizeChildDatasetName(reqSplitArrVals[2]);
        //                             for (const [metricId, metricValue] of Object.entries(value)) {
        //                                 if (!metricId.endsWith("drift") && metricId in TIMELINESS_METRIC) {
        //                                     let tableData = [];
        //                                     tableData.push({ "value": dataSource, "type": "td" });
        //                                     tableData.push({ "value": dataSet, "type": "td" });
        //                                     tableData.push({ "value": TIMELINESS_METRIC[metricId], "type": "td" });
        //                                     let tempMetricValue = metricValue;
        //                                     if (this.props.dataModule.timeFilterApplied) {
        //                                         let timeFilteredData = this.getTimeFilteredData(restrictchilddata, startDate, endDate, key, metricId);
        //                                         if (timeFilteredData.length > 0) {
        //                                             tempMetricValue = `${Math.min.apply(null, timeFilteredData)}`;
        //                                         } else {
        //                                             continue;
        //                                         }
        //                                     }
        //                                     tableData.push({ "value": `${tempMetricValue} days`, "type": "td" });
        //                                     tableInfo.push(tableData);
        //                                 }
        //                             }
        //                         }
        //                         break;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     let tableHeaders = this.state.tableData.headers;
        //     let tableData = { "headers": tableHeaders, data: tableInfo };
        //     this.setState({ tableData: tableData });
        // }
        let tableData = this.state.tableData

        this.setState({ tableData: tableData });
    }

    formCardData() {

        let cardInfo = [];
        let commonId = 0;
        for (const [cardName] of Object.entries(TIMELINESS_CARDS)) {
            let tempInfo = {};
            tempInfo["metric_name"] = cardName;
            tempInfo["hide_edit"] = true;
            tempInfo["cardType"] = "chart";
            tempInfo["background"] = TIMELINESS_METRIC_COLOR[cardName].cardBackground;
            tempInfo["textColor"] = TIMELINESS_METRIC_COLOR[cardName].textColor;
            tempInfo["chartData"] = this.filterTimeData(commonId);
            tempInfo["idValue"] = `completeness${commonId}`;
            commonId = commonId + 1;
            cardInfo.push(tempInfo);
        }
        this.setState({
            cardData: cardInfo
        });
    }

    setDataSetAndAttribute(dataSet, attribute) {
        this.props.setAttributeTab(dataSet, attribute)
        // this.props.setDataSet(dataSet);
        // this.props.setAttribute("", attribute);
    }

    skloader(key) {
        let count = this.state.initial_load + key
        this.setState({ initial_load: count })
    }

    render() {
        let dataSourceOption = [];
        let environmentOption = [];
        let selectedDataSource = {};
        const dmSelectedDataSource = this.props.dataModule.selectedDataSource;

        const lastProfilingTimeElement = this.state.last_processed_time
        let lastProfilingTime = lastProfilingTimeElement ?
            `Your data last profiled at ${lastProfilingTimeElement}` : "--";


        for (const [key, value] of Object.entries(this.props.dataModule.integrations)) {
            if (this.props.dataModule["recencyDetails"][key] === undefined) {
                dataSourceOption.push({ "label": <div>{value}  <Image src="loader" /></div>, "value": key, "isDisabled": true });
            } else {
                dataSourceOption.push({ "label": value, "value": key });
            }
            if (dmSelectedDataSource !== undefined && key === dmSelectedDataSource.toString()) {
                selectedDataSource = { "label": value, "value": key };
            }
        }

        for (const [key, value] of Object.entries(this.props.dataModule.environments)) {
            environmentOption.push({ "label": value, "value": key });
        }

        let isCalculationInProgress = false
        let discoveryStatus;
        let latestProcessingStatus = this.props.dataModule.processingStatus[dmSelectedDataSource];
        if (this.props.dataModule.discoveryStatus !== undefined) {
            discoveryStatus = this.props.dataModule.discoveryStatus[dmSelectedDataSource];
        }
        let hasValidDatasets = discoveryStatus !== undefined && discoveryStatus !== NO_DATA_SET_FOUND_TEXT;
        if (!hasValidDatasets) {
            lastProfilingTime = NO_DATASET_NO_DATA_PROFILE;
        } else if (latestProcessingStatus === DEFAULT_METRICS_RUN_FAILURE) {
            lastProfilingTime = DATA_PROFILING_FAILED;
        }

        let checkProgress;
        if (this.props.dataModule.relationship !== undefined && this.props.dataModule.relationship !== "undefined") {
            checkProgress = this.props.dataModule.relationship[dmSelectedDataSource]
            if (checkProgress !== undefined) {
                isCalculationInProgress = (Object.keys(this.props.dataModule.relationship[dmSelectedDataSource]).length === 0)
                isCalculationInProgress = isCalculationInProgress && hasValidDatasets && latestProcessingStatus !== DEFAULT_METRICS_RUN_FAILURE;

                if (isCalculationInProgress) {
                    this.props.setLoader(true);
                } else {
                    this.props.setLoader(false);
                }
            }
        }

        let sessionToken = localStorage.getItem("sessionToken");
        if (checkProgress === undefined && this.props.dataModule.tenantId !== undefined &&
            this.props.dataModule.tenantId === -1 &&
            dmSelectedDataSource === -1 && sessionToken !== null && sessionToken !== undefined) {
        }

        // let initialChipData = [{
        //     "key": dmSelectedDataSource,
        //     "value": this.props.dataModule.integrations[dmSelectedDataSource],
        //     "type": DATA_SOURCE
        // }];
        let default_data = this.props.dataModule.relationship[dmSelectedDataSource];
        let restrictchilddata = [];
        if ((default_data !== undefined && this.props.dataModule.filteredValues !== undefined && this.props.dataModule.filteredValues.length === 0) || (default_data !== undefined && this.props.dataModule.filteredValues.length === 1 && this.props.dataModule.filteredValues[0].split(".").length === 2)) {
            for (let loop = 0; loop < default_data.length; loop++) {
                let metaData = this.props.dataModule.metaData[default_data[loop]["name"]];
                if (metaData !== undefined && metaData !== null && metaData.includes(QUALDO_CHILD_DATASET_SEPARATOR)) {
                    continue
                }
                restrictchilddata.push(default_data[loop])
            }
        } else {
            restrictchilddata = default_data;
        }




/**  TODO */
        // Below entire chart logic needs to be moved into a function : handleChartData()

        let chartData = []

        let initialFilteredValues = this.state.filteredValues
        let initialFilteredDatasets = []
        let initialFilteredAttributes = []

        if (Object.keys(initialFilteredValues).length > 0 && initialFilteredValues['fiteredDatseset'].length > 0) {
            initialFilteredDatasets = initialFilteredValues['fiteredDatseset']
            initialFilteredAttributes = initialFilteredValues['fitlerdAttr']
        }


        /**Separate Dataset level and attribute level */
        let datasetLevelChart = []
        let attributeLevelChart = []

        this.state.chartDatas.map((data) => {
            let id = data.attribute_id === undefined ? data.dataset_id : data.attribute_id;
            if(data.attribute_id === undefined){
                data['name'] = normalizeChildDatasetName(data['name'])
            }
            else{
                data['dataset_name'] = normalizeChildDatasetName(data['dataset_name'])
            }
            if (this.state.existingList.includes(id)) {
                /** Condition to skip deleted metadata if any come in the chart data */
                if (initialFilteredDatasets.length > 0) {
                    if (initialFilteredDatasets.includes(Number(id))) {
                        if (data.attribute_id === undefined) {
                            datasetLevelChart = datasetLevelChart.concat(data)
                        }
                    }
                }
                else {
                    if (data.attribute_id === undefined) {
                        datasetLevelChart = datasetLevelChart.concat(data)
                    }
                }
                if (initialFilteredAttributes.length > 0) {
                    if (data.attribute_id !== undefined) {
                        if (initialFilteredAttributes.includes(Number(data.attribute_id))) {
                            attributeLevelChart = attributeLevelChart.concat(data)
                        }
                    }
                }
                else if (initialFilteredDatasets.length > 0) {
                    if (initialFilteredDatasets.includes(Number(data.dataset_id))) {
                        if (data.attribute_id !== undefined) {
                            attributeLevelChart = attributeLevelChart.concat(data)
                        }
                    }
                }
                else {
                    if (data.attribute_id !== undefined) {
                        attributeLevelChart = attributeLevelChart.concat(data)
                    }
                }
                // }
                // else {
                //     /** Condition to skip deleted metadata if any come in the chart data */
            }
            return data;
        })

        /**Separate Partition and child datasets */

        let parentDatasetIds = []
        let childDatasetIds = []
        datasetLevelChart.map((data) => {
            if(data.metric_type !== "Row Count"){
                if (this.state.filterData['childDatasets'].includes(Number(data.dataset_id))) {
                    childDatasetIds = childDatasetIds.concat(data.dataset_id)
                }
                else {
                    parentDatasetIds = parentDatasetIds.concat(data.dataset_id)
                }
            }
            return parentDatasetIds
        })


        /**Separate Partition dataset Attributes and Child Dataset Attributes  */
        let parentAttributeIds = []
        let childAttributeIds = []
        attributeLevelChart.map((data) => {
            if(data.metric_type !== "Row Count"){
                if (parentDatasetIds.includes(data.dataset_id)) {
                    parentAttributeIds = parentAttributeIds.concat(data.attribute_id)
                }
                else {
                    childAttributeIds = childAttributeIds.concat(data.attribute_id)
                }
            }
            return parentAttributeIds
        })


        /**Handle Dataset level chart Data based on the chart check box filter */
        let attributeList = []
        datasetLevelChart.map((data) => {
            if (this.state.checkedPartition) {
                chartData = chartData.concat(data)
                attributeList = childAttributeIds.concat(parentAttributeIds)
            }
            else if (this.state.filterData['childDatasets'].includes(Number(data.dataset_id))) {
                /*** Skip Pushing Child Dataset initially */
                
                
                // if (data.attribute_id === undefined) {
                
                // }

                // if (data.attribute_id === undefined) {
                
                // }
            }
            else {
                attributeList = parentAttributeIds
                chartData = chartData.concat(data)
            }
            return chartData
        })


        /**Handle Attribute level chart Data based on the chart check box filter */
        if (this.state.checkedAttributes) {
            chartData = []
            attributeLevelChart.map((data) => {
                if(this.state.checkedPartition === false){
                    if(parentDatasetIds.includes(data.dataset_id)){
                        chartData = chartData.concat(data)    
                    }
                }
                else if(childDatasetIds.includes(data.dataset_id)){
                        chartData = chartData.concat(data)    
                }
                else{
                    chartData = chartData.concat(data)    
                }
                return chartData
            })
        }

        /**  TODO */
        // Above entire chart logic needs to be moved into a function : handleChartData()

        return (
            this.state.showLoader === false && this.props.inProgress === false
                ?
                (
                    <div className={"data-profile-tabs"}>
                        {
                            <div className="qd-tab__content-action">
                                {
                                    this.state.hideLastProfiledTime === false ?
                                        <div className="caption">
                                            <div className="alert moniker-alert" role="alert">
                                                <p className="mb-0">
                                                    <strong> {lastProfilingTime}</strong>
                                                </p>
                                            </div>
                                        </div>
                                        : ""
                                }
                                <div className="actions flex-shrink-0">
                                    <button onClick={this.toggleFilter} className="btn btn-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1"
                                            viewBox="0 0 24 24" width="24">
                                            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                            <path d="M0 0h24v24H0z" fill="none" />
                                        </svg>
                                        <span className="dot-symbol"></span>
                                        <span>Filters</span>
                                    </button>
                                </div>
                            </div>
                        }
                        {this.state.isFilterMode === true &&
                            <TreeFilter
                                tab={"timeliness"}
                                startDate={this.props.startDate !== null ? this.props.startDate : this.state.startDate}
                                endDate={this.props.endDate !== null ? this.props.endDate : this.state.endDate}
                                seletedDatasourceId={this.state.seletedDatasourceId}
                                seletedDatasourceName={this.state.seletedDatasourceName}
                                data={this.state.filterData}
                                loadDataSourceRender={this.loadDataSourceRender}
                                clearAllFilterData={this.clearAllFilterData}
                                clearAllFilter={this.state.clearAllFilter}
                                handleFilteredData={this.handleFilteredData}
                                handleDateFiltered={this.props.handleDateFiltered}

                            />
                        }
                        <ProfileCardData
                            newCardData={this.state.newCardData}
                            cardData={this.state.cardData}
                            cardName={this.state.cardName}
                            showModal={this.state.showModal}
                            hideModal={this.hideModal}
                            skloader={this.skloader}

                        />
                        <BasicPortlet
                            video_url="profile_data"
                            className="pb-0"
                            title={this.state.chartTitle}
                            srcOption={dataSourceOption}
                            bodyClassName="pb-0"
                            showFilter={false}
                            changeSource={this.setDataSource}
                            showtimeFilter={false}
                            disableTimeFilter={isCalculationInProgress || !hasValidDatasets || latestProcessingStatus === DEFAULT_METRICS_RUN_FAILURE}
                            id="timeliness"
                            selectedDataSrc={selectedDataSource}
                            changeTime={this.changeTime}
                            content={
                                <ChartBody
                                    id="chartTime"
                                    chartType="radialBarChartV2"
                                    title={this.state.chartTitle}
                                    changeTab={this.props.changeTab}
                                    setDataSetAndAttribute={this.setDataSetAndAttribute}
                                    metaData={this.props.dataModule.metaData}
                                    metricInfo={this.props.dataModule.recencyDetails[dmSelectedDataSource]}
                                    datasetVersion={this.props.dataModule.datasetVersion}
                                    filteredValues={this.props.dataModule.filteredValues}
                                    page="profile"
                                    video_url="profile_data"
                                    timeFilterApplied={this.props.dataModule.timeFilterApplied}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    showParititionFilter={true}
                                    showAttributeFilter={false}
                                    showZoom={true}

                                    chart_data={chartData}
                                    // ToDo Rename variable
                                    checkedAttributes={this.state.checkedAttributes}
                                    checkedPartition={this.state.checkedPartition}
                                    datasetLevelChart={childDatasetIds}
                                    attributeLevelChart={attributeList}
                                    allowPartition={this.allowPartition}
                                    allowAttributes={this.allowAttributes}
                                />
                            }
                        />
                        <BasicPortlet
                            video_url="profile_data"
                            className="mb-0 pb-0" id="timelinessTableData" title={this.state.gridTitle} bodyClassName="pb-0"
                            content={
                                <QualdoDataTable
                                    data={this.state.tableData}
                                    component_name="profile_data"
                                />
                            }
                        />
                    </div>
                )
                :
                (
                    this.state.showLoader === false && this.props.inProgress === true
                        ?
                        <BasicPortlet
                            video_url="profile_data"
                            className="pb-0"
                            title={this.state.chartTitle}
                            bodyClassName="pb-0"
                            id="timeliness"
                            content={
                                <>
                                    <ProgressStatus 
                                        progressStatus={this.props.progressStatus} 
                                    />
                                </>
                            }
                        />
                        :
                        (this.props.noDataFound === true ? 
                            <NoDataComponent
                                noContentSubtext={"Please Configure datasource to view conformity."}
                                customButtonName={"Configure Datasource"}
                                navigatePage={"/configure"}
                                noIntegration={this.props.noDataFound}/>
                        :
                         <>
                            {this.state.showLoader === true ? renderskloader(this.state.chartTitle, this.state.gridTitle) : ''}
                        </>
                    )
                )
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { setDataSource, setDataSet, setAttribute, setChipData, setFilteredValues, setTimeFilter, setStartDate, setEndDate })(Timeliness);