import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import $ from "jquery";
import {AlertEdit, ConsumeEdit, IntegrationEdit, MetricsEdit, MLModelEdit, TeamEdit} from './tableEdit';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {TeamTableButtons} from "./tableButtons";
import {ImageComponent} from "./imageComponent";
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import Select from "react-select";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTable, faInfoCircle, faSearch, faCheckCircle, faTimesCircle, faExternalLinkAlt, faSitemap,faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight, faWindowRestore} from '@fortawesome/free-regular-svg-icons';
import RcTooltip from 'rc-tooltip';
import {
    NO_DATA_SET_FOUND_TEXT, ROW_WARN_CLASS, EXCLUDED_COMPONENTS,
    NOT_SORTABLE, ABOVE_ALERT_SUPPORTED_TABLES, LIST_VIEW_OPTIONS, QUALDO_DOCUMENTATION
} from "../utils/constant";
import {get_document_link} from "../utils/common_utils";
import NoErrorInPreview from '../monitor/components/noErrorPreview';
import NoModelComponent from "../monitor/components/noModelComponent";
import Image from "../components/image"
import { Link } from 'react-router';
import Form from 'react-bootstrap/Form';


const renderTooltip = (props) => (
    <span {...props}>
      Click here to Mapping
    </span>
);

class QualdoDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.paginationSize = this.props.paginationSize;
        this.onPageChange = this.onPageChange.bind(this);
        this.component_name = this.props.component_name;
        this.handleSearchOperation = null;
        this.handleDeleteOperation = this.handleDeleteOperation.bind(this);
        this.handleSeeMore = this.handleSeeMore.bind(this);
        this.input = null;
        this.state = {
            hideSizePerPage: this.props.hideSizePerPage !== undefined ? this.props.hideSizePerPage : false,
            paginationSize: this.props.paginationSize,
            tableData: this.props.data,
            isDelete: false,
            tempData: this.props.data,
            selectedGroup: this.props.selectedGroup ? this.props.selectedGroup : LIST_VIEW_OPTIONS[0],
            isSeeMore:this.props.isSeeMore !== undefined ? this.props.isSeeMore : false,
            isSeeMoreId:Math.random().toString(36).replace('0.','btn_seemore_' || '')
        }
    }

    componentDidMount() {
        if(this.state.isSeeMore===true){
            this.handleSeeMore();
        }
    }

    shouldComponentUpdate(prevProps){
        if(this.props.id==="configuredDatasources"){
            if(JSON.stringify(prevProps.data.data) !== JSON.stringify(this.props.data.data)){
                // To handle rendering while edit and delete actions
                return true
            }
            // Only for Configure page : To avoid rerendering of Qualdo Data Table
            // While the first call all the datas will received here
            // Only once this table will gets rendered  to avoid the rerendering            
            return false
        }
        else{
            return true
        }
    }

    onPageChange() {

        var target = $('#monitorDQListView,#monitorDQListView');
        if (target.length) {
            $('html, body').animate({scrollTop:0},500);
        }

        if(ABOVE_ALERT_SUPPORTED_TABLES.includes(this.props.component_name)){
       $('tr.alert-warning-row').remove();
       setTimeout(function(){

        var prevClassFlag = 1;
        $('.table tbody tr').each(function() {

            if($(this).hasClass("row-highlight has-above-alert") && prevClassFlag === 1) {
                $(this).before('<tr class="alert-warning-row"><td colspan="6" class="p-0"><div class="alert alert-warning mb-0 py-sm-1 mt-2" role="alert"><p class="mb-0"><strong>No Refreshes found in the chosen date range. Displaying the data points of earlier refreshes.</strong></p></div></td></tr>');
            }

            if($(this).hasClass("alert-warning-row")){
                prevClassFlag = 0;
            }else {
                prevClassFlag = 1;
            }

        });

        }, 500);

     }

    }

    getActionComponent(item) {
        switch (this.props.component_name) {
            case 'datasource':
                return (
                    <IntegrationEdit
                        showToast={this.props.showToast}
                        integration_details={item.integration_data}
                        handleDelete={this.handleDeleteOperation}
                        environment={this.props.environment}
                        component_name={this.props.component_name}
                        updateTable={this.props.updateTable}
                        setEnvData={this.props.setEnvData}
                        setAddDS={this.props.setAddDS}
                        
                    />
                );

            case 'ml_model':
                return (
                    <MLModelEdit
                        showToast={this.props.showToast}
                        mlData={item.ml_model_data}
                        environment={this.props.environment}
                        getExistingABData={this.props.getExistingABData}
                        handleDelete={this.handleDeleteOperation}
                        modelHasModelABConfiguration={this.props.modelHasModelABConfiguration}
                        integrationOption={this.props.integrationOption}
                        datasets={this.props.datasets}
                        showModelABPopup={this.props.showModelABPopup}
                        handleModelABAddition={this.props.handleModelABAddition}
                        availableModels={this.props.availableModels}
                        modelVersionMap={this.props.modelVersionMap}
                        modelsDataMap={this.props.modelsDataMap}
                        modelTypeMap={this.props.modelTypeMap}
                        attributes={this.props.attributes}
                        component_name={this.props.component_name}/>
                );

            case 'default_metrics':
                let metricsDataDefault = item.metrics_data;
                return (
                    <MetricsEdit
                        unique_key={metricsDataDefault.key}
                        modelOptions={this.props.modelOptions}
                        showToast={this.props.showToast}
                        metrics_data={metricsDataDefault}
                        handleDelete={this.handleDeleteOperation}
                        modalTitle={this.props.modalTitle}
                        integrationOption={this.props.integrationOption}
                        additional_details={this.props.additional_details}
                        isSeeMore = {this.props.isSeeMore}
                        isSeeMoreId = {this.props.isSeeMoreId}
                        datasetInfo={this.props.datasetInfo}
                        component_name={this.props.component_name}
                        updateFollowStatus={this.props.updateFollowStatus}
                    />
                );
                case 'metrics':
                    const metricsData = item.metrics_data;
                    return (
                        <MetricsEdit
                            unique_key={metricsData.key}
                            modelOptions={this.props.modelOptions}
                            showToast={this.props.showToast}
                            metrics_data={metricsData}
                            handleDelete={this.handleDeleteOperation}
                            modalTitle={this.props.modalTitle}
                            integrationOption={this.props.integrationOption}
                            additional_details={this.props.additional_details}
                            datasetInfo={this.props.datasetInfo}
                            component_name={this.props.component_name}
                            updateFollowStatus={this.props.updateFollowStatus}
                        />
                    );
            case 'consume':
                let consume_item = item.consume;
                return (
                    <ConsumeEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        edit_title={"Notification Channel"}
                        delete_case={"delete_consume"}
                        consume_item={consume_item}
                        component_name={this.props.component_name}
                    />
                );
            case 'alert':
                let alert_item = item.alert;
                return (
                    <AlertEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        edit_title={this.props.modelTitle}
                        dataset={this.props.dataset}
                        integrationOption={this.props.integrationOption}
                        modelOptions={this.props.modelOptions}
                        delete_case={"delete_alert"}
                        alert_item={alert_item}
                        component_name={this.props.component_name}
                        metricsData={this.props.details}
                        tab={this.props.tab}
                    />
                );
            case 'consume_pipeline':
                let consume_pipeline = item.consume;
                return (
                    <ConsumeEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        edit_title={"Pipeline Channel"}
                        hideModelAlertsTab={this.props.hideModelAlertsTab}
                        changeTab={this.props.changeTab}
                        delete_case={"delete_pipeline"}
                        consume_item={consume_pipeline}
                        component_name={this.props.component_name}
                    />
                );
            case 'teamMate':
                let userDetails = item.userDetails;
                return (
                    <TeamEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        userDetails={userDetails}
                        ugmDetails={this.props.ugmDetails}
                        component_name={this.props.component_name}
                    />
                );
            case 'billingHistory':

                let billingDetails = item.billingDetails;
                return (
                    <>
                        <strong className="text-success mr-1">Paid</strong>
                        {
                            billingDetails.showTooltip
                                ?
                                <RcTooltip placement="top"
                                                overlay={
                                                    <span id={'tooltip-top'}>
                                                        Your next Billing date is
                                                        <br/> {billingDetails.nextDate}.
                                                    </span>
                                                }
                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                            >
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>
                                </RcTooltip>
                                :
                                ''
                        }

                    </>
                );
                case 'billingHistoryLink':
                    return (
                        <>
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>

                        </>
                    );
                case 'pbierrordashboard':
                    return (
                            <>
                         <div class="custom-control custom-switch d-inline-block" >
                                <input type="checkbox" checked={item.pbierrordashboard.actions === "1" ? "true" : ""} className="custom-control-input" onChange={(e) => this.props.pbistatus(e)}  id={item.pbierrordashboard.index}  /><label class="custom-control-label" for={item.pbierrordashboard.index}></label>
                        </div>
                            </>
                        );
        
                case 'pbimapping':
                    if(item['pbimapping'].status === 1) {
                        return (
                            <>
                                <button type="button" className="btn btn-sm btn-success mr-1"><FontAwesomeIcon icon={faCheckCircle} /> Mapped</button>
                                <button type="button" data-mappingid={item.pbimapping.id}  id={"mapping_"+item.pbimapping.id} onClick={(e) => this.props.setupMapping(item.pbimapping.id,item['pbimapping'].status)} className="btn btn-sm btn-outline-dark btn__ds-mapped mr-0"><FontAwesomeIcon icon={faSitemap} className="d-none" /> <FontAwesomeIcon icon={faPencilAlt} /><span></span></button> 
                            </>
                        );    
                        }else {
                            return (
                                <>
                                    <button type="button" data-mappingid={item.pbimapping.id}  id={"mapping_"+item.pbimapping.id} onClick={(e) => this.props.setupMapping(item.pbimapping.id,item['pbimapping'].status)} className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button> 
                                </>
                            );  
                        }

                   
            default:
                return (
                    <div/>
                );
        }

    }

    getButtonComponent(item) {
        switch (this.props.component_name) {
            case 'requests':
                return (
                    <TeamTableButtons
                        showToast={this.props.showToast}
                        userDetails={item.userDetails}
                        componentName={this.props.component_name}
                    />
                );
            case 'teamMate':
                if (item.userDetails !== undefined) {
                    return (
                        <ImageComponent
                            showToast={this.props.showToast}
                            userDetails={item.userDetails}
                            componentName={this.props.component_name}
                        />
                    );
                }
                else {
                    return (<span className="label label-success label-inline">{item}</span>);
                }
            default:
                return (
                    <div/>
                );
        }
    }

    async navigateFirstPage(props) {
        return props.onPageChange(1);
    }

    async handlePageChange(value) {
        this.setState({paginationSize: value});
        return;
    }

    async clearSearch(props) {
        this.setState({isDelete: false});
        return;
    }

    renderRow() {
        let result = []
        let headerKeys = {}
        this.state.tableData.headers.forEach((headerValue) => {
            headerKeys[headerValue] = null;
        });
        let j = 0;
        let isDatasetRowFound = false
        let datasetData;
        let selectedGroup;
        if(this.state.selectedGroup){
          selectedGroup = this.state.selectedGroup.value;
        }
        let fullData = this.props.component_name === "dqErrorListViewTable" ? this.state.tempData.data : this.state.tableData.data;
        fullData = _.cloneDeep(fullData)
        for (let i = 0; i < fullData.length; i++) {
            let rowItems = fullData[i];
            if(this.props.component_name === "dqErrorListViewTable"){
              if(rowItems[0].group === "dataset" && selectedGroup === "attributes"){
                isDatasetRowFound = true
                datasetData = rowItems[1]
                continue;
              }
              if(rowItems[0].group === "attribute" && selectedGroup === "datasets"){
                continue;
              }
            
              if(Object.keys(this.props.follow_attribute_details).length === 0){
                continue
            }

            let updatedAttributeStatus = this.props.follow_attribute_details[rowItems[4]['dataSourceId']][rowItems[4]['attributeId']];
            if((selectedGroup === "followedAttributes" && updatedAttributeStatus !== true) && (rowItems[0].group === "dataset" || updatedAttributeStatus !== true)){
                if(rowItems[0].group === "dataset"){
                   isDatasetRowFound = true
                   datasetData = rowItems[1]
                }
                continue;
              }

              if(selectedGroup === "attributes" && isDatasetRowFound === true){
                rowItems[0].value = rowItems[4].dataSourceName
                rowItems[1].value = datasetData
              }
              if(selectedGroup === "followedAttributes" && isDatasetRowFound === true && updatedAttributeStatus === true){
                rowItems[0].value = rowItems[4].dataSourceName
                rowItems[1].value = datasetData
              }
              isDatasetRowFound = false

            }
            let obj = _.cloneDeep(headerKeys);
            let keys = Object.keys(obj);
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === 'td') {
                    obj[keys[k]] = items.value
                } else if (items.type === "buttons") {
                    obj[keys[k]] = items;
                } else if (items.type === "component") {
                    obj[keys[k]] = items;
                } else if (items.type === "image") {
                    obj[keys[k]] = items;
                } else if (items.type === "singlebutton") {
                    obj[keys[k]] = items.value;
                }else if (items.type === "billingComponent") {
                    obj[keys[k]] = items;
                }
                else if (items.type === "billingComponentLink") {
                    obj[keys[k]] = items;
                }
                else {
                    obj[keys[k]] = items;
                }
            }

            if (this.props.component_name === "dqErrorListViewTable") {
                obj["ID"] = i + 1;
                if (rowItems[0]["group"] === "attribute" && !["attributes","followedAttributes"].includes(selectedGroup)) {
                    obj["R.No"] = "";
                }else {
                    j = j + 1
                    obj["R.No"] = j;
                }
            } else if (this.props.component_name === "modelABTestTable") {
                obj["ID"] = i + 1;
            } 
            else {
                if(this.props.component_name !== "datasourceView"){
                    obj["ID"] = i + 1;
                }
            }

            result.push(obj);
        }
        return result
    }


    getDefaultPaginationOptions() {
        let pageLimit = [10,25,50,100]
        let pageList = [{text: 5, value: 5}]
        for(let i=0;i<=(pageLimit.length-1);i++) {
           if(this.state.tableData.data.length >= pageLimit[i]){
              pageList.push({text: pageLimit[i], value: pageLimit[i]});
           }else{
              break
           }

        }
        if(this.props.component_name !== 'dqErrorListViewTable'){
            pageList.push({text: "All" , value: this.state.tableData.data.length});
        }

        const options = {
            custom: true,
            // totalSize: this.state.tableData.data.length,
            page: 1,  // which page you want to show as default
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }



    getCustomPaginationOptions(pageLimitCustom) {
        let pageLimit = this.props.pageOptions;
        if (pageLimit === undefined || pageLimit === null) {
            pageLimit = pageLimitCustom;
        }

        let sizePerPage = this.props.paginationSize;

        // Add the first option
        let pageList = [];

        for (let i = 0; i <= (pageLimit.length - 1); i++) {
            const currentValue = pageLimit[i];

            if (this.state.tableData.data.length >= currentValue) {
                pageList.push({text: currentValue, value: currentValue});
            } else {
                break;
            }

        }

        // Add the 'All' option
        if(this.props.component_name !== 'dqErrorListViewTable'){
            pageList.push({text: "All" , value: this.state.tableData.data.length});
        }

        const options = {
            custom: true,
            onPageChange: this.onPageChange,
            page: 1,  // which page you want to show as default
            // totalSize: this.state.tableData.data.length,
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: sizePerPage,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }

    convertData(actionType, headerValue, cell, row,rowIndex) {

        
        // If getActionComponent is provided in the props we can use that method itself
        if (this.props.customGetActionComponent !== undefined && this.props.customGetActionComponent !== null) {
            return this.props.customGetActionComponent(actionType, headerValue, cell, this.props,rowIndex);
        }

        if (headerValue === "Actions" && actionType === "component") {
            return this.getActionComponent(cell);
        } else if (headerValue === "Action" && actionType === "button") {
            return this.getButtonComponent(cell);
        } else if (headerValue === "Status" && actionType === "component") {
            return this.getButtonComponent(cell);
        } else if (headerValue === "Teammate" && actionType === "component") {
            return this.getButtonComponent(cell);
        } else if (headerValue === "Status" && actionType === "billingComponent") {
            return this.getActionComponent(cell);
        } else if (headerValue === "Receipt") {
           return <label className="btn btn-link"
                         onClick={() => this.props.pdfdownload(row)}>Download</label>
           // return <button onClick={() => this.props.pdfdownload(row)} type="button" class="btn btn-link">Download</button>

        } else if (headerValue === "Schema Learning Status") {
            let integrations = row.Actions;
            if(integrations !== undefined && integrations !== null && integrations.integration_data !== undefined && integrations.integration_data !== null) {
              let status_text = integrations.integration_data.status_text
              if(status_text === NO_DATA_SET_FOUND_TEXT) {
                 return <>{cell} <RcTooltip placement="top"
                                                overlay={
                                                    <span id={'tooltip-top'}>
                                                        No Datasets Found
                                                    </span>
                                                }
                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                >
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>
                                </RcTooltip></>
              }
            }

        }

          // Power BI 
          else if (headerValue === "Reports & Dashboards" && actionType === "component") {

            return (
                <span style={{cursor:'pointer'}}  className="text-blue cursor-pointer" onClick={() => this.props.pireportdatasetfullscreen(row)}>{row["Reports & Dashboards"]["pbierrordataset"]["name"]}<i className="small ml-1"><FontAwesomeIcon icon={faWindowRestore} /></i></span>
                //  <span  className="text-blue cursor-pointer" onClick={() => this.props.pireportdatasetfullscreen(row)}>{row["Reports & Dashboards"]["pbierrordataset"]["name"]}<i className="small ml-1"></i></span>
            )
    }
    else if (headerValue === "Dataset Summary" && actionType === "component") {
        
        return (
                <>
                <dl><dt>Last refresh time</dt><dd><strong>{row["Reports & Dashboards"]["pbierrordataset"]["refreshed_date"].substring(4)}</strong></dd></dl>
                <dl><dt>Last processed time</dt><dd><strong>{row["Reports & Dashboards"]["pbierrordataset"]["processedtime"].substring(4)}</strong></dd></dl>
                </>
        )
    }
    else if (headerValue === "Name" && actionType === "component") {

        return (
            <>
            <span style={{cursor:'pointer'}}  className="text-blue cursor-pointer" onClick={() => this.props.pireportfullscreen(row)}>{row["Name"]}<i className="small ml-1"><FontAwesomeIcon icon={faWindowRestore} /></i></span>
            <dl><dt>Datasource</dt><dd><strong>{row["Actions"]["pbierrordashboard"]["integration_name"]}</strong></dd></dl>
            <dl><dt>Workspace</dt><dd><strong>{row["Actions"]["pbierrordashboard"]["workspace"]}</strong></dd></dl>
            </>
            )
    }
   else if(headerValue === "Errors" && actionType === "component") {

    if(row["Quality Check Status"] === "Not yet started" ||  row["Quality Check Status"] === "In Progress"){
        return (<Image src="icon_powerbi_process" width="50%" height="20px" alt=""/>)
    }

    else if(row["Quality Check Status"] === "Completed"){
        
        let pageredirection = JSON.stringify(row["Actions"]["pbierrordashboard"]["redirection"]);
        if(row["Errors"] === "Error"){
            return (
             <Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="btn btn-md btn-outline-danger">
             <span className="mr-1">{row["Actions"]["pbierrordashboard"]["error_count"]} widgets have error</span><i className="small"><FontAwesomeIcon icon={faExternalLinkAlt} className="mr-0" /></i></Link>
             );
        }
        else if(row["Errors"] === "No Error"){
            return ( <Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="btn btn-md btn-outline-success">
            <span className="mr-1">No Error</span><i className="small"><FontAwesomeIcon icon={faExternalLinkAlt} className="mr-0" /></i></Link>);
        }
        else {
            return("---")
        }
    }
    else{
        return ("--");
    }
   }
   else if(headerValue === "Quality Status" && actionType === "component") {

    let pageredirection = JSON.stringify(row["Reports & Dashboards"]["pbierrordataset"]["redirection"]);

    if(row["Reports & Dashboards"]["pbierrordataset"]["qualitystatus"] === "Error"){
        return ( <Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="badge badge-light-danger border-0"><span ><FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> 
        Error</span></Link>);
    }
    else if(row["Reports & Dashboards"]["pbierrordataset"]["qualitystatus"] === "No Error"){
        return (<Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="badge badge-light-success border-0"><span ><FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> No Error</span></Link>);
    }
    else {
        return("---")
    }
   }
   else if(headerValue === "Quality Check Status" && actionType === "component") {
        if(row["Quality Check Status"] === "Not yet started"){
            return (<span className="text-muted">Not yet started</span>);
        }
        else if(row["Quality Check Status"] === "Completed"){
            return (<span className="text-success">Completed</span>);
        }
        else if(row["Quality Check Status"] === "in-progress"){
            return (<span className="text-warning">In Progress</span>);
        }
        else if(row["Quality Check Status"] === "Failed Authentication"){
            return (<span className="text-danger">Failed Authentication</span>);
        }
        else if(row["Quality Check Status"] === "Mapping Not Found"){
            return (<><span className="text-danger">Mapping Not Found</span><RcTooltip placement="top" overlay={renderTooltip} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}><Link to={"/pbi-mappiing"}><button className="btn btn-sm btn-outline-dark ml-2"><FontAwesomeIcon icon={faSitemap} className="mr-0" /></button></Link></RcTooltip></>);
        }

        }

        return cell;
    }

     static getDerivedStateFromProps(props, state) {
        if (props.data !== state.tableData) {
            return {tableData : props.data};
        } else if (props.paginationSize !== state.paginationSize && !EXCLUDED_COMPONENTS.includes(props.component_name)){
            return {paginationSize: props.paginationSize}
        }

        return null;
     }



    componentDidUpdate(prevProps) {
        if (prevProps.data !== undefined && this.props.data !== undefined) {
            // if (prevProps.data.data.length !== this.props.data.data.length) {
            if (JSON.stringify(prevProps.data.data) !== JSON.stringify(this.props.data.data)) {
                this.setState({tableData: this.props.data, tempData: this.props.data, selectedGroup: this.props.selectedGroup});
                this.setState({paginationSize: this.props.paginationSize});
            }
        }
        if(prevProps.selectedGroup !== this.props.selectedGroup){
          this.setState({selectedGroup: this.props.selectedGroup})
        }

    }

    handleDeleteOperation() {
        this.setState({isDelete: this.props.data});
    }

    handleSeeMore() {
            var curobj = $("#"+this.state.isSeeMoreId);
            curobj.toggleClass("active");
            curobj.parent().parent().find('.react-bootstrap-table__footer').toggleClass("d-none");
            curobj.parent().parent().find('table tbody tr').toggleClass("d-none");
            curobj.parent().parent().find('table tbody tr:first').removeClass("d-none");
            curobj.parent().parent().find('.react-table__length').toggleClass("d-none");
            curobj.parent().parent().find('.react-table__filter').toggleClass("d-none");
            $("#"+this.state.isSeeMoreId+" span").text(function(i, v){
                return v === 'See Less' ? 'See More' : 'See Less'
            })

    }

    render() {
        let hasData = this.state.tableData.data.length > 0;
        const customTotal = (from, to, size) => (
            <span className="react-table__info">
                    Showing <b>{from}</b> to <b>{to}</b> of <b>{size}</b> results
            </span>
        );

        const addClassFailure = (row, rowIndex) => {
            if (row === undefined || row === null) {
                return "";
            }

            if (row.Actions === undefined || row.Actions === null) {
                return "";
            }

            let integration_data = row.Actions;
            if (integration_data === undefined || integration_data === null) {
                return "";
            }

            if (integration_data.integration_data === undefined || integration_data.integration_data === null){
                return "";
            }

            let connection_status = integration_data.integration_data["status_text"];
            if (connection_status === "connection_failure") {
                return "has-table-error";
            }

            if (connection_status === NO_DATA_SET_FOUND_TEXT) {
                return ROW_WARN_CLASS;
            }

            return "";
        };

        const pageListRenderer = ({
                                      pages,
                                      onPageChange
                                  }) => {
            return (
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        {
                            pages.map(p => (
                                <button className="btn btn-success"
                                        onClick={() => onPageChange(p.page)}>
                                    {p.page}
                                </button>
                            ))
                        }
                    </div>
                </div>
            );
        };

        let options;
        let lengthLimit = 0;
        if (this.state.paginationSize === undefined || this.state.paginationSize === null ) {
            options = this.getDefaultPaginationOptions();

            lengthLimit = 5;
        }
        else {

            if(this.props.component_name === "dqErrorListViewTable") {
                options = this.getCustomPaginationOptions([1, 5,10,20,30,50,100,150,200,250]);
                lengthLimit = this.state.paginationSize;
            }else if(this.props.component_name === "modelErrorListViewTable" || this.props.component_name === "mpErrorListViewTable"){
                options = this.getCustomPaginationOptions([2, 4, 10, 25, 50]);
                lengthLimit = this.state.paginationSize;
            } else if (this.props.pageOptions !== undefined) {
                options = this.getCustomPaginationOptions(this.props.pageOptions);
                lengthLimit = this.state.paginationSize;
            }else {
                options = this.getCustomPaginationOptions([3,6,10,25,50,100]);
                lengthLimit = this.state.paginationSize;
            }

             if(ABOVE_ALERT_SUPPORTED_TABLES.includes(this.props.component_name)){
                $('tr.alert-warning-row').remove();
                setTimeout(function(){
                    var prevClassFlag = 1;
                    $('.table tbody tr').each(function() {
                            if($(this).hasClass("row-highlight has-above-alert") && prevClassFlag === 1) {
                               $(this).before('<tr class="alert-warning-row"><td colspan="6" class="p-0"><div class="alert alert-warning mb-0 py-sm-1 mt-2" role="alert"><p class="mb-0"><strong>No Refreshes found in the chosen date range. Displaying the data points of earlier refreshes.</strong></p></div></td></tr>');
                            }
                    if($(this).hasClass("alert-warning-row")){
                        prevClassFlag = 0;
                    }else {
                        prevClassFlag = 1;
                    }

             });

        }, 100);                }
        }
        options["paginationTotalRenderer"] = customTotal;
        options["pageListRenderer"] = pageListRenderer;
        options["hideSizePerPage"] = this.state.hideSizePerPage;

        let data = this.renderRow();

        const sizePerPageRenderer = ({
                                         options,
                                         currSizePerPage,
                                         onSizePerPageChange
                                     }) => (
            <div className="react-bootstrap-table__header">
                <div className="row">
                    <div className={this.props.component_name === "dqErrorListViewTable" ? "col-12 col-lg-8" : "col-6 col-md-6"}>
                        <div className={this.props.component_name === "dqErrorListViewTable" ? "lv_tbl-filter" : "d-flex"}>
                            <div className="btn-group react-table__length" role="group">
                                <span>Show</span>
                                <Select
                                    classNamePrefix='select-control'
                                    defaultValue={lengthLimit > this.state.tableData.data.length  ? {"label": "All","value":this.state.tableData.data.length} : {"label":lengthLimit,"value":lengthLimit}}
                                    options={options.map((option) => ({'label': option.text, 'value': option.page}))}
                                    onChange={(selectedOption) => {
                                        this.handlePageChange(selectedOption.value).then((res) => {
                                           onSizePerPageChange(selectedOption.value);
                                        });
                                    }}
                                />
                                <span>items</span>
                            </div>
                            {this.props.component_name === "dqErrorListViewTable" ? 
                                <>
                                    <div className="row g-1 align-items-center">
                                        <div className="col-auto pr-0 d-none d-md-inline-flex">
                                            <label className="col-form-label">View: </label>
                                        </div>
                                        <div className="col-auto pr-0" style={{minWidth: "200px"}}>
                                            <Select
                                                classNamePrefix='select-control'
                                                value={this.state.selectedGroup}
                                                options={LIST_VIEW_OPTIONS}
                                                isOptionDisabled={(option) => option.disabled}
                                                onChange={this.props.changeGroup}
                                            />
                                        </div>
                                    </div> 
                                    <Form.Check 
                                        custom
                                        type='checkbox'
                                        id='grid_include-partitions'
                                        label="Include Partitions"
                                        defaultChecked={this.props.showpartitions}
                                        onChange={this.props.changePartitions}
                                    />
                                </>
                                : ""
                            }
                        </div>
                    </div>
                    <div className={this.props.component_name === "dqErrorListViewTable" ? "col-12 col-lg-4" : "col-6 col-md-6"}>
                        <div className={this.props.component_name === "dqErrorListViewTable" ? "btn-group react-table__filter justify-content-center justify-content-lg-end" : "btn-group react-table__filter"} role="group">
                            <span>Search:</span>
                            <div className="table-search">
                                <input
                                    placeholder={"Search"}
                                    id={"searchBox"}
                                    className="form-control"
                                    ref={n => this.input = n}
                                    onChange={this.handleSearchOperation}
                                    style={{backgroundColor: 'white', align: 'right'}}
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        options["sizePerPageRenderer"] = sizePerPageRenderer.bind(this);


        let actionType = null;
        if (this.state.tableData.data != null && this.state.tableData.data.length > 0) {
            let rowItems = this.state.tableData.data[0];
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === "buttons") {
                    actionType = "button";
                } else if (items.type === "component") {
                    actionType = "component";
                } else if (items.type === "image") {
                    actionType = "image";
                } else if (items.type === "billingComponent") {
                    actionType = "billingComponent";
                }
                else if (items.type === "billingComponentLink") {
                    actionType = "billingComponentLink";
                }
            }
        }

        const addClassForColumns = (cell, row, rowIndex, colIndex) => {
            return "";
        }

        const rowClasses = (row, rowIndex) => {

            if (this.props.component_name === 'dqErrorListViewTable') {
                if (row['Attribute Name'] === 'Dataset Level - Includes All Attributes') {
                    let previousTime = row["Dataset Name"]["previousTime"];
                    if (previousTime === null) {
                        return 'row-highlight';
                    }

                    return 'row-highlight has-above-alert';
                }

            } else {
                return this.props.customRowHighlighter === undefined ?
                    addClassFailure(row, rowIndex) : this.props.customRowHighlighter(row, rowIndex);
            }
        };

        const classFuncForCols = this.props.getCustomClassForColumns === undefined ?
            addClassForColumns :
            this.props.getCustomClassForColumns;

        let is_table_not_sortable = (this.state.tableData.not_sortable !== undefined &&
            this.state.tableData.not_sortable === true);

        // let columns = this.state.tableData.headers.map(x => ({
        //     'dataField': x,
        //     'text': x,
        //     'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true) ? false : true,
        //     'searchable': true,
        //     'formatter': this.convertData.bind(this, actionType, x),
        //     'classes': classFuncForCols
        // }));

        let columns = this.state.tableData.headers.map((x) => (
            x==="Metric Value" ? 
            {
            'dataField': x,
            'text': x,
            'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true) ? false : true,
            'searchable': true,
            'formatter': this.convertData.bind(this, actionType, x),
            'classes': classFuncForCols,
            'sortFunc': (a, b, order, dataField, rowA, rowB) => {
                if(dataField==="Metric Value"){
                a = String(a).replace(/[^0-9.-]/g, '');
                b = String(b).replace(/[^0-9.-]/g, '');
                if (order === "asc") return Number(a) - Number(b);
                 else return Number(b) - Number(a);
                }  
            } 
        }
        :
        {
            'dataField': x,
            'text': x,
            'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true) ? false : true,
            'searchable': true,
            'formatter': this.convertData.bind(this, actionType, x),
            'classes': classFuncForCols 
        }
        ));

        switch (this.props.component_name) {
          
            case "dqErrorListViewTable":
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': false,
                    'classes': classFuncForCols,
                    'formatter': (cell, row) => row["R.No"]
                }, ...columns];
                break;
            case "modelABTestTable":
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': false,
                    'classes': classFuncForCols,
                    'formatter': this.props.tableIDFormatter
                }, ...columns];
                break;
            default:
            if(this.props.component_name!=="datasourceView") {
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': true,
                    'classes': classFuncForCols
                }, ...columns];
            }
                break;
        }

        const afterSearch = (newResult) => {
            $('tr.alert-warning-row').remove();
        };

        let CustomSearchBar = (props) => {
            if (this.state.isDelete) {
                this.clearSearch(props).then((res) => {
                    props.onClear();
                    if (this.input !== null && this.input !== undefined) {
                        this.input.value = "";
                    }
                });
            }
            const handleClick = () => {
                this.navigateFirstPage(props).then((res) => {
                    props.onSearch(this.input.value)
                });
            };
            this.handleSearchOperation = handleClick.bind(this);
            return (""
                // <div>
                //     <label htmlFor={"searchBox"}>Search: </label>
                //     <input
                //         placeholder={"Search"}
                //         id={"searchBox"}
                //         className="form-control"
                //         style={{backgroundColor: 'white', align: 'right'}}
                //         ref={n => input = n}
                //         onChange={handleClick}
                //         type="text"
                //     />
                // </div>
            );
        };

        let BindedCustomSearchBar = CustomSearchBar.bind(this);
        const contentTable = ({paginationProps, paginationTableProps}) => (
            <>
                {
                    hasData && (this.state.tableData.data.length > lengthLimit || (this.props.component_name === "dqErrorListViewTable"))
                        ?
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        :
                        ''
                }

                {hasData ? <><ToolkitProvider
                    keyField="ID"
                    columns={columns}
                    data={data}
                    search={ { afterSearch } }
                >
                    {
                        toolkitprops => {
                            let classNameForTable = this.props.component_name === 'dqErrorListViewTable' ? "table-striped" : "";
                            if (this.props.classNameForTable !== undefined) {
                                classNameForTable = this.props.classNameForTable;
                            }

                            return (
                                <>
                                    {
                                        hasData
                                            ?
                                            <>
                                            <BindedCustomSearchBar {...toolkitprops.searchProps}
                                                                   {...paginationProps}/>
                                            </>
                                            :
                                            ''
                                    }


                                    <BootstrapTable
                                        bootstrap4
                                        bordered={false}
                                        responsive
                                        // striped
                                        // hover
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        wrapperClasses={this.props.component_name === "environmentInfo" ? "table-responsive" : "table-responsive action-sticky-cell"}
                                        classes={classNameForTable}
                                        tableHeaderClass="qd-table mb-0"
                                        tableBodyClass="qd-table mb-0"
                                        className="action-sticky-cell"
                                        rowClasses={rowClasses}

                                    />
                                </>
                            );
                        }
                    }
                </ToolkitProvider></> : ""}
                <div className="react-bootstrap-table__footer">
                    <div className="row">
                        {
                            hasData ? <div className="col-sm-12 col-md-6"> <PaginationTotalStandalone {...paginationProps} /> </div>
                                :
                                ''
                        }
                        <div className="col-sm-12 col-md-6">
                            {hasData && this.state.tableData.data.length > lengthLimit ?  <PaginationListStandalone {...paginationProps} /> : ''}
                        </div>
                    </div>
                </div>
                {
                     this.state.isSeeMore === true ?
                    <div  className="table__seemore">
                                <button id={this.state.isSeeMoreId} className="btn active" onClick={this.handleSeeMore} ><span>See Less</span>
                                <i>
                                            <FontAwesomeIcon icon={faArrowAltCircleRight}/>
                                </i>
                                 </button>
                    </div>
                    :
                    ''
                    }
            </>
        );
        if (hasData) {
            return (
                <>
                    <PaginationProvider
                        pagination={
                            paginationFactory(options)
                        }
                    >
                        {contentTable}
                    </PaginationProvider>
                </>
            );

        } else {
            switch(this.props.component_name) {
                case "modelErrorListViewTable":
                case "mpErrorListViewTable":
                      if (this.props.message != null && this.props.message === "in-progress"){
                         return <NoErrorInPreview
                                message={'Model Profiling is in-progress.'} footer={''}/>
                      } else {
                        return <NoModelComponent model = {true}/>
                      }
                case "mpCompareViewTable":
                    if (this.props.message != null && this.props.message === "in-progress") {
                        return <NoErrorInPreview
                            message={'Model Profiling is in-progress.'} footer={''}/>
                    } else {
                        const subText = "No model AB test configured yet.";
                        return <NoModelComponent model = {true} customButtonName={"Configure Model AB"}
                                                 noContentSubtext={subText}
                        />
                    }
                case "dqErrorListViewTable":
                   if (this.props.message !== null){
                        let message = this.props.message;
                        return <NoErrorInPreview
                            message={message}
                            footer={''}
                        />
                   } else {
                        return (<div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4 className='opacity-50'>No data available</h4>
                                <p>For help, check out the <a href={get_document_link(this.props.component_name)} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                            </div>
                    );
                   }
                case "teamMate":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </h1>
                            <h4>Teammates not available</h4>
                            <p>Try broadening your filters or search terms.</p>
                          </div>
                    );
                case "invitation":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </h1>
                            <h4>We couldn't find any invitations sent from this account</h4>
                            <p>No invitation at the moment.</p>
                          </div>
                    );
                case "requests":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </h1>
                            <h4>You have no incoming team requests.</h4>
                          </div>
                    );
                case "modelABTestTable":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faTable}/>
                                </i>
                            </h1>
                            <h4>Model AB is not configured</h4>
                            <p>Please configure Model AB test</p>
                          </div>
                    );
                default:
                    return (<div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4>No data available</h4>
                                <p>For help, check out the <a href={get_document_link(this.props.component_name)} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                            </div>
                    );
            }
        }
    }
}

export default QualdoDataTable;