import React from 'react';
import Select from "react-select";
import BasicPortlet from "../components/basicPortlet";
import QualdoDataTable from "../components/bootstrapTable";
import { DISPLAY_DATE_FORMAT, MONITOR_DQP_LIST_VIEW_HEADERS, IS_NONE_CHECK } from "../utils/constant";
import { getDefaultDateRangeOptions } from "../utils/common_utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faArrowRight, faChartArea, faInfoCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import DqpErrorFullScreen from "./dqpErrorFullScreen";
import { connect } from "react-redux";
import ThumbnailChartList from "./components/thumbnailChartList";
import CustomSelect from "./components/customSelect";
import _ from 'lodash';
import Load from '../components/loadAction';
import RcTooltip from 'rc-tooltip';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from '../components/image';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import {
    Option, changeDataSourceAdvanced, handleTime, handleChangeDataset, changeAttribute,
    handleSubmit, handleCheckClick, getPlaceholder
} from "./components/filter_common_utils";
import FollowComponent from "./components/followComponent"
import { addFollowAttributes, addMonitorDQListViewData } from "../redux/actions";

import $ from 'jquery';
import ReactDOM from 'react-dom';
import AttributeNameComp from "../components/tableCellComponents/attributeNameComp";
import { normalizeChildDatasetName } from '../utils/attribute_name_utils';
// import AttributeTreeSelect from "../components/attributeTreeSelect"

import {
    getMonitorListViewData,
} from "../utils/monitorEventHandling";
import CustomTreeFilter from './components/customTreeFilter';
import { getMetricsOption } from "./components/filter_common_utils";
import { LIST_VIEW_OPTIONS } from "../utils/constant";

let selected_Group;

class DqpListViewContent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.getDQListTableComponents = this.getDQListTableComponents.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.getFilteredData = this.getFilteredData.bind(this);
        this.changeDataSource = this.changeDataSource.bind(this);
        this.handleTime = handleTime.bind(this);
        this.changeAttribute = changeAttribute.bind(this);
        this.handleCheckClick = handleCheckClick.bind(this);
        this.handleSubmit = handleSubmit.bind(this);
        this.handleChangeDataset = handleChangeDataset.bind(this);
        this.changeDataSourceAdvanced = changeDataSourceAdvanced.bind(this);
        this.changeDataset = this.changeDataset.bind(this);
        this.closeFullScreenMode = this.closeFullScreenMode.bind(this);
        this.handleDQListViewTimeFilter = this.handleDQListViewTimeFilter.bind(this);
        this.getCustomClassForActions = this.getCustomClassForActions.bind(this);
        this.getPlaceholder = getPlaceholder.bind(this);
        this.changeGroup = this.changeGroup.bind(this);

        this.fetchDataSource = this.fetchDataSource.bind(this)
        this.getMonitorListViewData = getMonitorListViewData.bind(this)
        // New Filter 
        this.clearAllFilterData = this.clearAllFilterData.bind(this)
        this.loadDataSourceRender = this.loadDataSourceRender.bind(this)
        this.applyDataset = this.applyDataset.bind(this)
        this.applyDateFilter = this.applyDateFilter.bind(this)
        this.toggleFilter = this.toggleFilter.bind(this)
        this.getMetricsOption = getMetricsOption.bind(this);
        this.changePartitions = this.changePartitions.bind(this);
        this.changeGroup = this.changeGroup.bind(this);
        this.updateFollowAttributes = this.updateFollowAttributes.bind(this)


        // this.onTreeChange = this.onTreeChange.bind(this);
        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });

        // eslint-disable-next-line
        const metaDataMapping = this.props.metaData;
        // const monitorDQListViewData = this.state.monitorDQListView;
        // let showLoader = true;
        // if (monitorDQListViewData === undefined || monitorDQListViewData === null) {
        //     showLoader = true;
        // } else {
        //     showLoader = !monitorDQListViewData.apiFetchOver;
        // }

        // let metricsList = this.props.monitorModule.metrics;



        let mappedDatasetInfo = this.props.mappedDatasetInfo
        // eslint-disable-next-line
        // eslint-disable-next-line
        let timelinessData = this.props.timelinessData

        this.state = {
            selectedDataSet: [],
            selectedDataSourceID: null,
            isInFullScreenMode: false,
            startDate: startDate,
            endDate: endDate,
            selectedDatasource: null,
            datasets: [],
            selectedGroup: null,
            showAttributes: true,
            attributeOptions: [],
            originalAttributeOptions: [],
            selectedAttributes: [],
            fullScreenClassVal: "",
            listViewTableData: [],
            currListViewTableData: [],
            dataFilteredValues: [],
            completeTableData: {},
            defaultDatasourceInside: null,
            defaultDatasetInside:null,
            lastProfilingTimeData: null,
            timeFilterApplied: false,
            fullScreenData: null,
            fullScreenAttributeData: null,
            keyData: 'dqListView_0',
            initial: {
                datasource: "",
                dataset: "",
                attribute: "",
            },

            showLoader: true,
            mappedDatasetInfo: mappedDatasetInfo,
            monitorDQListView: {},
            selectedDataSourceName: "",
            clearAllFilter: false,
            isFilterMode: true,
            showpartitions: true,
            follow_attributes: this.props.follow_attributes,
            // timelinessData:timelinessData,
            inital_filter_data : [],
            inital_dataset:[],
            inital_attibute:[],
            last_processed_time:null,
            noIntegration: this.props.noIntegration,
            inProgress: null,
            noDataFound: this.props.noDataFound,

        }
    }

    onTreeChange = (currentAttributeId, isSelected) => {
        let prevSelections = this.state.selectedAttributes;

        if (isSelected) {
            // Add current id to selection
            prevSelections.push({ "label": currentAttributeId, "value": currentAttributeId });
        } else {
            // Remove the current entry from list
            prevSelections = prevSelections.filter(x => x.value !== currentAttributeId);
        }

        this.setState({ selectedAttributes: prevSelections });
    };


    assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === "object") {
                node.path = stack ? `${stack}.${k}` : k;
                this.assignObjectPaths(node, node.path);
            }
        });
    };

    setFullScreen(selectedOption) {
        let selectedDatasetlist = this.props.filterData["parentDatasets"].filter((data)=> 
               ((parseInt(data.integration_id) === parseInt(this.state.selectedDataSourceID) || data.value !== "*") &&  data.checked === true)
        ) 

        let id = selectedOption.value
        let level = selectedOption.level
        let datasourceName = selectedOption.dataSourceName
        let datasetName = selectedOption.datasetName
        let dataSourceId = selectedOption.dataSourceId

        let lastProfilingTime = selectedOption.lastProfilingTime
        let defaultDatasourceInside = { "label": datasourceName, "value": dataSourceId }
        let defaultDatasetInside = { "label": datasetName, "value": id }
        if (level === "dataset") {
            let datasetLevelData = this.state.monitorDQListView.dataset_level_data
            let filteredData = datasetLevelData[id.toString()]
            let attributeLevelData = this.state.monitorDQListView.attribute_level_data
            let filteredAttributeData = attributeLevelData[id.toString()]
            let filteredTimeliness = []
            let filteredTimelinessData = this.props.timelinessData.DefaultDataSet[0]
            if (filteredTimelinessData !== undefined) {
                filteredTimeliness = filteredTimelinessData.data.filter(x => x.data_set_id === id)
            }
            let fullScreenData = {}
            fullScreenData[id] = filteredData
            this.setState({
                fullScreenClassKey: "show",
                isInFullScreenMode: true,
                isDatasetLevel: true,
                datasetName: datasetName,
                filteredTimeliness: filteredTimeliness,
                lastProfilingTime: lastProfilingTime,
                datasourceName: datasourceName,
                defaultDatasetInside: defaultDatasetInside,
                defaultDatasourceInside: defaultDatasourceInside,
                fullScreenData: fullScreenData,
                fullScreenAttributeData: filteredAttributeData,
                selectedGroup: { "label": "Dataset + Attribute", "value": "all" },
                inital_filter_data:this.props.filterData,
                inital_dataset:selectedDatasetlist,
                inital_attibute:this.props.filterData["attributesOptions"]
        
            })
        } else {
            let attributeLevelData = this.state.monitorDQListView.attribute_level_data
            let filteredAttributeData = attributeLevelData[id.toString()]
            let attributeId = selectedOption.attributeId
            let attributeName = selectedOption.attributeName
            let finalFilteredData = { attributeId: filteredAttributeData[attributeId] }
            let defaultAttributeInside = { "label": attributeName, "value": attributeId }
            this.setState({
                fullScreenClassKey: "show",
                isInFullScreenMode: true,
                isDatasetLevel: false,
                datasetName: datasetName,
                datasourceName: datasourceName,
                defaultDatasourceInside: defaultDatasourceInside,
                defaultDatasetInside: defaultDatasetInside,
                defaultAttributeInside: defaultAttributeInside,
                fullScreenAttributeData: finalFilteredData,
                selectedGroup: { "label": "Attribute Only", "value": "attributes" },
                inital_filter_data:this.props.filterData,
                inital_dataset:String(JSON.stringify(this.props.filterData["parentDatasets"])),
                inital_attibute:String(JSON.stringify(this.props.filterData["attributesOptions"]))
            })
        }

    }
    changePartitions() {
        
        let filteredRows = [];
        for (let rowData of this.state.currListViewTableData) {
            let check = this.props.filterData.parentDatasets.filter((data) =>
                parseInt(data.value) === parseInt(rowData[4].value)
            ).length;

            if (this.state.showpartitions) {
                if (check > 0) {
                    filteredRows.push(rowData);
                }
            }
            else {
                filteredRows.push(rowData);
            }

        }
        this.setState({
            dataFilteredValues: filteredRows,
            showpartitions: !this.state.showpartitions
        });
        //  this.setState({showpartitions:!this.state.showpartitions}, function() {
        //});
    }
    clearAllFilterData() {
        this.setState({ filterData: this.props.filterData, clearAllFilter: true })
    }
    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }
    componentDidMount() {
        if(this.props.default_filter_data.datasource!==undefined && this.props.default_filter_data.datasource > 0 ){
            
            this.setState({
                seletedDatasourceId:this.props.default_filter_data.datasource,
                seletedDatasourceName:this.props.default_filter_data.datasourcename,
                startDate: this.props.default_filter_data.start_date !==undefined ? this.props.default_filter_data.start_date : this.state.startDate,
                endDate: this.props.default_filter_data.end_date !==undefined ? this.props.default_filter_data.end_date : this.state.endDate ,
               timeFilterApplied: true
            }, function() {
                this.fetchDataSource(this.props.default_filter_data.datasource, 'dqp_list_view')
            });
        }else {
            this.fetchDataSource();   
        }
        setTimeout(
            () => {
                $(window).scroll(function (e) {
                    $('.loading-thumb-graph').each(function () {
                        let pos = $(this).offset(),
                            wX = $(window).scrollLeft(), wY = $(window).scrollTop(),
                            wH = $(window).height(), wW = $(window).width(),
                            oH = $(this).outerHeight(), oW = $(this).outerWidth();
                        let index = $(this).data("value");
                        let loadid = $(this).data("id");
                        // check the edges
                        if (pos.left >= wX && pos.top >= wY &&
                            oW + pos.left <= wX + wW && oH + pos.top <= wY + wH) {

                            ReactDOM.render(<ThumbnailChartList listData={index.value}
                                key={"dqErrorsListView"}
                                filterType={selected_Group}
                                noDataAvailable={index.noDataAvailable}
                            >
                            </ThumbnailChartList>, document.getElementById(loadid));
                            $(this).removeClass("loading-thumb-graph")
                        }
                        else
                            if (((pos.left <= wX && pos.left + oW > wX) ||
                                (pos.left >= wX && pos.left <= wX + wW)) &&
                                ((pos.top <= wY && pos.top + oH > wY) ||
                                    (pos.top >= wY && pos.top <= wY + wH))) {
                                const loadidElement = document.getElementById("loadid")
                                if (loadidElement) {
                                    ReactDOM.render(<ThumbnailChartList listData={index.value}
                                        key={"dqErrorsListView"}
                                        filterType={selected_Group}
                                        noDataAvailable={index.noDataAvailable}
                                    >
                                    </ThumbnailChartList>, document.getElementById(loadid));
                                    $(this).removeClass("loading-thumb-graph")
                                }

                            }
                    })

                });
            },
            500
        );
    }

    changeDataset(selectedDataSet) {
        let filteredRows = [];
        let lastProfilingTimeData = null;
        let initial = this.state.initial

        for (let rowData of this.state.currListViewTableData) {
            let filteredDataset = selectedDataSet.filter(x => x.value === rowData[4].value)
            if (filteredDataset.length > 0) {
                initial.dataset = filteredDataset[0].value
                let lastProfilingTime = rowData[1]["lastProfilingTime"]
                if (lastProfilingTime !== undefined) {
                    lastProfilingTimeData = lastProfilingTime
                }
                filteredRows.push(rowData);
            }
        }

        this.setState({
            dataFilteredValues: filteredRows,
            lastProfilingTimeData: lastProfilingTimeData,
            initial: initial
        });
        this.handleChangeDataset(selectedDataSet);

    }

    fetchDataSource(value = null, tab = null) {
        let teamID = localStorage.getItem('team_id')
        // let tenantData = { "tenant_id": teamID };
        let integrationId = value
        if (tab === 'dqp_list_view') {
            if (this.state.selectedDataSourceID !== Number(integrationId)) {
                this.getMonitorListViewData(teamID, this.props.addMonitorDQListViewData, integrationId);
            }
            else{
                this.setState({ showLoader: false })
            }
        }
        else {
            this.getMonitorListViewData(teamID, this.props.addMonitorDQListViewData);
            // this.setState({ showLoader: true })
        }
    }

    applyDataset(pm) {

        if (this.state.showpartitions === false) {
            let restrictchilddataset = [];
            for(let loop=0; loop<pm.dataset.length;loop++){
                let check = this.props.filterData.parentDatasets.filter((data) =>
                parseInt(data.value) === parseInt(pm.dataset[loop].value)
            ).length;
            if (check > 0) {
                restrictchilddataset.push(pm.dataset[loop]);
            }
            }
            pm.dataset = restrictchilddataset;
        }

        let obj = {
            "datasource": pm.datasource,
            "dataset": pm.dataset,
            "attribute": pm.attribute
        }
        this.setState({ selectedDataSet: pm.dataset, selectedAttributes: pm.attribute }, function () {
            this.handleSubmit(obj);
        });
    }

    applyDateFilter(start_date, end_date) {
        let obj = {
            "datasource":this.state.selectedDataSourceID,
            "datasourcename":this.state.selectedDataSourceName,
            "start_date":start_date,
            "end_date":end_date,
        }
        this.props.setCustomFilterValues(obj);
        let picker = { startDate: start_date, endDate: end_date };
        let event = "";
        this.handleDQListViewTimeFilter(event, picker);
    }

    //  handleDQListViewTimeFilter(event, picker)

    loadDataSourceRender(selectedDataSource) {

      ///  this.props.filterData = this.props.filterDataInitial

        // return;
        let obj = {
            "datasource": selectedDataSource.integration_id,
            "datasourcename":selectedDataSource.integration_name,
            "start_date": this.state.startDate,
            "end_date": this.state.endDate,
        }
        this.props.setCustomFilterValues(obj);

        let selectedDataSourceobj = { "label": selectedDataSource.integration_name, "value": selectedDataSource.integration_id }
        this.setState({ showLoader: true, clearAllFilter: false })
        let selectedDataSourceID = selectedDataSource.integration_id;
        let initial = this.state.initial
        initial.datasource = selectedDataSourceID
        let filteredRows = [];
        for (let rowData of this.state.currListViewTableData) {
            if (rowData[4].dataSourceId === selectedDataSourceID) {
                filteredRows.push(rowData);
            }
        }
        let datasets = []

        for (const [key, value] of Object.entries(this.props.mappedDatasetInfo["idDatasetDetail"])) {
            if (value["dataSourceId"] === selectedDataSourceID) {
                datasets.push({ "label": normalizeChildDatasetName(value["datasetName"]), "value": key });
            }
        }
        let lastProfilingTimeData = this.props.dataModule.lastProfilingTime[selectedDataSourceID]

        this.setState({
            selectedDatasource: selectedDataSourceobj,
            dataFilteredValues: filteredRows,
            datasets: datasets,
            selectedDataSet: [],
            selectedAttributes: [],
            selectedDataSourceID: selectedDataSource.integration_id,
            selectedDataSourceName: selectedDataSource.integration_name,
            lastProfilingTimeData: lastProfilingTimeData,
            initial: initial
        });

        this.fetchDataSource(selectedDataSource.integration_id, 'dqp_list_view')
        this.setState({ loader: true })

    }

    changeDataSource(selectedDataSource) {
        this.setState({ showLoader: true })

        let selectedDataSourceID = selectedDataSource.value;
        let initial = this.state.initial
        initial.datasource = selectedDataSourceID
        let filteredRows = [];
        for (let rowData of this.state.listViewTableData) {
            if (rowData[4].dataSourceId === selectedDataSourceID) {
                filteredRows.push(rowData);
            }
        }
        let datasets = []

        for (const [key, value] of Object.entries(this.state.mappedDatasetInfo["idDatasetDetail"])) {
            if (value["dataSourceId"] === selectedDataSourceID) {
                datasets.push({ "label": normalizeChildDatasetName(value["datasetName"]), "value": key });
            }
        }
        let lastProfilingTimeData = this.props.dataModule.lastProfilingTime[selectedDataSourceID]

        this.setState({
            selectedDatasource: selectedDataSource,
            dataFilteredValues: filteredRows,
            datasets: datasets,
            selectedDataSet: [],
            selectedAttributes: [],
            selectedDataSourceID: selectedDataSourceID,
            lastProfilingTimeData: lastProfilingTimeData,
            initial: initial
        });
        // this.props.renderChild(selectedDataSource.value)
        this.fetchDataSource(selectedDataSource.value, 'dqp_list_view')
        this.setState({ loader: true })
    }

    getFilteredData(data, startDate, endDate, considerEndAlone = false) {
        let updatedThumbnailCharts = []
        for (let _singleThumbnailData of data) {
            const actChartData = _.cloneDeep(_singleThumbnailData.chartData);
            let timeArr = actChartData.time;
            let valArr = actChartData.values;
            let driftPatterns = actChartData.drift_patterns;
            let versionNames = actChartData.version_name;
            let newValues = []
            let newDrift = []
            let newVersion = []
            if (timeArr === undefined) {
                continue;
            }
            let filteredArr
            if (considerEndAlone) {
                filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(endDate));
            } else {
                filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(endDate) && moment(x).isSameOrAfter(startDate));
            }

            const filteredLength = filteredArr.length;
            if (filteredLength === 0) {
                continue;
            }
            let startIndex = timeArr.indexOf(filteredArr[0]);
            let endIndex = timeArr.indexOf(filteredArr[filteredLength - 1]);
            for (let i = startIndex; i <= endIndex; i++) {
                newValues.push(valArr[i])
                if (versionNames !== undefined && versionNames !== null) {
                    newVersion.push(versionNames[i])
                }
                if (driftPatterns !== undefined) {
                    newDrift.push(driftPatterns[i])
                }
            }
            if (filteredLength === 1) {
                newValues.push(valArr[startIndex])
                if (versionNames !== undefined && versionNames !== null) {
                    newVersion.push(versionNames[startIndex])
                }
                if (driftPatterns !== undefined) {
                    newDrift.push(driftPatterns[startIndex])
                }
            }
            actChartData.time = filteredArr;
            actChartData.dq_chart_points = newValues;
            actChartData.values = newValues;
            actChartData.drift_patterns = newDrift;
            actChartData.version_name = newVersion;
            _singleThumbnailData.chartData = actChartData;
            updatedThumbnailCharts.push(_singleThumbnailData);
        }
        return updatedThumbnailCharts
    }

    handleDQListViewTimeFilter(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;
        if (this.state.noIntegration === true || this.state.inProgress === true) {
            this.setState({ startDate: startDate, endDate: endDate, timeFilterApplied: true });
            return
        }

        let clonedData = _.cloneDeep(this.state.completeTableData);
        let filteredData = [];
        let dataFilteredValues = [];
        for (let rowData of clonedData) {
            let previousTime = null;
            let thumbnailData = rowData.find(x => x.type === "thumbnailCharts");
            let datasetName = rowData.find(x => x.type === "datasetName");
            let detailedCharts = rowData.find(x => x.type === "action")
            let datasetId = detailedCharts.value;
            let dataSourceId = detailedCharts.dataSourceId;
            let attributeId = detailedCharts.attributeId;

            if (this.state.showpartitions === false) {
                let check = this.props.filterData.parentDatasets.filter((data) =>
                    parseInt(data.value) === parseInt(datasetId)
                ).length;
                if (check === 0) {
                    return;
                }
            }

            let createdTime = moment(thumbnailData.createdTime).startOf("day").toDate();
            let noDataAvailable = moment(startDate).isBefore(createdTime) && moment(endDate).isBefore(createdTime)

            if (noDataAvailable === true) {
                continue;
            }


            const thumbnailVal = thumbnailData.value;

            let issuedAttributes = thumbnailData.issuedAttributes;

            for (let _cData of thumbnailVal) {
                let updatedThumbnailCharts = this.getFilteredData(_cData.data, startDate, endDate);
                if (updatedThumbnailCharts.length === 0 || (updatedThumbnailCharts.length === 1 && updatedThumbnailCharts[0]["name"] === "Recency")) {
                    updatedThumbnailCharts = this.getFilteredData(_cData.data, startDate, endDate, true)
                    if (issuedAttributes !== undefined) {
                        let previousTimeArr = issuedAttributes.filter(x => moment(x.refresh_time).isSameOrBefore(endDate))
                        if (previousTimeArr.length > 0) {
                            previousTime = previousTimeArr[0]["refresh_time"]
                        }

                    }


                }
                _cData.data = updatedThumbnailCharts;
            }

            if (datasetName !== undefined) {
                datasetName["previousTime"] = previousTime
                thumbnailData.noDataAvailable = noDataAvailable;
            }

            let filteredDataset = this.state.selectedDataSet.filter(x => Number(x.value) === Number(datasetId))


            if (filteredDataset.length > 0) {
                if (attributeId !== undefined && this.state.selectedAttributes.length > 0) {
                    let filteredAttributes = this.state.selectedAttributes.filter(x => Number(x.value) === Number(attributeId))
                    if (filteredAttributes.length > 0) {
                        dataFilteredValues.push(rowData);
                    }
                } else {
                    dataFilteredValues.push(rowData);
                }
            } else if (this.state.selectedDataSet.length === 0 && this.state.selectedDataSourceID !== null && Number(dataSourceId) === Number(this.state.selectedDataSourceID)) {
                dataFilteredValues.push(rowData);
            }

            filteredData.push(rowData);
        }

        this.setState({
            startDate: startDate,
            endDate: endDate,
            dataFilteredValues: dataFilteredValues,
            timeFilterApplied: true,
            currListViewTableData: filteredData,
            selectedGroup:this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] : this.state.selectedGroup
        });
    }

    updateFollowAttributes(data){
        this.setState({follow_attributes:data})
    }
    getDQListTableComponents(actionType, headerValue, cell, row, rowIndex) {
        if (headerValue === "Attribute Name") {
            return (
                <AttributeNameComp attributeName={cell}>
                </AttributeNameComp>
            )
        }

        if (headerValue === "Dataset Name") {
            if (cell.value === undefined) {
                return ""
            }
            return (<><b className="table-monitor-label-mw">{cell.value} {cell.previousTime !== undefined && cell.previousTime !== null ? <RcTooltip placement="top"
                tooltipInfo={"Refresh Date"}
                overlay={
                    <span id={`tooltipId`}>
                        {cell.previousTime !== undefined && cell.previousTime !== null ? `Previous Refresh Date: ${cell.previousTime}` : ""}<br />
                    </span>
                }
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                <i className={"d-inline-block px-1 text-danger"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </i>
            </RcTooltip> : ""}</b>

                <p className="text-muted mb-0 mt-2">Latest Refresh Date: </p>
                {cell.lastProfilingTime}<br /></>)
        }
        if (headerValue === "DQ Metrics") {
            if (cell.value.length > 0) {
                return rowIndex < 4 ?
                    (<ThumbnailChartList listData={cell.value}
                        key={"dqErrorsListView"}
                        filterType={selected_Group}
                        noDataAvailable={cell.noDataAvailable}
                    >
                    </ThumbnailChartList>)
                    :
                    (<div id={'load_' + rowIndex + cell.value[0]['key']} data-id={'load_' + rowIndex + cell.value[0]['key']} data-value={JSON.stringify(cell)} className="loading-thumb-graph"><Load /></div>);

            } else {
                return (<div className="tbl-placeholder">
                    <h2>
                        <FontAwesomeIcon icon={faChartArea} />
                    </h2>
                    <h4>{cell.noDataAvailable === true ? "No Charts Found" : "No Errors Found"}</h4>
                </div>)
            }

        } else if (headerValue === "Action") {

            if (cell.level === "attribute") {
                let follow_attributes = this.state.follow_attributes
                let attributId = cell['attributeId'];
                let datasourceId = cell['dataSourceId'];
                let updatedFollowAttri = follow_attributes[datasourceId][attributId]
                cell.follow_status = updatedFollowAttri;
            }


            return (
                <div key={cell['level']} className="d-flex align-items-center w-100 h-100 justify-content-center">

                    {cell.level === "attribute" ?

                        <FollowComponent
                            follow_attribute_details={cell}
                            follow_attributes={this.state.follow_attributes}
                            updateFollowAttributes={this.updateFollowAttributes}
                        />
                        : ""
                    }
                    <div className="show-more monitor-row_show-more"
                        onClick={this.setFullScreen.bind(this, cell)}>
                        <span className="icon">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                        <p>Detailed Charts</p>
                    </div>
                </div>
            );
        }


        return cell;
    }

    getCustomClassForActions() {
        /**
         * This function returns a "function" which will provide the class for <td> entries
         * i.e the cells of the tables
         * */

        return (cell, row, rowIndex, colIndex) => {
            if (colIndex === 5) {
                return "align-middle";
            }

            return "";
        };
    }


    closeFullScreenMode() {


        // let selectedDatasetlist;
        // for(let loop=0;loop<this.props.filterData["parentDatasets"].length;loop++){
        // if(this.props.filterData["parentDatasets"][loop]["value"]!=="*"){
        //  selectedDatasetlist = this.state.inital_dataset.filter((data)=> 
        //  (data.value !=="*" && parseInt(data.value) === parseInt(this.props.filterData["parentDatasets"][loop]["value"])) && data.checked===true)
        //  if(selectedDatasetlist.length > 0){
        //  }
        // }
        // }

        this.setState({
            isInFullScreenMode: false,
            fullScreenClassKey: "",
            fullScreenAttributeData: null,
            lastProfilingTime: null,
            fullScreenData: null
        });
    }

    changeGroup(selectedOption) {
        selected_Group = selectedOption.value;
        this.setState({
            selectedGroup: selectedOption
        });
    }


    reset() {
        this.setState({
            selectedDatasource: null,
            selectedDataSet: [],
            selectedAttributes: [],
            initial: {
                datasource: "",
                dataset: "",
                attribute: "",
            }
        });
    }

//    shouldComponentUpdate() {
//        if (this.state.showLoader === false) {
//            return true
//        }
//        return false
//    }

    render() {
        let tableData = {
            "data": (this.state.dataFilteredValues.length > 0 ? this.state.dataFilteredValues : (this.state.selectedDataSourceID !== null ? this.state.currListViewTableData : this.state.currListViewTableData)),
            "headers": MONITOR_DQP_LIST_VIEW_HEADERS,
            "not_sortable": true
        };
        let dataSourceOption = [];
        for (const [key, value] of Object.entries(this.props.dataModule.integrations)) {
            if (key !== undefined && value !== undefined) {
                dataSourceOption.push({ "label": value, "value": key });
            }
        }

        let ranges = getDefaultDateRangeOptions();
        let dqSchema = yup.object({
            // datasource: yup.string().required("Datasource is a required field"),
            dataset: yup.string().required("Dataset is a required field"),
        });

        let noDataFoundMessage = null;
        let lastProfilingTimeDict = this.props.dataModule.lastProfilingTime;
        let lastProfiledTime = lastProfilingTimeDict[this.state.selectedDataSourceID];
        let containsNull = Object.values(lastProfilingTimeDict).filter(x => x !== null)
        if (containsNull.length === 0 || this.state.inProgress === true) {
            noDataFoundMessage = `Data profiling is in-progress`;
        } else if (this.state.selectedDataSourceID && IS_NONE_CHECK.includes(lastProfiledTime) && tableData.data.length === 0) {
            noDataFoundMessage = `Data profiling is in-progress`;
        }


        // if (this.state.noIntegration === true || this.props.noDataFound) {
        //     noDataFoundMessage = null
        //     if(this.props.inProgress===true){
        //         lastProfilingTimeData = "Data profiling is in-progress"
        //     }
        //     else{
        //         lastProfilingTimeData = "Data profiling is in-progress"

        //     }        
        
        // }
        // let lastProfilingTimeData = null;
        // if(parseInt(this.state.selectedDataSourceID) > 0){
        // lastProfilingTimeData = this.props.dataModule.lastProfilingTime[this.state.selectedDataSourceID]
        // }

        
        let lastProfilingTime = this.state.last_processed_time === null ? "--" : `Your data last profiled at ${this.state.last_processed_time}`;
        
        if (this.state.noIntegration === true) {
            noDataFoundMessage = null
            lastProfilingTime = "No data available"
        }
        else if(this.state.inProgress === true && this.state.noDataFound === false && this.state.noIntegration === false){
            lastProfilingTime = "Data profiling is in-progress"
            noDataFoundMessage = "Data profiling is in-progress"
        }
        // else if(this.state.noDataFound === false && this.state.noIntegration === false ){
        //     noDataFoundMessage = "No data found for selected time range"
        // }

        return (
            <div className="portlet-body pb-0">
                <div className="qd-tab__content-action">
                    <div className="caption">
                        <div className="alert moniker-alert" role="alert">
                            <p className="mb-0">
                            <strong> {lastProfilingTime}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="actions flex-shrink-0">
                        <button onClick={this.toggleFilter} className="btn btn-light">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1"
                                viewBox="0 0 24 24" width="24">
                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                            <span className="dot-symbol"></span>
                            <span>Filters</span>
                        </button>
                    </div>
                    <div className="actions" style={{ display: "none" }}>
                        <div className="action-left">
                            <Select
                                name="datasource"
                                filterOption={({ label }, query) => label.includes(query)}
                                id="chooseDatasourceDqError"
                                options={dataSourceOption}
                                onChange={this.changeDataSource}
                                value={this.state.defaultDatasourceInside}
                                // value={this.state.selectedDatasource}
                                classNamePrefix='form-control'
                                placeholder="Choose Datasource"
                            />
                            <CustomSelect
                                isMulti
                                isClearable={false}
                                name="dataset"
                                filterOption={({ label }, query) => label !== undefined && label.includes(query)}
                                id="chooseDatasetError"
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ Option }}
                                allowSelectAll={true}
                                value={this.state.selectedDataSet}
                                options={this.state.datasets}
                                onChange={this.changeDataset}
                                controlShouldRenderValue={false}
                                classNamePrefix='form-control'
                                placeholder={getPlaceholder("Choose Dataset", this.state.selectedDataSet, this.state.datasets)}
                            />
                        </div>
                        <div className="action-right">
                            <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                startDate={this.state.startDate}
                                onApply={this.handleDQListViewTimeFilter}
                                endDate={this.state.endDate}
                                key={`date_${this.state.keyData}`}
                                ranges={ranges}>
                                <i>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </i>
                                <span
                                    className="d-inline-block">
                                    {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                </span>
                            </DateRangePicker>
                            <Formik
                                enableReinitialize
                                validationSchema={dqSchema}
                                onSubmit={this.handleSubmit}
                                initialValues={this.state.initial}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    setValues,
                                    setFieldValue,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isValid,
                                    setErrors
                                }) => (<><Dropdown className="qd_filter-btn d-inline" alignRight show={this.state.showDropDown}>
                                    <Dropdown.Toggle onClick={() => { this.state.showDropDown === true ? this.setState({ showDropDown: false }) : this.setState({ showDropDown: true }) }} id="dropdown-autoclose-false" className="btn-light">
                                        <Image
                                            src="icon_filter"
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right">
                                        <Form noValidate name="signup"
                                            className="login-form needs-validation"
                                            onSubmit={handleSubmit}>
                                            <div className="qd_filter__body">
                                                <h4 className="filter_title">Select Date</h4>
                                                <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                                    startDate={this.state.startDate}
                                                    onApply={this.handleTime}
                                                    endDate={this.state.endDate}
                                                    key={`date_${this.state.keyData}`}
                                                    ranges={ranges}>
                                                    <i>
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </i>
                                                    <span
                                                        className="ml-2 d-none d-md-inline-block">
                                                        {this.state.startDate.format(DISPLAY_DATE_FORMAT)} - {this.state.endDate.format(DISPLAY_DATE_FORMAT)}
                                                    </span>
                                                </DateRangePicker>


                                                {/* Needs to handle this filter while seleting datasource */}

                                                <Form.Group >
                                                    <Form.Label>Datasource</Form.Label>
                                                    <Select
                                                        name="datasource"
                                                        id="datasourceNewFilter"
                                                        placeholder="Choose Datasource"
                                                        options={dataSourceOption}
                                                        isInvalid={errors.datasource && touched.datasource}
                                                        onChange={selectedOption => {
                                                            handleChange("datasource")(selectedOption.value.toString());
                                                            this.changeDataSourceAdvanced(selectedOption);
                                                            setFieldValue("dataset", "")
                                                        }}
                                                        classNamePrefix='select-control'
                                                        value={this.state.defaultDatasourceInside}
                                                        isDisabled={true}

                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="datasource" />
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label>Dataset</Form.Label>
                                                    <CustomSelect
                                                        isMulti
                                                        isClearable={false}
                                                        name="dataset"
                                                        id="datasetNewFilter"
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        isInvalid={errors.dataset && touched.dataset}
                                                        components={{ Option }}
                                                        classNamePrefix='select-control'
                                                        options={this.state.datasets}
                                                        onChange={selectedOption => {
                                                            if (selectedOption !== null && selectedOption.length !== 0) {
                                                                handleChange("dataset")(selectedOption[0].value.toString());
                                                            } else {
                                                                handleChange("dataset")("");
                                                            }
                                                            this.handleChangeDataset(selectedOption);
                                                        }}
                                                        allowSelectAll={true}
                                                        placeholder={getPlaceholder("Choose Dataset", this.state.selectedDataSet, this.state.datasets)}
                                                        value={this.state.selectedDataSet}
                                                        controlShouldRenderValue={false}
                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="dataset" />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Attribute</Form.Label>
                                                    <CustomSelect
                                                        isMulti
                                                        isClearable={false}
                                                        name="attribute"
                                                        id="attributeNewFilter"
                                                        placeholder={getPlaceholder("Choose Attribute", this.state.selectedAttributes, this.state.originalAttributeOptions)}
                                                        options={this.state.attributeOptions}
                                                        originalAttributeOptions={this.state.originalAttributeOptions}
                                                        //isInvalid={errors.attribute && touched.attribute}
                                                        onChange={selectedOption => {
                                                            if (selectedOption !== null && selectedOption.length !== 0) {
                                                                handleChange("attribute")(selectedOption[0].value.toString());
                                                            } else {
                                                                handleChange("attribute")("");
                                                            }
                                                            this.changeAttribute(selectedOption);
                                                        }}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        value={this.state.selectedAttributes}
                                                        allowSelectAll={true}
                                                        controlShouldRenderValue={false}
                                                        classNamePrefix='select-control'
                                                    />
                                                    {/*<ErrorMessage component="div" className="error-text" name="attribute" />*/}
                                                </Form.Group>

                                                {/* <Form.Group >
                                                    <Form.Label>Attribute Tree</Form.Label>
                                                    <AttributeTreeSelect
                                                         attributeTreeOptions={this.state.attributeOptions}
                                                         onChangeAttributeTree={this.onTreeChange}
                                                    />
      
                                                    <ErrorMessage component="div" className="error-text" name="datasource" />
                                                </Form.Group> */}


                                                {/*<Form.Group>
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <Form.Control
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    checked={this.state.showAttributes}
                                                                    onChange={this.handleCheckClick}
                                                                    name="showAttributes"
                                                                    id="dqpPreviewAttributes" />
                                                                <Form.Label className="custom-control-label" htmlFor="dqpPreviewAttributes">Show Attributes</Form.Label>
                                                            </div>
                                                </Form.Group>*/}
                                            </div>
                                            <div className="d-flex justify-content-between border-top p-3 my-1">
                                                <button type="button" className="btn btn-link" onClick={() => {
                                                    this.reset();
                                                    setTimeout(() => {
                                                        setValues(this.state.initial);
                                                        setTimeout(() => {
                                                            setErrors({})
                                                        }, 1);
                                                    }, 500);
                                                }}>Reset</button>
                                                <div>
                                                    <Button className="btn-outline btn-grey btn-circle mr-2" onClick={() => {
                                                        setTimeout(() => {
                                                            setErrors({})
                                                        }, 1); this.setState({ showDropDown: false })
                                                    }}>Cancel</Button>
                                                    <Button type="submit" className="btn-primary btn-circle"><FontAwesomeIcon icon={faCheck} /> Apply</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </Dropdown.Menu>
                                </Dropdown>

                                </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                {this.state.selectedDataSourceID !== "" && this.props.filterData !== undefined && this.state.selectedDataSourceID !== null && this.state.isFilterMode === true && this.state.isInFullScreenMode === false && this.state.noIntegration === false?
                    <CustomTreeFilter
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        seletedDatasourceId={this.state.selectedDataSourceID}
                        seletedDatasourceName={this.state.selectedDataSourceName}
                        data={this.props.filterData}
                        clearAllFilterData={this.clearAllFilterData}
                        clearAllFilter={this.state.clearAllFilter}
                        loadDataSourceRender={this.loadDataSourceRender}
                        applyDataset={this.applyDataset}
                        applyDateFilter={this.applyDateFilter}
                        showAttributes={true}
                        showDatasets={true}
                        fullscreenviewselect={this.state.isInFullScreenMode}
                    /> : ""}
                {this.state.isInFullScreenMode ?
                    <DqpErrorFullScreen fullScreenClassVal={this.state.fullScreenClassKey}
                        title={"Data Quality Performance - Dashboard"}
                        isDatasetLevel={this.state.isDatasetLevel}
                        mappedDatasetInfo={this.state.mappedDatasetInfo}
                        datasetName={this.state.datasetName}
                        metricsList={this.props.metricsList}
                        defaultDatasetInside={[this.state.defaultDatasetInside]}
                        filteredTimeliness={this.state.filteredTimeliness}
                        timelinessData={this.props.timelinessData}
                        datasourceName={this.state.datasourceName}
                        key={this.state.keyData}
                        lastProfilingTime={this.state.lastProfilingTime}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        defaultAttributeInside={this.state.defaultAttributeInside}
                        dataSourceOption={dataSourceOption}
                        defaultDatasourceInside={this.state.defaultDatasourceInside}
                        timeFilterApplied={this.state.timeFilterApplied}
                        fullScreenData={this.state.fullScreenData}
                        allData={this.state.monitorDQListView}
                        fullScreenAttributeData={this.state.fullScreenAttributeData}
                        variant={"listViewFullScreenDqError"}
                        closeFullScreen={this.closeFullScreenMode}
                        dataModule={this.props.dataModule}
                        // new add
                        seletedDatasourceId={this.state.selectedDataSourceID}
                        seletedDatasourceName={this.state.selectedDataSourceName}
                        filterData={this.props.filterData}
                        clearAllFilterData={this.clearAllFilterData}
                        clearAllFilter={this.state.clearAllFilter}
                        loadDataSourceRender={this.loadDataSourceRender}
                        showAttributes={true}
                        showDatasets={true}
                        changeGroup={this.changeGroup}
                        selectedGroup={this.state.selectedGroup === null ? LIST_VIEW_OPTIONS[0] : this.state.selectedGroup}
                        metrics_data={this.getMetricsOption([this.state.defaultDatasetInside], 1, 1)}
                        fullscreenview={this.state.isInFullScreenMode}
                        type="listView"
                    >
                    </DqpErrorFullScreen>
                    :
                    <BasicPortlet
                        key={`monitorDQListView_${tableData.data.length}`}
                        className="pb-0"
                        id="monitorDQListView"
                        title="Data Quality Performance"
                        content={
                            this.state.showLoader === true
                                //  && this.props.firstDatasetUp === false 
                                ?
                                <Load />
                                :
                                <QualdoDataTable
                                    id="monitorDQListView"
                                    customGetActionComponent={this.getDQListTableComponents}
                                    data={tableData}
                                    changeGroup={this.changeGroup}
                                    selectedGroup={this.state.selectedGroup}
                                    message={noDataFoundMessage}
                                    paginationSize={50}
                                    component_name="dqErrorListViewTable"
                                    getCustomClassForColumns={this.getCustomClassForActions()}
                                    follow_attribute_details={this.state.follow_attributes}
                                    showpartitions={this.state.showpartitions}
                                    changePartitions={this.changePartitions}
                                />
                        }
                    />
                }
            </div>);

    }
}
const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { addFollowAttributes, addMonitorDQListViewData })(DqpListViewContent);
