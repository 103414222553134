import React from 'react';
import Image from "../components/image";
import Card from 'react-bootstrap/Card';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Account from './components/account';
import PlanAndUsage from './components/plan_and_usage';
import Notifications from "../notifications/index";
import Preferences from './components/preferences';
import TeamMate from './components/teamMate';
import Security from './components/security';
import Billing from './components/billing';
import ChangePassword from './components/changePassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faKey,
    faPaperPlane,
    // faHistory,
    faUsersCog,
    faReceipt,
    faChalkboardTeacher
} from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import { getApi, increaseDataLimit } from "../utils/User_handling";
import ErrorHandler from '../components/error_500';
import { CopyRightsFooter } from '../components/copyrights';
import { postUIlogs } from "../utils/event_handling";
import {
    IS_NONE_CHECK, PLAN_CARD_CLASS_MAPS,
    PLAN_ICON_MAPS, QualdoPlanVersions,CYCLE,
    CLOUD, LOCAL_STORE_KEY
} from "../utils/constant";
import Toast from "react-bootstrap/Toast";
import { toastObj, getFeatureAccess, decryptForLocalUsage } from "../utils/common_utils";
import { Col, Row } from 'react-bootstrap';
import $ from 'jquery';
import {logoutPageRedirect} from "../utils/common_utils";

const goToChoosePlan = () => {
    window.location.href = "/payment";
}

let EDITION_LOGO = process.env.REACT_APP_CUSTOM_EDITION_LOGO
class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.increaseDataLimit = increaseDataLimit.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeWarningMessage = this.closeWarningMessage.bind(this);
        this.check_access_billing = this.check_access_billing.bind(this);

        let navKey = "account";
        if (this.props.route !== undefined && this.props.route.navKey !== null && this.props.route.navKey !== undefined) {
            navKey = this.props.route.navKey;
        } else if (this.props.navKey !== null && this.props.navKey !== undefined) {
            navKey = this.props.navKey;
        }
        this.state = {
            toastStatus: false,
            reRenderNavComponent: false,
            errorOccurred: false,
            navKey: navKey,
            featureAccess: getFeatureAccess(),
            routeSelection: navKey,
            sideBarSelected: false,
            first_name: null,
            last_name: null,
            org_name: null,
            user_profile_image: null,
            email: null,
            phoneNo: null,
            edition_details: {},
            cycle_end_data:null,
            next_billing_date: null,
            change_request: {},
            planDetails: {
                size: null,
                showInformationBanner: false,
                mlModelCount: null,
                planName: null,
                planNameKey: null,
                planBenefits: [],
                monthlyLimit: [],
                cost: null,
                maxDataLimit: null,
                maxMlModelLimit: null,
                comments: [],
                modifiedTime: null,
                requiresDataLimitUpgrade: false,
                CycleEndData : null,
                paymentStatus:null,
            }
        }
        this.getData();
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }


    static getDerivedStateFromProps(props, state) {
        if (IS_NONE_CHECK.includes(props) || IS_NONE_CHECK.includes(state)) {
            return null;
        }

        let navKey = "account";
        if (props.route !== undefined && props.route.navKey !== null && props.route.navKey !== undefined) {
            navKey = props.route.navKey;
        } else if (props.navKey !== null && props.navKey !== undefined) {
            navKey = props.navKey;
        }

        // Props and state route will be compared to identify is selection is made/changed from Right-Top
        // Profile drop down menu
        if (navKey !== state.routeSelection) {
            return {
                navKey: navKey,
                routeSelection: navKey
            };
        }

        return null;
    }



    getData() {
        this.getApi("user_details");
        this.getApi("edition_details");
        this.getApi("usage");
        this.getApi("planChangeRequest");
    }

    closeWarningMessage() {
        // This function is called from increaseDataLimit() in User_Handling.js
        localStorage.removeItem("warning_message");

        const reRenderNavComponent = this.state.reRenderNavComponent;

        this.setState({
            reRenderNavComponent: !reRenderNavComponent,
        });
    }

    navSelect(selectedKey) {
        // Handles left side bar navigation selections
        this.setState({ navKey: selectedKey });
    }

    closeToast() {
        this.setState({ toastStatus: false });
    }

    showToast(status, msg, link) {
        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastLink: link,
            toastIcon: toastObj[status]["icon"],
            toastHeading: toastObj[status]["heading"]
        });

        if (link.trim().length > 0) {
            setTimeout(function () {
                window.location.href = link;
            }, 3000);
        }

    }

    handleSidenav = () => {
        $('#qd-aside').addClass("--is-visible");
        $('body').addClass("overflow-hidden");
    }
    handleSidenavClose = () => {
        $('#qd-aside').removeClass("--is-visible");
        $('body').removeClass("overflow-hidden");
    }

    renderNavContent() {
        let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
        let isPlanExpired = decrypt_data['isPlanExpired']
        let isInBufferPeriod = decrypt_data['isInBufferPeriod']
        // let isPlanExpired = localStorage.getItem("isPlanExpired")
        // let isInBufferPeriod = localStorage.getItem("isInBufferPeriod")
        
        if (isPlanExpired === true && this.state.navKey !== "billing" && isInBufferPeriod === false) {
            return <PlanAndUsage navKey={this.state.navKey}
                showToast={this.showToast}
                change_request={this.state.change_request}
                planDetails={this.state.planDetails}
                increaseDataLimit={this.increaseDataLimit}
            />;
        }
        let hideModelInfo = true;

        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const modelInfo = this.state.featureAccess["models"];
            hideModelInfo = modelInfo === false;
        }
        switch (this.state.navKey) {
            case "account":
                return <Account navKey={this.state.navKey} />;
            case "preferences":
                return <Preferences navKey={this.state.navKey} />;
            case "teammate":
                return <TeamMate navKey={this.state.navKey} />;
            case "usage":
                return <PlanAndUsage navKey={this.state.navKey}
                    showToast={this.showToast}
                    hideModelInfo={hideModelInfo}
                    change_request={this.state.change_request}
                    increaseDataLimit={this.increaseDataLimit}
                    planDetails={this.state.planDetails} />;
            case "security":
                return <Security navKey={this.state.navKey} />;
            case "billing":
                return <Billing navKey={this.state.navKey}
                    showToast={this.showToast}
                    closeToast={this.closeToast}
                />;
            case "changePassword":
                return <ChangePassword navKey={this.state.navKey} />;
            case "notification":
                return <Notifications navKey={this.state.navKey} />;
            default:
                break;
        }
    }

    check_access_billing() {
        let permissions = localStorage.getItem("permissions");
        let permissionsList = [];
        if (permissions !== undefined && permissions !== null) {
            permissionsList = permissions;
        }

        return (
            <>
                {
                    (this.state.featureAccess["usage_payment"] === false &&
                        permissionsList.includes('BillingAccess')) ?
                        <>
                            {/* <div className={this.state.navKey === "usage" ? "qd-sn-item active" : "qd-sn-item"}>
                                <Nav.Link className="qd-sn-nav-link" eventKey="usage">
                                    <span className="nav-icon"><FontAwesomeIcon icon={faPaperPlane} /></span>
                                    <span className="nav-text">Plan & Usage</span>
                                </Nav.Link>
                            </div> */}
                            <div className={this.state.navKey === "teammate" ? "qd-sn-item active" : "qd-sn-item"}>
                                <Nav.Link className="qd-sn-nav-link" eventKey="teammate">
                                    <span className="nav-icon"><FontAwesomeIcon icon={faUsersCog} /></span>
                                    <span className="nav-text">Manage Teammates</span>
                                </Nav.Link>
                            </div>
                        </>
                        : permissionsList.includes('BillingAccess') ?
                            <>
                                <div className={this.state.navKey === "usage" ? "qd-sn-item active" : "qd-sn-item"}>
                                    <Nav.Link className="qd-sn-nav-link" eventKey="usage">
                                        <span className="nav-icon"><FontAwesomeIcon icon={faPaperPlane} /></span>
                                        <span className="nav-text">Plan & Usage</span>
                                    </Nav.Link>
                                </div>
                                <div className={this.state.navKey === "billing" ? "qd-sn-item active" : "qd-sn-item"}>
                                    <Nav.Link className="qd-sn-nav-link" eventKey="billing">
                                        <span className="nav-icon"><FontAwesomeIcon icon={faReceipt} /></span>
                                        <span className="nav-text"> Billing & Payment</span>
                                    </Nav.Link>
                                </div>
                                <div className={this.state.navKey === "teammate" ? "qd-sn-item active" : "qd-sn-item"}>
                                    <Nav.Link className="qd-sn-nav-link" eventKey="teammate">
                                        <span className="nav-icon"><FontAwesomeIcon icon={faUsersCog} /></span>
                                        <span className="nav-text">Manage Teammates</span>
                                    </Nav.Link>
                                </div>
                            </>
                            : ''
                }
            </>
        );
    }

    render() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }

        let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
        let isPlanExpired = decrypt_data['isPlanExpired']
        let permissions = localStorage.getItem("permissions");
        let permissionsList = [];
        if (permissions !== undefined && permissions !== null) {
            permissionsList = permissions;
        }
        const planDetails = this.state.planDetails;

        const editionDetails = this.state.edition_details;
        const loadingCompleted = Object.keys(editionDetails).length > 0;

        let planTitle = '';
        if (loadingCompleted) {
            planTitle = editionDetails.details.title;
        }


        const currentPlanName = editionDetails["plan_name"];
        let currentPlanCycle = editionDetails["plan_cycle"];
        currentPlanCycle = CYCLE[currentPlanCycle]
        return (
            this.state.errorOccurred ?
                <ErrorHandler />
                :
                <>
                    <div id="settingsPageToastMsg">
                        <Toast className={this.state.toastClass}
                            onClose={() => this.closeToast()}
                            show={this.state.toastStatus}
                            delay={3000} autohide>

                            <div className="d-flex align-items-center">
                                {this.state.toastStatus ?
                                    <i className="toast-icon">
                                        <FontAwesomeIcon icon={this.state.toastIcon} />
                                    </i>
                                    :
                                    ''
                                }

                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    <p>{this.state.toastMsg}</p>
                                </Toast.Body>
                                <button type="button"
                                    onClick={this.closeToast}
                                    className="close"
                                    data-dismiss="toast"
                                    aria-label="Close" />
                            </div>
                        </Toast>
                    </div>

                    <NavigationHeader {...this.props} key={this.state.reRenderNavComponent} />
                    <main>
                        <BreadCrumb icon='userCog' title='Settings' sidenav='1' showSidenav={this.handleSidenav} />
                        <div className="qd-container">
                            <div className="qd-body no-tabs qd-grid qd-settings shadow-none bg-transparent">
                                <div id="qd-aside" className="qd-aside qd-aside-settings shadow-sm qd-grid-item">
                                        <div className="profile-info d-flex align-items-center">
                                            <div className="profile-avatar-block align-self-start">
                                                <Image width="100" height="100" src="userProfile" />
                                            </div>
                                            <div className="w-100">
                                                <h5 className="avatar-name">{this.state.first_name}</h5>
                                                <div className="text-muted">{this.state.org_name}</div>
                                                {
                                                    // This section can be completely removed
                                                    // once this functionality is added in other places such as
                                                    // Plan&Usage or Billing section. (Refer issue 1042- Points 18 & 24)
                                                    (permissionsList.includes('BillingAccess') &&
                                                        planDetails.planNameKey !== null &&
                                                        planDetails.planNameKey !== QualdoPlanVersions.FREE &&
                                                        false
                                                    ) ?
                                                        <button type="button"
                                                            onClick={goToChoosePlan}
                                                            className="btn btn-block btn-outline-primary btn-circle mt-3">
                                                            Pay Now
                                                        </button> : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="py-4">
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <span className="mr-2">Email:</span>
                                                <a href="#link" className="text-muted text-hover-primary">
                                                    {this.state.email}
                                                </a>
                                            </div>
                                            {
                                                this.state.phoneNo !== null ?
                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                        <span className="mr-2">Phone:</span>
                                                        <span className="text-muted">
                                                            {this.state.phoneNo}
                                                        </span>
                                                    </div>
                                                    : ''
                                            }
                                        </div>


                                        <div className="qd-settings-nav">
                                            {(loadingCompleted === true && this.state.featureAccess["usage_payment"] === false) ?

                                                <Card className="qd-edition-card">
                                                    <Card.Body>
                                                        <Row className="no-gutters">
                                                            <Col>
                                                                <div className="user-plan__details">
                                                                    <Image src={EDITION_LOGO} alt="EDITION Logo" />
                                                                    {/* <Image src="aws_brandMark" alt="AWS Logo" /> */}
                                                                    {/* <Image src="azure_brandMark" alt="Azure Logo" /> */}
                                                                    <div className="user-plan__stacked">
                                                                        <h6 className="user-plan__title">{editionDetails["edition"]} Edition</h6>
                                                                        <span className="user-plan__name">{CLOUD[EDITION_LOGO]}</span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col className="user-cycle__info">
                                                                <h6 className="user-cycle__title">{currentPlanCycle} Cycle</h6>
                                                                <span className="user-cycle__name"></span>
                                                            </Col>
                                                        </Row>
                                                        <div className="card-divider"></div>
                                                        <div className='col text-right'>
                                                            {(this.state.next_billing_date === undefined || this.state.next_billing_date === null) 
                                                                ?
                                                                    '' 
                                                                :
                                                                    <>
                                                                        <p className="billing-text">Next billing date</p>
                                                                            {isPlanExpired === true && (this.state.cycle_end_data !== undefined &&
                                                                                this.state.cycle_end_data !== null)
                                                                            ?
                                                                                <h5>
                                                                                    {this.state.cycle_end_data}
                                                                                </h5>
                                                                            :
                                                                                <h5>
                                                                                    {this.state.next_billing_date.split(',')[1].slice(0, 12)}
                                                                                </h5>
                                                                            }
                                                                    </>
                                                                }
                                                            </div>
                                                    </Card.Body>
                                                </Card>

                                                :
                                                loadingCompleted === true ?

                                                    <Card className={PLAN_CARD_CLASS_MAPS[currentPlanName]}>
                                                        <Card.Header>{editionDetails["edition"]}</Card.Header>
                                                        <Card.Body>
                                                            <div className="row no-gutters">
                                                                <div className="col">
                                                                    <div className="user__plan">
                                                                        <Image src={PLAN_ICON_MAPS[currentPlanName]}
                                                                            alt="ML Monitoring tools" />
                                                                        <div className="user__plan-info"
                                                                            key={`plan_title_${loadingCompleted}`}
                                                                        >
                                                                            <p>Your Plan</p>
                                                                            <h4>{planTitle}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               <div className='col text-right'>
                                                                {(this.state.next_billing_date === undefined || this.state.next_billing_date === null) 
                                                                    ?
                                                                        '' 
                                                                    :
                                                                        <>
                                                                            <p className="billing-text">Next billing date</p>
                                                                                {isPlanExpired === true && (this.state.cycle_end_data !== undefined &&
                                                                                    this.state.cycle_end_data !== null)
                                                                                ?
                                                                                    <h5>
                                                                                        {this.state.cycle_end_data}
                                                                                    </h5>
                                                                                :
                                                                                    <h5>
                                                                                        {this.state.next_billing_date.split(',')[1].slice(0, 12)}
                                                                                    </h5>
                                                                                }
                                                                        </>
                                                                    }
                                                               </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>

                                                    :

                                                    ""

                                            }
                                            <Nav onSelect={(selectedKey) => this.navSelect(selectedKey)} onClick={this.handleSidenavClose}>
                                                <div className={this.state.navKey === "account" ? "qd-sn-item active" : "qd-sn-item"}>
                                                    <Nav.Link className="qd-sn-nav-link" eventKey="account">
                                                        <span className="nav-icon"><FontAwesomeIcon icon={faUser} /></span>
                                                        <span className="nav-text">Profile Settings</span>
                                                    </Nav.Link>
                                                </div>
                                                {process.env.REACT_APP_CUSTOM_NODE_ENV_LOGIN !== "msi_login" || process.env.REACT_APP_CLOUD === "AWS"
                                                ?
                                                <div className={this.state.navKey === "changePassword" ? "qd-sn-item active" : "qd-sn-item"}>
                                                    <Nav.Link className="qd-sn-nav-link" eventKey="changePassword">
                                                        <span className="nav-icon"><FontAwesomeIcon icon={faKey} /></span>
                                                        <span className="nav-text">Change Password</span>
                                                    </Nav.Link>
                                                </div>
                                                :
                                                ""
                                                }
                                                <div className={this.state.navKey === "preferences" ? "qd-sn-item active" : "qd-sn-item"}>
                                                    <Nav.Link className="qd-sn-nav-link" eventKey="preferences">
                                                        <span className="nav-icon"><FontAwesomeIcon icon={faChalkboardTeacher} /></span>
                                                        <span className="nav-text">Preferences</span>
                                                    </Nav.Link>
                                                </div>

                                                {this.check_access_billing()}

                                            </Nav>
                                        </div>
                                    </div>
                                <div className="qd-aside-overlay" />
                                <button className="close remove-aside" type="button" onClick={this.handleSidenavClose} />
                                <div id="qd-section__section"
                                    key={this.state.navKey}
                                    className="qd-section qd-grid-item ml-4">
                                    {this.renderNavContent()}
                                </div>
                            </div>
                            
                        </div>
                    </main>
                    <CopyRightsFooter />
                </>
        );
    }
}

export default Settings;