import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DataQualityMetricsForm from "../metrics/components/DataQualityMetricsForm";
import ModelQualityMetricsForm from "../metrics/components/ModelQualityMetricsForm";
import Select from "react-select";
import Integration from "../configure/components/integration";
import AddModel from "../configure/mlmodel/addModel";
import EmailForm from '../consume/components/EmailForm';
import WebhookForm from '../consume/components/WebhookForm';
import DatabricksForm from '../consume/components/DatabricksForm';
import AwsLambdaForm from '../consume/components/awsLambdaForm';
import GcpCloudFunctions from '../consume/components/gcpCloudFunctions';
import AirflowForm from '../consume/components/AirflowForm';
import AWSSagemakerForm from '../consume/components/AWSSagemakerForm';
import KubeflowForm from '../consume/components/KubeflowForm';
import SlackForm from '../consume/components/slackForm';
import PagerdutyForm from '../consume/components/PagerdutyForm';
import AlertForm from '../consume/components/alertForm';
import {getMetricTypeFromDefinition, isTemplateMetric} from '../utils/common_utils'
import CustomDefaultMetrics from "./customDefaultMetrics";
import MSTeamsForm from '../consume/components/msTeamsForm';

export function get_edit_component(props) {
    let details = props.details;
    switch (details.component_name) {
        case 'datasource':
            if (details.integration_details === undefined) {
                return (<div/>);
            }

            let env_value = null;
            if (details.environment !== null) {
                 env_value = details.environment.filter(function (env) {
                    return details.integration_details.env_id === env.value &&
                        details.integration_details.env_name === env.label;
            });
            }


            let env_id = null;
            if (env_value !== null) {
                env_id = details.integration_details.env_id;
            }

            const handleChange = (selectedOption) => {
                this.props.setEnvData(selectedOption["value"])
            }


            return (
                <>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Choose Environment</label>
                            <Select
                                name="env" onChange={handleChange}
                                filterOption={({label}, query) => label.includes(query)}
                                defaultValue={env_value}
                                id="choose_environment_datasource_edit"
                                options={details.environment}
                                placeholder="Choose Environment"
                                classNamePrefix='select-control'
                            />
                        </div>
                    </div>
                    <Integration
                        src_page="edit_integration"
                        env_id={env_id}
                        showToast={details.showToast}
                        setEnvData={details.setEnvData}
                        closeModal={props.handleClose}
                        ex_integration_details={details.integration_details}
                    />
                </>
            );
        case 'metrics': case 'default_metrics':
            const exMetricDetails = details.metrics_data;
            const metricDefinition = exMetricDetails.metrics_definition;
            let metricType = getMetricTypeFromDefinition(metricDefinition);
            let isTemplate = isTemplateMetric(metricDefinition);
            let editComponent;
            let modelOptions = details.modelOptions;
            if (isTemplate) {
                editComponent = (<CustomDefaultMetrics closeModal={props.handleClose}
                                                       metricName={exMetricDetails.metrics_name}
                                                       metricId={exMetricDetails.metrics_id}
                                                       exMetricDetails={exMetricDetails}
                                                       details={details.datasetInfo}
                                                       src_page="edit_metrics"
                                                       showToast={details.showToast}/>);
            } else if(modelOptions !== undefined) {
                   editComponent = (<ModelQualityMetricsForm
                    src_page="edit_metrics"
                    modelOptions={modelOptions}
                    showToast={details.showToast}
                    closeModal={props.handleClose}
                    ex_metric_details={exMetricDetails}
                    mas_data={details.additional_details}
                    details={details.datasetInfo}
                    type={metricType}
                />)
            }
            else {
                editComponent = (<DataQualityMetricsForm
                    src_page="edit_metrics"
                    showToast={details.showToast}
                    closeModal={props.handleClose}
                    ex_metric_details={exMetricDetails}
                    mas_data={details.additional_details}
                    details={details.datasetInfo}
                    integrationOption={details.integrationOption}
                    type={metricType}
                />)
            }

            return (
                <>
                    <div className="form-row">
                    </div>
                    {editComponent}
                </>
            );
        case 'alert':
            const exAlert = details.alert_item;
            return (
                        <>
                            <div className="form-row">
                            </div>
                            <AlertForm
                                src_page="edit_alert"
                                showToast={details.showToast}
                                modelOptions={details.modelOptions}
                                integrationOption={details.integrationOption}
                                dataset={this.props.dataset}
                                closeModal={props.handleClose}
                                exDetails={exAlert}
                                details={details.metricsData}
                                tab={details.tab}
                            />
                        </>
                    );

        case 'ml_model':
            if (details.mlData === undefined) {
                return (<div />);
            }
            return (
                <>
                    <div className="form-row">
                    </div>
                    <AddModel 
                        srcPage="edit_ml_model"
                        showToast={details.showToast}
                        closeModal={props.handleClose}
                        integrationOption={details.integrationOption}
                        datasets={details.datasets}
                        attributes={details.attributes}
                        exMLData={details.mlData}
                    />
                </>
            );
        case 'consume':
            const exConsume = details.consume_item;
            const consumeType = exConsume.consume_group_type;
            switch(consumeType) {
                case "email":
                      return (
                            <>
                                <div className="form-row">
                                </div>
                                <EmailForm
                                    src_page="edit_consume"
                                    showToast={details.showToast}
                                    closeModal={props.handleClose}
                                    exConsumeDetails={details}
                                    alerts={details.alerts}
                                />
                            </>
                      );
                case "slack":
                      return (
                            <>
                                <div className="form-row">
                                </div>
                                <SlackForm
                                    src_page="edit_consume"
                                    showToast={details.showToast}
                                    closeModal={props.handleClose}
                                    exConsumeDetails={details}
                                    alerts={details.alerts}
                                />
                            </>
                      );
                case "pagerduty":
                      return (
                            <>
                                <div className="form-row">
                                </div>
                                <PagerdutyForm
                                    src_page="edit_consume"
                                    showToast={details.showToast}
                                    closeModal={props.handleClose}
                                    exConsumeDetails={details}
                                    alerts={details.alerts}
                                />
                            </>
                      );
                case "MicrosoftTeams":
                      return (
                            <>
                                <div className="form-row">
                                </div>
                                <MSTeamsForm
                                    src_page="edit_consume"
                                    showToast={details.showToast}
                                    closeModal={props.handleClose}
                                    exConsumeDetails={details}
                                    alerts={details.alerts}
                                />
                            </>
                      );
                default:
                    return (
                        <>
                        </>
                    );
            }

        case 'consume_pipeline':
            const ex_consume_web_hook = details.consume_item;
            const trigger_type = ex_consume_web_hook.pipeline_trigger_type;
            switch (trigger_type) {
                case "webhook":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <WebhookForm
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                case "databricks":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <DatabricksForm
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                case "lambda":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <AwsLambdaForm
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                case "cloudfunction":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <GcpCloudFunctions
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                case "airflow":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <AirflowForm
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                case "sagemaker":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <AWSSagemakerForm
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                case "kubeflow":
                    return (
                        <>
                            <div className="form-row">
                            </div>
                            <KubeflowForm
                                src_page="edit_pipeline"
                                showToast={details.showToast}
                                closeModal={props.handleClose}
                                ex_details={ex_consume_web_hook}
                                alerts = {details.alerts}
                            />
                        </>
                    );
                default:
                    return (
                        <>
                        </>
                    );
            }
        default:
            return (
                <div/>
            );
    }
}
