import React from 'react';
import Toast from 'react-bootstrap/Toast';
import NavigationHeader from '../../components/navigationHeader';
import BreadCrumb from '../../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Relationship from './relationship';
import Completeness from './completeness';
import Uniqueness from './uniqueness';
import Timeliness from './timeliness';
import Accuracy from './accuracy';
import Conformity from './conformity';
import Attribute from './attribute';
import Standardisation from './standardisation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorHandler from '../../components/error_500';
import { CopyRightsFooter } from '../../components/copyrights';
import * as d3 from 'd3';
import { postUIlogs, get_api_call_cnt, get_api_call_complete_cnt } from "../../utils/event_handling";
import Image from '../../components/image';
import { postMixpanel } from "../../mixpanel";
import { renderskloader } from "./dq_render_utils";
import { connect } from "react-redux";
import {
    // preFetchRelationshipData,
    preFetchMetaData,
    // preFetchUserNotifications, 
    prefetchDatasetDetails, prefetchRecencyDetails, prefetchRecentHistory,
    prefetchModelABData, prefetchDataSize, preFetchMetadataDetails
} from "../../utils/dataFetch";

// import {
//     // preFetchMonitorPreview,
//     // preFetchMonitorInfo,
//     // preFetchMonitorModelInfo,
//     // preFetchModelDetails,
//     // preFetchMonitorListViewData,
//     // preFetchMetrics,
//     // preFetchFollowAttributes,
//     // fromMonitorData
// } from "../../utils/monitorDataFetch";

import {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setEndDate,
    setStartDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setAttribute,
    setDataSet,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    addModelPerformance,
    addModelDetails,
    // addFollowAttributes,
    addMonitorDQListViewData,
    addMetrics,
    // addUserNotificationToState,
    addDatasetVersions,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyDetails,
    addRecentHistory,
    addDataSize,
    addRecencyPreview,
    prefetchMonitorState,
    noIntegration,
    storeMetadataDetails,
    mappedDatasetInfo,
    // listViewTableData,
    // setDataSource, 
    // setDataSet, 
    // setAttribute, 
    setChipData,
    setFilteredValues,
    setTimeFilter,
    // setStartDate, 
    // setEndDate,
    addMLModelMapping,
    setMLModel,
    setModelABDataToList
} from "../../redux/actions";
import store from "../../redux/store";
import {
    extractDatasetInfoForMonitorListView,
} from "../../utils/common_utils";
import {formData} from "../../utils/dataFetch";
import { getMetaDatas,getDatasForTab} from "../../utils/dataProfileEventHandling"
import {logoutPageRedirect} from "../../utils/common_utils";

class DataProfile extends React.Component {

    constructor(props) {
        super(props);

        this.pre_load = true;
        this.changeTab = this.changeTab.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.setLoader = this.setLoader.bind(this);
        this.setAttributeLoader = this.setAttributeLoader.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.getMetaDatas = getMetaDatas.bind(this);
        this.getDatasForTab = getDatasForTab.bind(this);
        this.loadDataSourceRender = this.loadDataSourceRender.bind(this);
        this.setAttributeTab = this.setAttributeTab.bind(this);
        this.handleFilteredData = this.handleFilteredData.bind(this);
        this.handleDateFiltered = this.handleDateFiltered.bind(this);

        this.state = {
            errorOccurred: false,
            toastStatus: false,
            closeLastProfiledTime: false,
            activeTabKey: "relationship",
            showSpinner: false,
            showAttributeSpinner: false,
            isConsistencyEnabled: null,

            chartTitle: "Relationships & Errors within a Datasource",
            gridTitle: "Data quality metric values for selected datasets / attributes",
            showLoader: true,
            environmentOptions: [],
            datasourceOptions: [],
            datasetsOptions: [],
            attributesOptions: [],
            childDatasets: [],
            parentDatasets: [],
            filterData:[],
            existingList:[],
            seletedDatasetID:"",
            seletedAttribute:"",
            filteredValues:[],
            
            filterStartDate:null,
            filterEndDate:null,
            filteredDatas:null,
            loadparams:null,
            
            noDataFound:null,
            inProgress:null,
        }
    }

    formData() {
        if (this.props.dataModule.metaData !== undefined) {
            const metaDataMapping = this.props.dataModule.metaData;
            const mappedDatasetInfos = extractDatasetInfoForMonitorListView(metaDataMapping);
            formData(this.props.mappedDatasetInfo, mappedDatasetInfos, store)
            this.pre_load = false;

        }
    }

    handleFilteredData(data){
        this.setState({filteredValues:data})
    }

    /**Function used to Persist filtered values while switching over tabs */
    // Below values needs to be Persist
    handleDateFiltered(loadparams, filteredDatas){
        this.setState({
                        filterStartDate:loadparams['start_date'],
                        filterEndDate:loadparams['end_date'],
                        filteredDatas:filteredDatas,
                        loadparams:loadparams
                    })
    }

    componentDidMount() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        let prefetch = (Object.keys(this.props.dataModule.environmentDataSourceMapping).length !==
            Object.keys(this.props.dataModule.environments).length) ||
            (Object.keys(this.props.dataModule.integrations).length !==
                Object.keys(this.props.dataModule.relationship).length)
        let initial_prefetch = Object.keys(this.props.dataModule.environmentDataSourceMapping).length === 0 &&
            Object.keys(this.props.dataModule.environments).length === 0 &&
            Object.keys(this.props.dataModule.integrations).length === 0 &&
            Object.keys(this.props.dataModule.relationship).length === 0
        if (prefetch === true || initial_prefetch === true) {
            this.preFetch();
        }
        else {
            this.pre_load = false
            this.setState({ pre_load: false })
        }
        this.getMetaDatas();
        // this.getData();   
    }

    componentDidUpdate() {
        if (get_api_call_cnt === get_api_call_complete_cnt && this.state.pre_load === true) {
            // this.formData();
            this.pre_load = false;
            this.setState({ pre_load: false })
        }
    }
    loadDataSourceRender(params) {
        this.setState({showLoader:true})
        this.getDatasForTab(params)
    }
    changeTab(tab) {
        postMixpanel("Profile -> Data -> " + tab)
        this.setState({ activeTabKey: tab });
    }
    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }
    closeToast() {
        this.setState({ toastStatus: false });
    }

    componentWillUnmount() {
        d3.selectAll(".d3-tip").remove();
    }
    setLoader(value) {
        this.setState({ showSpinner: value });
    }
    setAttributeLoader(value) {
        this.setState({ showAttributeSpinner: value });
    }

    preFetch() {
        let teamID = localStorage.getItem('team_id')
        let tenantData = { "tenant_id": teamID };

        // Monitor Prefetch.
        // Single api call : /model_performance/results/info
        // if (!this.props.monitorModule.preFetchMonitorPreview) {
        //     preFetchMonitorPreview(tenantData, this.props.addMonitorPreview, this.props.addPerformancePreview,
        //         this.props.addModelPerformance);
        // }


        // // Data Prefetch
        // // Single Api call and interating the result to get exact metadata values
        if (!this.props.dataModule.preFetchMetaData) {
            preFetchMetaData(
                tenantData, this.props.addMetaData, this.props.addEnvironment,
                this.props.addEnvironmentDataSourceMapping, this.props.addDataSourceDataSetMapping,
                this.props.addDataSetAttributeMapping, this.props.setDataSet, this.props.setAttribute,
                this.props.setDataSource, this.props.setMlDataSource, this.props.addRelationship, this.props.addDataSource,
                this.props.addInfo, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate, this.props.addDiscoveryStatus,
                this.props.addProcessingStatus, this.props.noIntegration
            );
        }

        // // Monitor Prefetch
        // // Prefetch happens for every Integrations 
        // // Gets the result and iterate for all integrations
        // if (!this.props.dataModule.integrations) {
        //     for (const key of Object.keys(this.props.dataModule.integrations)) {
        //         if (!(key in this.props.monitorModule.fetchInfo && this.props.monitorModule.fetchInfo[key])) {
        //             preFetchMonitorInfo(
        //                 tenantData, this.props.addCompletenessDataSetInfo, this.props.addCompletenessAttributeInfo,
        //                 this.props.addTimelinessDataSetInfo, this.props.addTimelinessAttributeInfo,
        //                 this.props.addAccuracyDataSetInfo, this.props.addAccuracyAttributeInfo,
        //                 this.props.addConformityDataSetInfo, this.props.addConformityAttributeInfo,
        //                 this.props.addConsistencyDataSetInfo, this.props.addConsistencyAttributeInfo,
        //                 this.props.addDriftDataSetInfo, this.props.addDriftAttributeInfo, this.props.addMLDataSetInfo,
        //                 this.props.addMLAttributeInfo, this.props.addMonitorPreview, this.props.addUniquenessDataSetInfo,
        //                 this.props.addUniquenessAttributeInfo, this.props.addRecencyPreview,
        //                 this.props.prefetchMonitorState
        //             );
        //         }
        //     }
        // }

        // // Monitor Model Prefetch : model/map
        // // store the value in redux
        // if (!this.props.monitorModule.preFetchMonitorModelInfo) {
        //     preFetchMonitorModelInfo(
        //         teamID, this.props.addMLModelMapping,
        //         this.props.addMLDataSetInfo, this.props.addMLAttributeInfo,
        //         this.props.addMLModel, this.props.setMLModel
        //     );
        // }

        // // ML Monitor prefetch : ml-models 
        // // Profile ML filter and ML tab
        // if (!this.props.monitorModule.preFetchModelDetails) {
        //     preFetchModelDetails(teamID, this.props.addModelDetails);
        // }

        // custom Metrics Prefetch used in custom metric page
        // Fetch and Store
        // if (!this.props.monitorModule.preFetchMetrics) {
        //     preFetchMetrics(teamID, this.props.addMetrics);
        // }


        //    Monitor Prefetch
        // Prefetch happens for every Integrations 
        // Gets the result and iterate for all integrations
        // if (!this.props.monitorModule.preFetchMonitorListViewData) {
        //     preFetchMonitorListViewData(teamID, this.props.addMonitorDQListViewData);
        // }

        //    Notification Prefetch: user_notification/unread
        //    Used in navigation header
        //    Fetch and store
        // if (!this.props.dataModule.preFetchUserNotifications) {
        //     // Fetch first page result of user notifications
        //     preFetchUserNotifications(tenantData, addUserNotificationToState, store);

        // }

        //  Data Prefetch: /datasets
        //  Used in charts discover, profile
        //  Fetch and store
        if (!this.props.dataModule.prefetchDatasetDetails) {// Fetch dataset version details
            prefetchDatasetDetails(tenantData, addDatasetVersions, store);
        }

        // Data Prefetch: recency/all 
        // Used in Discovery and profile
        // Fetch and store
        if (!this.props.dataModule.prefetchRecencyDetails) {// Fetch Recency Details
            prefetchRecencyDetails(tenantData, addRecencyDetails, store);
        }

        // Discover prefetch: recent_search
        // user in discover page    
        // fetch and store
        if (!this.props.dataModule.prefetchRecentHistory) { // Fetch recent search history - discover page
            prefetchRecentHistory(tenantData, addRecentHistory, store);
        }

        // Fetch and store datasize : /data_size
        // simple prefetch
        if (!this.props.dataModule.prefetchDataSize) {// Fetch data size - discover page
            prefetchDataSize(tenantData, addDataSize, store)
        }

        // Monitor Model Prefetch used in configure and monitor : model_ab_data
        if (!this.props.dataModule.prefetchModelABData) { // Fetch Model AB configuration list
            prefetchModelABData(tenantData, setModelABDataToList, store);
        }

        // Monitor Prefetch: follow_attributes
        // used in monitor page
        // fetch and store
        // if (!this.props.monitorModule.preFetchFollowAttributes) {
        //     preFetchFollowAttributes(tenantData, addFollowAttributes, store);
        // }

        //    Alert Prefetch : alert_applicable_metrics
        //    used on alert page
        if (!this.props.monitorModule.preFetchMetadataDetails) {
            preFetchMetadataDetails(tenantData, storeMetadataDetails, store)
        }
    }

    setAttributeTab(dataset, attribute){
        this.setState({seletedDatasetID:dataset})
        this.setState({seletedAttribute:attribute})    
    }
    
    render() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }
        return (

            this.state.errorOccurred ?
                <ErrorHandler />
                :
                <>
                    <div id="toastMsg">
                        <Toast className={this.state.toastClass} onClose={() => this.closeToast()} show={this.state.toastStatus} delay={3000} autohide>
                            <div className="d-flex align-items-center">
                                <i className="toast-icon">
                                    {this.state.toastIcon ?
                                        <FontAwesomeIcon icon={this.state.toastIcon} /> :
                                        ""
                                    }
                                </i>
                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    <p>{this.state.toastMsg}</p>
                                </Toast.Body>
                                <button type="button" onClick={this.closeToast} className="close" data-dismiss="toast" aria-label="Close" />
                            </div>
                        </Toast>
                    </div>
                    <NavigationHeader page="profile" tab="data" />
                    <main>
                        <BreadCrumb icon='userCircle' title='Profile' separator={true} separatorData="Data" />
                        <input type="hidden" id="zoomlevel" />

                        <Tab.Container defaultActiveKey="relationship" activeKey={this.state.activeTabKey} onSelect={this.changeTab}>
                            <div className="qd-tab__header">
                                <div className="container-fluid">
                                    <div className="qd-tab__header-wrapper">
                                        <div className="qd-tab__header-menu">
                                            <Nav variant="tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="relationship" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Relationship {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="completeness" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Completeness {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="accuracy" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Accuracy {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="timeliness" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Timeliness {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="conformity" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Conformity {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="consistency" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Consistency {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="uniqueness" className={this.state.inProgress ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Uniqueness {this.state.inProgress ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="attribute" className={(this.state.inProgress || this.state.showAttributeSpinner) ? "has-spinner" : ""}>
                                                        <span className="qd-menu__link-text">Attribute {(this.state.inProgress || this.state.showAttributeSpinner) ? <Image className="spinner-img" src="spinner" /> : ''}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="qd-container">
                                <div className="qd-body">
                                    <div className="qd-tab__content" id={this.state.activeTabKey + "Tab"}>
                                        <Tab.Content>
                                            {this.state.showLoader === false ?
                                                <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                    eventKey="relationship">
                                                    <Relationship
                                                        pre_load={this.pre_load}
                                                        setLoader={this.setLoader}
                                                        changeTab={this.changeTab}
                                                        filterData={this.state.filterData}
                                                        existingList={this.state.existingList}
                                                        setAttributeTab={this.setAttributeTab}
                                                        handleFilteredData={this.handleFilteredData}
                                                        filteredValues={this.state.filteredValues}
                                                        startDate = {this.state.filterStartDate}
                                                        endDate = {this.state.filterEndDate}
                                                        handleDateFiltered={this.handleDateFiltered}
                                                        loadparams={this.state.loadparams}
                                                        filteredDatas={this.state.filteredDatas}
                                                        inProgress={this.state.inProgress}
                                                        noDataFound={this.state.noDataFound}
                                                        progressStatus={this.state.progressStatus}
                                                        showLoader={this.state.showLoader}

                                                        // this.setState({loadparams:loadparams})
                                                        // this.setState({filteredDatas:filteredDatas})}
                                                    />
                                                </Tab.Pane>
                                                :
                                                renderskloader(this.state.chartTitle, this.state.gridTitle)
                                            }
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="completeness">
                                                <Completeness 
                                                        setLoader={this.setLoader}
                                                        filterData={this.state.filterData}
                                                        changeTab={this.changeTab}
                                                        existingList={this.state.existingList}
                                                        setAttributeTab={this.setAttributeTab}
                                                        handleFilteredData={this.handleFilteredData}
                                                        filteredValues={this.state.filteredValues}
                                                        startDate = {this.state.filterStartDate}
                                                        endDate = {this.state.filterEndDate}
                                                        handleDateFiltered={this.handleDateFiltered}
                                                        loadparams={this.state.loadparams}
                                                        filteredDatas={this.state.filteredDatas}
                                                        inProgress={this.state.inProgress}
                                                        noDataFound={this.state.noDataFound}
                                                        progressStatus={this.state.progressStatus}
                                                        showLoader={this.state.showLoader}


                                                />
                                            </Tab.Pane>
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="accuracy">
                                                <Accuracy 
                                                    setLoader={this.setLoader}
                                                    changeTab={this.changeTab}
                                                    filterData={this.state.filterData}
                                                    existingList={this.state.existingList}
                                                    setAttributeTab={this.setAttributeTab}
                                                    handleFilteredData={this.handleFilteredData}
                                                    filteredValues={this.state.filteredValues}
                                                    startDate = {this.state.filterStartDate}
                                                    endDate = {this.state.filterEndDate}
                                                    handleDateFiltered={this.handleDateFiltered}
                                                    loadparams={this.state.loadparams}
                                                    filteredDatas={this.state.filteredDatas}
                                                    inProgress={this.state.inProgress}
                                                    noDataFound={this.state.noDataFound}
                                                    progressStatus={this.state.progressStatus}
                                                    showLoader={this.state.showLoader}


                                                />
                                            </Tab.Pane>
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="timeliness">
                                                <Timeliness 
                                                    setLoader={this.setLoader}
                                                    changeTab={this.changeTab}
                                                    filterData={this.state.filterData}
                                                    existingList={this.state.existingList}
                                                    setAttributeTab={this.setAttributeTab}
                                                    handleFilteredData={this.handleFilteredData}
                                                    filteredValues={this.state.filteredValues}
                                                    startDate = {this.state.filterStartDate}
                                                    endDate = {this.state.filterEndDate}
                                                    handleDateFiltered={this.handleDateFiltered}
                                                    loadparams={this.state.loadparams}
                                                    filteredDatas={this.state.filteredDatas}
                                                    inProgress={this.state.inProgress}
                                                    noDataFound={this.state.noDataFound}
                                                    progressStatus={this.state.progressStatus}
                                                    showLoader={this.state.showLoader}



                                                />
                                            </Tab.Pane>
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="conformity">
                                                <Conformity 
                                                    setLoader={this.setLoader}
                                                    changeTab={this.changeTab}
                                                    filterData={this.state.filterData}
                                                    existingList={this.state.existingList}
                                                    setAttributeTab={this.setAttributeTab}
                                                    handleFilteredData={this.handleFilteredData}
                                                    filteredValues={this.state.filteredValues}
                                                    startDate = {this.state.filterStartDate}
                                                    endDate = {this.state.filterEndDate}
                                                    handleDateFiltered={this.handleDateFiltered}
                                                    loadparams={this.state.loadparams}
                                                    filteredDatas={this.state.filteredDatas}
                                                    inProgress={this.state.inProgress}
                                                    noDataFound={this.state.noDataFound}
                                                    progressStatus={this.state.progressStatus}
                                                    showLoader={this.state.showLoader}



                                                />
                                            </Tab.Pane>
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="consistency">
                                                <Standardisation 
                                                    setLoader={this.setLoader}
                                                    changeTab={this.changeTab}
                                                    isConsistencyEnabled={this.state.isConsistencyEnabled}
                                                    filterData={this.state.filterData}
                                                    existingList={this.state.existingList}
                                                    setAttributeTab={this.setAttributeTab}
                                                    handleFilteredData={this.handleFilteredData}
                                                    filteredValues={this.state.filteredValues}
                                                    startDate = {this.state.filterStartDate}
                                                    endDate = {this.state.filterEndDate}
                                                    handleDateFiltered={this.handleDateFiltered}
                                                    loadparams={this.state.loadparams}
                                                    filteredDatas={this.state.filteredDatas}
                                                    inProgress={this.state.inProgress}
                                                    noDataFound={this.state.noDataFound}
                                                    progressStatus={this.state.progressStatus}
                                                    showLoader={this.state.showLoader}



                                                />
                                            </Tab.Pane>
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="uniqueness">
                                                <Uniqueness 
                                                    setLoader={this.setLoader}
                                                    changeTab={this.changeTab}
                                                    filterData={this.state.filterData}
                                                    existingList={this.state.existingList}
                                                    setAttributeTab={this.setAttributeTab}
                                                    handleFilteredData={this.handleFilteredData}
                                                    filteredValues={this.state.filteredValues}
                                                    startDate = {this.state.filterStartDate}
                                                    endDate = {this.state.filterEndDate}
                                                    handleDateFiltered={this.handleDateFiltered}
                                                    loadparams={this.state.loadparams}
                                                    filteredDatas={this.state.filteredDatas}
                                                    inProgress={this.state.inProgress}
                                                    noDataFound={this.state.noDataFound}
                                                    progressStatus={this.state.progressStatus}
                                                    showLoader={this.state.showLoader}


                                                />
                                            </Tab.Pane>
                                            <Tab.Pane mountOnEnter={true} unmountOnExit={true}
                                                eventKey="attribute">
                                                <Attribute 
                                                    filterData={this.state.filterData}
                                                    setLoader={this.setAttributeLoader}
                                                    seletedDatasetID={this.state.seletedDatasetID}
                                                    seletedAttribute={this.state.seletedAttribute}
                                                    setAttributeTab={this.setAttributeTab}
                                                    inProgress={this.state.inProgress}
                                                    noDataFound={this.state.noDataFound}
                                                    progressStatus={this.state.progressStatus}
                                                    showLoader={this.state.showLoader}

                                                />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </div>
                        </Tab.Container>
                    </main>
                    <CopyRightsFooter />
                </>
        );

    }

}

// export default DataProfile;


const mapStateToProps = state => {
    return state;
}


export default connect(
    mapStateToProps, {
    addMetaData,
    addMLModelMapping,
    setMLModel,
    setModelABDataToList,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setDataSet,
    // setAttribute,
    setDataSource,
    setMlDataSource,
    addRelationship,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setStartDate,
    setEndDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addMonitorPreview,
    addPerformancePreview,
    addModelPerformance,
    addCompletenessDataSetInfo,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyPreview,
    addMLModel,
    addModelDetails,
    addMetrics,
    addMonitorDQListViewData,
    prefetchMonitorState,
    noIntegration,
    mappedDatasetInfo,
    // listViewTableDatasetDataSource, 
    // setDataSet,
    setAttribute,
    setChipData,
    setFilteredValues,
    setTimeFilter,
    // setStartDate,
    // setEndDate
})(DataProfile);