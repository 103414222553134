import React from 'react';
import BreadCrumb from '../components/breadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavigationHeader from '../components/navigationHeader';
import Dropdown from 'react-bootstrap/Dropdown';
import { faUserPlus, faUserMinus, faRedoAlt, faServer, faHdd, faExclamationTriangle, faBell, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import ViewNotification from './components/viewNotification';
import { fetchAllUserNotifications, getApi } from "../utils/event_handling";
import ListGroup from 'react-bootstrap/ListGroup';
import ErrorHandler from '../components/error_500';
import { CopyRightsFooter } from '../components/copyrights';
import { updateUserNotification, postUIlogs } from "../utils/event_handling";
import Load from '../components/loadAction';
import { connect } from "react-redux";
import { addUserNotificationToState, markNotificationAsRead, removeNotificationFromList } from "../redux/actions";
import NotificationLoader from "./components/notificationLoader";
import { IS_NONE_CHECK } from "../utils/constant";
import { getFeatureAccess } from "../utils/common_utils";
import $ from 'jquery';
import {logoutPageRedirect} from "../utils/common_utils";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#!"
        ref={ref}
        className="nav-link dropdown-toggle"
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));


class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.updateUserNotification = updateUserNotification.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.setNotficationState = this.setNotificationState.bind(this);
        let selectedNotificationId = this.getSelectedNotificationId();
        let selectedView = null;

        if (selectedNotificationId !== null) {
            const userNotificationDataFromProps = this.props.dataModule.userNotificationData;
            let currentUnreads = userNotificationDataFromProps.unreadNotifications;
            selectedView = currentUnreads.find(x => x["notification_id"] === selectedNotificationId);
            // this.updateUserNotification(selectedNotificationId, selectedView);
        }

        this.state = {
            errorOccurred: false,
            alerts: null,
            featureAccess: getFeatureAccess(),
            requests: null,
            invites: null,
            notificationType: null,
            selectedView: selectedView,
            allNotifications: null,
            notificationLoaded: false,
            currentNotifications: null,
            notificationFilter: null,
            unreadNotifications: null,
            selectedNotification: selectedNotificationId,
        }
    }

    getSelectedNotificationId() {
        const notification_id = localStorage.getItem("selected_notification_id");
        if (IS_NONE_CHECK.includes(notification_id)) {
            return null;
        }

        // Remove the key from local storage
        localStorage.removeItem("selected_notification_id");
        return parseInt(notification_id);
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    setNotificationState(val) {
        this.setState({
            notificationType: val.notification_type,
            selectedView: val
        });
    }

    renderNotificationContent(val, defaultActiveKey) {

        if (this.state.selectedNotification !== null && this.state.selectedNotification === val.notification_id) {
            if (val.notification_type === "request") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faUserPlus} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Invitation Request</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["username"]} would like to join your team on Qualdo.</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );
            }
            else if (val.notification_type === "request_auto_deleted") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faUserMinus} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Auto Deleted Request</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}.</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );
            }
            else if (val.notification_type === "Payment Failure Alert") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faDollarSign} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Payment Failure Alert</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Limit Exceeded Alert") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Limit Exceeded Alert</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "app-notification unread";
                } else {
                    readNotificationClassName = "app-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff"
                                        icon={this.getErrorIcon(val.notification_type)} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>{val["notification_type"]}</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p> {val["message"]} </p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );
            }
        } else {
            if (val.notification_type === "request") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }

                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <div className="list-group list-group-flush">
                            <ListGroup.Item className={readNotificationClassName}
                                active={defaultActiveKey === val["notification_id"]}
                                eventKey={val["notification_id"]}
                                onClick={evt => {
                                    this.setView(evt, val.notification_type, val)
                                }}>
                                <div className="notification-icon">
                                    <span>
                                        <FontAwesomeIcon color="#fff" icon={faUserPlus} />
                                    </span>
                                </div>
                                <div className="notification-content">
                                    <div className="list-group-item-content">
                                        <h5>Invitation Request</h5>
                                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                    </div>
                                    <p>{val["username"]} would like to join your team on Qualdo.</p>
                                </div>
                            </ListGroup.Item>
                        </div>
                    </div>
                );
            }
            else if (val.notification_type === "request_auto_deleted") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }

                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <div className="list-group list-group-flush">
                            <ListGroup.Item className={readNotificationClassName}
                                active={defaultActiveKey === val["notification_id"]}
                                eventKey={val["notification_id"]}
                                onClick={evt => {
                                    this.setView(evt, val.notification_type, val)
                                }}>
                                <div className="notification-icon">
                                    <span>
                                        <FontAwesomeIcon color="#fff" icon={faUserMinus} />
                                    </span>
                                </div>
                                <div className="notification-content">
                                    <div className="list-group-item-content">
                                        <h5>Auto Deleted Request</h5>
                                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                    </div>
                                    <p>{val["message"]}</p>
                                </div>
                            </ListGroup.Item>
                        </div>
                    </div>
                );
            }
            else if (val.notification_type === "Payment Failure Alert") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faDollarSign} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Payment Failure Alert</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Datasource Configuration Alert") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list"
                        key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Datasource Configuration Alert</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Datasets deletion info") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list"
                        key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Dataset deletion</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Model Monitoring Metrics Alert") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list">
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Datasource Configuration Alert</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Limit Exceeded Alert") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Limit Exceeded Alert</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "No dataset found" || val.notification_type === "Model dataset has null values") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            {val.notification_type === "No dataset found" ?
                                <div className="notification-content">
                                    <div className="list-group-item-content">
                                        <h5>No Dataset Identified Alert</h5>
                                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                    </div>
                                    <p>{val["message"]}</p>
                                </div>
                                :
                                <div className="notification-content">
                                    <div className="list-group-item-content">
                                        <h5>Missing Data</h5>
                                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                    </div>
                                    <p>Dataset(s) associated with one of your configured models has null values</p>
                                </div>
                            }
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Model Error") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "app-notification unread";
                } else {
                    readNotificationClassName = "app-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={this.getErrorIcon(val.notification_type)} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>{val["notification_type"]}</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>Model quality gate failed for the
                                    dataset {val["additional_info"] !== null ? val["additional_info"]["data_set_name"] : ""}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );
            }
            else if (val.notification_type === "Missing Dataset") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "app-notification unread";
                } else {
                    readNotificationClassName = "app-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={this.getErrorIcon(val.notification_type)} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>{val["notification_type"]}</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>Version mismatch found for the
                                    dataset {val["additional_info"] !== null ? val["additional_info"]["data_set_name"] : ""}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );
            } else if (val.notification_type === "Model Dataset Not Refreshed") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "settings-notification unread";
                } else {
                    readNotificationClassName = "settings-notification";
                }
                return (
                    <div className="user-notification__list"
                        key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={faExclamationTriangle} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>Dataset deletion</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>{val["message"]}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else if (val.notification_type === "Schema Drift") {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "app-notification unread";
                } else {
                    readNotificationClassName = "app-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={this.getErrorIcon(val.notification_type)} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>{val["notification_type"]}</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>Schema Drift detected in dataset</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );

            }
            else {
                let readNotificationClassName;
                let readStatus = val["read_status"];
                if (readStatus === false) {
                    readNotificationClassName = "app-notification unread";
                } else {
                    readNotificationClassName = "app-notification";
                }
                return (
                    <div className="user-notification__list" key={val.notification_id}>
                        <ListGroup.Item className={readNotificationClassName}
                            active={defaultActiveKey === val["notification_id"]}
                            eventKey={val["notification_id"]}
                            onClick={evt => {
                                this.setView(evt, val.notification_type, val)
                            }}>
                            <div className="notification-icon">
                                <span>
                                    <FontAwesomeIcon color="#fff" icon={this.getErrorIcon(val.notification_type)} />
                                </span>
                            </div>
                            <div className="notification-content">
                                <div className="list-group-item-content">
                                    <h5>{val["notification_type"]}</h5>
                                    <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                                </div>
                                <p>Data quality gate failed for the
                                    dataset {val["additional_info"] !== null ? val["additional_info"]["data_set_name"] : ""}</p>
                            </div>
                        </ListGroup.Item>
                    </div>
                );
            }
        }

    }


    renderContent(defaultActiveKey) {
        if (this.state.currentNotifications === null) {
            return [];
        }

        let requests = this.state.currentNotifications.map(val => this.renderNotificationContent(val, defaultActiveKey));
        return requests;
    }

    setView(evt, notificationType, selectedView) {
        this.handleSidenavClose();
        let notificationId = selectedView["notification_id"];
        let notificationStatus = selectedView["read_status"];

        this.setState({
            // notificationType: notificationType,
            selectedView: selectedView,
            selectedNotification: null
        });

        if (!notificationStatus) {
            // Unread notification is clicked by user. So mark it as read notification.
            // Update the change to DB entries so that when user logs in next time, this
            // will not come as unread notification
            this.updateUserNotification(notificationId, selectedView);
        }
    }

    static getDerivedStateFromProps(props, state) {
        const userNotificationDataFromProps = props.dataModule.userNotificationData;

        if (userNotificationDataFromProps.completeLoadInitiated === false) {
            // User is coming to notification page for the first time. So start fetching
            // all the notifications and assign them to corresponding key in redux store
            fetchAllUserNotifications(props.addUserNotificationToState);
            return null;
        }

        if (state.notificationLoaded === false && userNotificationDataFromProps.loaded === true) {

            return {
                unreadNotifications: userNotificationDataFromProps.unreadNotifications,
                currentNotifications: userNotificationDataFromProps.allNotifications,
                allNotifications: userNotificationDataFromProps.allNotifications,
                notificationLoaded: true
            };
        }

        return null;
    }

    setNotificationFilter(event) {
        let current_notifications = [];
        let selectedNotificationType;
        let notifications = this.state.allNotifications;
        if (event !== "ALL") {
            if (notifications !== null) {
                notifications.forEach(element => {
                    if (element["notification_type"] === event) {
                        current_notifications.push(element);
                    }
                });
            }
        } else {
            current_notifications = notifications;
        }


        if (event === "request") {
            selectedNotificationType = "Invitation Request";
        } else if (event === "request_auto_deleted") {
            selectedNotificationType = "Request Auto-deleted";
        } else if (event === "Data Quality Error") {
            selectedNotificationType = "Data Quality Error";
        } else if (event === "Model Error") {
            selectedNotificationType = "Model Error";
        } else if (event === "ALL") {
            selectedNotificationType = "ALL";
        } else if (event === "Limit Exceeded Alert") {
            selectedNotificationType = "Limit Exceed Alert";
        } else if (event === "Payment Failure Alert") {
            selectedNotificationType = "Payment Failure Alert";
        } else if (event === "Datasource Configuration Alert") {
            selectedNotificationType = "Datasource Configuration Alert";
        } else if (event === "Model Monitoring Metrics Alert") {
            selectedNotificationType = "Model Monitoring Metrics Alert";
        } else if (event === "Datasets deletion info") {
            selectedNotificationType = "Datasets deletion info";
        } else if (event === "Model Dataset Not Refreshed") {
            selectedNotificationType = "Model Dataset Not Refreshed";
        } else {
            selectedNotificationType = "Invitation Request";
        }

        this.setState({
            currentNotifications: current_notifications,
            notificationType: selectedNotificationType
        });

    }

    getErrorIcon(notification_type) {
        if (notification_type === "Data Quality Error") {
            return faServer;
        } else if (notification_type === "Model Error") {
            return faHdd;
        }
    }

    getNotificationSendTimeInMinutes(createdTimeString) {
        let minutes = null;
        let hours = null;
        let days = null;
        var currentTime = new Date();
        var createdTime = new Date(createdTimeString);
        minutes = parseInt((currentTime - createdTime) / (60 * 1000));
        hours = parseInt((currentTime - createdTime) / (3600 * 1000));
        days = parseInt((currentTime - createdTime) / (24 * 3600 * 1000));
        if (minutes != null && minutes <= 60) {
            return minutes + " Mins ago";

        } else if (hours != null && hours <= 24) {
            return hours + " Hours ago";

        } else if (hours > 25 && hours < 48) { // Added this condition to increase the day count by 1 if hours range in between 24 to 48.
            return days + 1 + " days ago";
        }
        else {
            return days + " days ago"
        }
    }

    handleSidenav = () => {
        $('#qd-aside').addClass("--is-visible");
        $('body').addClass("overflow-hidden");
    }
    handleSidenavClose = () => {
        $('#qd-aside').removeClass("--is-visible");
        $('body').removeClass("overflow-hidden");
    }

    render() {
        let logoutPage = logoutPageRedirect()
        if (logoutPage) {
            return ''
        }

        if (this.state.errorOccurred) {
            return (<ErrorHandler />);
        }

        if (this.state.notificationLoaded === false || this.state.currentNotifications === null) {
            // Loading for the first time
            return (<NotificationLoader />)
        }
        let hideModelNotificationTab = true;

        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const modelNotification = this.state.featureAccess["models"];
            hideModelNotificationTab = modelNotification === false;
        }

        let defaultActiveKey;
        let selectedView = null;
        let notificationType = null;
        const allNotifications = this.state.allNotifications;
        if (allNotifications === undefined || allNotifications === null) {
            return "";
        }

        if (this.state.selectedView !== null) {
            // User has selected one notification from the notification sidebar. So highlight that notification
            selectedView = this.state.selectedView;
            defaultActiveKey = this.state.selectedView["notification_id"];
            notificationType = this.state.notificationType;
        } else if (this.state.selectedNotification !== null) {
            // User has selected this notification from Bell icon. So highlight that notification details
            defaultActiveKey = this.state.selectedNotification;
            selectedView = this.state.selectedView;
        } else if (allNotifications.length > 0) {
            // User has selected "View all notifications" button. So highlight the first notification
            selectedView = allNotifications[0];
            defaultActiveKey = !IS_NONE_CHECK.includes(selectedView) ? selectedView["notification_id"] : "";
            notificationType = this.state.notificationType;
        }

        return (
            <>
                <NavigationHeader />
                <main>
                    <BreadCrumb icon='notifications' title='Qualdo Notifications' sidenav='1' showSidenav={this.handleSidenav} />
                    <div className="qd-container">
                        <div className="qd-body no-tabs qd-grid qd-notification">
                            <div id="qd-aside" className="qd-aside qd-aside-notification qd-grid-item">
                                <div className="d-flex w-100 justify-content-between pl-3 pr-3 qd-aside-notification__header">
                                    <ul>
                                        <li className="nav-item">
                                            <Dropdown alignRight
                                                onSelect={evt => { this.setNotificationFilter(evt) }}>
                                                <span className="text-dark">  Filter By:</span>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <span className="nav-item text-dark">
                                                        <strong>{this.state.notificationType !== null ?
                                                            this.state.notificationType : "ALL"}
                                                        </strong>
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="ALL" href="#!">ALL</Dropdown.Item>
                                                    {hideModelNotificationTab ? "" : <Dropdown.Item eventKey="Model Error" href="#!">Model Error</Dropdown.Item>}
                                                    <Dropdown.Item eventKey="request" href="#!">Invitation Request</Dropdown.Item>
                                                    <Dropdown.Item eventKey="request_auto_deleted" href="#!"> Auto Deleted Requests </Dropdown.Item>
                                                    <Dropdown.Item eventKey="Data Quality Error" href="#!">Data Quality Error</Dropdown.Item>
                                                    {hideModelNotificationTab ? "" : <Dropdown.Item eventKey="Limit Exceeded Alert" href="#!">Limit Exceeded Alert</Dropdown.Item>}
                                                    {hideModelNotificationTab ? "" : <Dropdown.Item eventKey="Payment Failure Alert" href="#!">Payment Failure Alert</Dropdown.Item>}
                                                    <Dropdown.Item eventKey="Datasource Configuration Alert" href="#!">Datasource Configuration Alert</Dropdown.Item>
                                                    {hideModelNotificationTab ? "" : <Dropdown.Item eventKey="Model Monitoring Metrics Alert" href="#!">Model Monitoring Metrics Alert</Dropdown.Item>}
                                                    <Dropdown.Item eventKey="Datasets deletion info" href="#!">Datasets deletion info</Dropdown.Item>
                                                    {hideModelNotificationTab ? "" : <Dropdown.Item eventKey="Model Dataset Not Refreshed" href="#!">Model Dataset Not Refreshed</Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                    <span className="action-icons">
                                        <a className="app-icon"
                                            href={"#!"}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title={"Refresh"}
                                            // onClick={this.getNotificationsPageData.bind(this)}
                                            data-original-title="Refresh">
                                            <i>
                                                <FontAwesomeIcon color="#484848"
                                                    icon={faRedoAlt} />
                                            </i>
                                        </a>
                                    </span>
                                </div>
                                <ListGroup defaultActiveKey={defaultActiveKey}>
                                    {this.state.currentNotifications.length > 0 ?
                                        this.renderContent(defaultActiveKey) :
                                        ''
                                    }
                                </ListGroup>
                            </div>
                            <div className="qd-aside-overlay" />
                            <button className="close remove-aside" type="button" onClick={this.handleSidenavClose}/>
                            {
                                this.state.currentNotifications !== null && selectedView !== undefined ? (
                                    this.state.currentNotifications.length > 0 ?
                                        <ViewNotification notificationType={notificationType}
                                            removeNotificationFromList={this.props.removeNotificationFromList}
                                            selectedView={selectedView} /> :
                                        <div className="text-center text-muted d-flex align-items-center flex-column justify-content-center w-100 h-100">

                                            <h1>
                                                <i>
                                                    <FontAwesomeIcon icon={faBell} />
                                                </i>
                                            </h1>
                                            <h4>No Notifications found.</h4>
                                            <p>It appears that you have not received any notifications from the Qualdo team.</p>

                                        </div>
                                )
                                    :
                                    <div
                                        className="text-center text-muted d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                        <Load />
                                    </div>
                            }
                        </div>
                    </div>
                </main>
                <CopyRightsFooter />
            </>
        );
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, {
    markNotificationAsRead,
    removeNotificationFromList,
    addUserNotificationToState
})(Notifications);
