import React from 'react';
import DropdownTreeSelect from "react-dropdown-tree-select";
import $ from 'jquery';
import "./nested-data.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomTreeSelect extends React.Component {

    constructor(props) {
        super(props);
        this.onFocus = this.onFocus.bind(this);
        
        this.onChange = this.onChange.bind(this);
        this.loadFilterDataset = this.loadFilterDataset.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.state = {
            id:this.props.id,
            placeholder:this.props.placeholder,
            selected_id:[],
            selected_id_index:[],
            data:this.props.data,
            cur_data:"",
            renderDataset:true,
          //  fullscreenviewselect:this.props.fullscreenviewselect
        }
    }


    loadFilterDataset(pmname){

        let parms; 
        let filterDatasetId = []
        let filterMetricsId = []
        let filterAttributeId = []
        let filterUncheckedDatasetId = []
        if(pmname.target.id==="parentview_data_set" || pmname.target.id==="fullscreen_data_set"){

            let cur_data = this.props.data.filter((data)=> 
                parseInt(data.integration_id) === parseInt(this.props.seletedDatasource) || data.integration_id === "-1"
             )

             let metrics_data = this.props.metrics_data;
            if(metrics_data!==undefined){
                for(let i=0;i<metrics_data.length;i++){
                    if(metrics_data[i].value!=="*" && metrics_data[i].checked!==undefined){
                        if(metrics_data[i].checked === true){
                            filterMetricsId.push({value:metrics_data[i].value,label:metrics_data[i].label})
                        }
                    }
                }
            }

             for(let i=0;i<cur_data.length;i++){
                if(cur_data[i].value!=="*" && cur_data[i].checked!==undefined){
                    if(cur_data[i].checked === true){
                        filterDatasetId.push({value:cur_data[i].value,label:cur_data[i].label})
                    }else {
                        filterUncheckedDatasetId.push({value:cur_data[i].value,label:cur_data[i].label})
                    }
                }
                if(cur_data[i]["children"]!==undefined){
                    for(let j=0;j<cur_data[i]["children"].length;j++){
                        if(cur_data[i]["children"][j].value!=="*"){
                            if(cur_data[i]["children"][j].checked===true){
                                filterDatasetId.push({value:cur_data[i]["children"][j].value,label:cur_data[i]["children"][j].label});
                            }else {
                                filterUncheckedDatasetId.push({value:cur_data[i]["children"][j].value,label:cur_data[i]["children"][j].label});
                            }
                        }
                    }
                }
             }
             parms = {
                filterdDatsource:[this.props.seletedDatasource],
                fiteredDatseset:filterDatasetId,
                fitlerdDate:[this.props.startDate,this.props.endDate],
                fitlerdAttr:[],
                filterMetricsId:filterMetricsId
                }


                if(filterDatasetId.length > 0){
                    if(pmname.target.getAttribute("data-type")==="fullscreen") { 
                        this.props.loadFilterDataset(parms)
                    }else {
                        this.props.loadFilterDataset(parms)
                    }
                }else {
                    parms.fiteredDatseset = ["*"];
                    if(pmname.target.getAttribute("data-type")==="fullscreen") { 
                        this.props.loadFilterDataset(parms)
                    }else {
                        this.props.loadFilterDataset(parms)
                    }
                }
        }
        if(pmname.target.id==="parentview_attributes" || pmname.target.id==="fullscreen_attributes"){
        let cur_data = this.props.data;
        let parms 
        let datasetindex; 
        if(pmname.target.id==="parentview_attributes" || pmname.target.id==="fullscreen_attributes"){
            
            let metrics_data = this.props.metrics_data;
            if(metrics_data!==undefined){
                for(let i=0;i<metrics_data.length;i++){
                    if(metrics_data[i].value!=="*" && metrics_data[i].checked!==undefined){
                        if(metrics_data[i].checked === true){
                            filterMetricsId.push({value:metrics_data[i].value,label:metrics_data[i].label})
                        }
                    }
                }
            }

            for(let i=0;i<cur_data.length;i++){
              //  let datasetid;
                if( cur_data[i].value!=="*"){
                    if(cur_data[i].dataset_id!==undefined){
                        // datasetindex = -1;
                        // datasetid = cur_data[i]["dataset_id"];
                        // datasetindex = this.props.dataset_data.findIndex(function(item){
                        //     return item === datasetid;
                        //       });
                        // if(datasetindex === -1){
                        //     filterDatasetId.push({value:datasetid,label:this.props.dataset_data["datasetindex"]["label"]})
                        // }
                    }
                    if(cur_data[i].value  > 0 && cur_data[i].checked === true){
                        filterAttributeId.push({"value":cur_data[i].value})
                    }

                    if(cur_data[i]["children"]!==undefined){
                        for(let j=0;j<cur_data[i]["children"].length;j++){
                            let datasetid;
                            if(cur_data[i]["children"][j].value!=="*"){
                                datasetindex = -1;
                                datasetid = cur_data[i]["children"][j]["dataset_id"];
                                datasetindex = filterDatasetId.findIndex(function(item){
                                    return item === datasetid;
                                });
                                if(datasetindex === -1){
                                    //filterDatasetId.push({"value":datasetid})
                                }
                                if(cur_data[i]["children"][j].value > 0 && cur_data[i]["children"][j]["checked"]===true){
                                    filterAttributeId.push({"value":cur_data[i]["children"][j].value,"label":cur_data[i]["children"][j].label});
                                }
                            }
                        }
                    }
                }
             }

             cur_data = this.props.dataset_data;
             for(let i=0;i<cur_data.length;i++){
                if(cur_data[i].value!=="*" && cur_data[i].checked!==undefined){
                    if(cur_data[i].checked === true){
                        filterDatasetId.push({value:cur_data[i].value,label:cur_data[i].label})
                    }else {
                        filterUncheckedDatasetId.push({value:cur_data[i].value,label:cur_data[i].label})
                    }
                }
                if(cur_data[i]["children"]!==undefined){
                    for(let j=0;j<cur_data[i]["children"].length;j++){
                        if(cur_data[i]["children"][j].value!=="*"){
                            if(cur_data[i]["children"][j].checked===true){
                                filterDatasetId.push({value:cur_data[i]["children"][j].value,label:cur_data[i]["children"][j].label});
                            }else {
                                filterUncheckedDatasetId.push({value:cur_data[i]["children"][j].value,label:cur_data[i]["children"][j].label});
                            }
                        }
                    }
                }
             }

             parms = {
                filterdDatsource:[this.props.seletedDatasource],
                fiteredDatseset:filterDatasetId,
                fitlerdDate:[this.props.startDate,this.props.endDate],
                fitlerdAttr:filterAttributeId,
                filterMetricsId:filterMetricsId
                }
                if(pmname.target.getAttribute("data-type")==="fullscreen") { 
                    this.props.loadFilterDataset(parms)
                }else {
                    this.props.loadFilterDataset(parms)
                }
                return;
        }
        }
        if(pmname.target.id==="parentview_metrics" || pmname.target.id==="fullscreen_metrics"){

            let cur_data = this.props.data;
            for(let i=0;i<cur_data.length;i++){
                if(cur_data[i].value!=="*" && cur_data[i].checked!==undefined){
                    if(cur_data[i].checked === true){
                        filterMetricsId.push({value:cur_data[i].value,label:cur_data[i].label})
                    }
                }
            }
            cur_data = this.props.dataset_data;
            for(let i=0;i<cur_data.length;i++){
               if(cur_data[i].value!=="*" && cur_data[i].checked!==undefined){
                   if(cur_data[i].checked === true){
                       filterDatasetId.push({value:cur_data[i].value,label:cur_data[i].label})
                   }else {
                       filterUncheckedDatasetId.push({value:cur_data[i].value,label:cur_data[i].label})
                   }
               }
               if(cur_data[i]["children"]!==undefined){
                   for(let j=0;j<cur_data[i]["children"].length;j++){
                       if(cur_data[i]["children"][j].value!=="*"){
                           if(cur_data[i]["children"][j].checked===true){
                               filterDatasetId.push({value:cur_data[i]["children"][j].value,label:cur_data[i]["children"][j].label});
                           }else {
                               filterUncheckedDatasetId.push({value:cur_data[i]["children"][j].value,label:cur_data[i]["children"][j].label});
                           }
                       }
                   }
               }
            }

            cur_data = this.props.attribute_data;
            for(let i=0;i<cur_data.length;i++){
                //  let datasetid;
                  if( cur_data[i].value!=="*"){
                      if(cur_data[i].dataset_id!==undefined){
                          // datasetindex = -1;
                          // datasetid = cur_data[i]["dataset_id"];
                          // datasetindex = this.props.dataset_data.findIndex(function(item){
                          //     return item === datasetid;
                          //       });
                          // if(datasetindex === -1){
                          //     filterDatasetId.push({value:datasetid,label:this.props.dataset_data["datasetindex"]["label"]})
                          // }
                      }
                      if(cur_data[i].value  > 0 && cur_data[i].checked === true){
                          filterAttributeId.push({"value":cur_data[i].value})
                      }
  
                      let datasetindex;
                      if(cur_data[i]["children"]!==undefined){
                          for(let j=0;j<cur_data[i]["children"].length;j++){
                              let datasetid;
                              if(cur_data[i]["children"][j].value!=="*"){
                                  datasetindex = -1;
                                  datasetid = cur_data[i]["children"][j]["dataset_id"];
                                  datasetindex = filterDatasetId.findIndex(function(item){
                                      return item === datasetid;
                                  });
                                  if(datasetindex === -1){
                                      //filterDatasetId.push({"value":datasetid})
                                  }
                                  if(cur_data[i]["children"][j].value > 0 && cur_data[i]["children"][j]["checked"]===true){
                                      filterAttributeId.push({"value":cur_data[i]["children"][j].value,"label":cur_data[i]["children"][j].label});
                                  }
                              }
                          }
                      }
                  }
               }
            

            parms = {
                filterdDatsource:[this.props.seletedDatasource],
                fiteredDatseset:filterDatasetId,
                fitlerdDate:[this.props.startDate,this.props.endDate],
                fitlerdAttr:filterAttributeId,
                filterMetricsId:filterMetricsId
                }

                if(pmname.target.getAttribute("data-type")==="fullscreen") { 
                    this.props.loadFilterDataset(parms)
                }else {
                    this.props.loadFilterDataset(parms)
                }
                return;
        }
    }
   
    componentWillUnmount(){
    }
    componentDidMount(){
        $('body').click(function() {
            $(".textfield-outlined").removeClass("customselect-active");
        });

        $(document).off('click', '#datasettreeselectapply');
        $(document).on('click', '#datasettreeselectapply', function(e){
            // if(this.props.fullscreenviewselect){
            //     $("#fullscreen_data_set").click();
            // }else{
            //     $("#parentview_data_set").click();
            // }
            $("#"+$("#datasettreeselectapply").parents().eq(5)[0]["childNodes"][1]["attributes"]["id"]["value"]).click();

        $(".textfield-outlined").removeClass("customselect-active");
        document.body.click()
        // eslint-disable-next-line
        }.bind(this));
        
       $(document).off('click', '#attributestreeselectapply');
        $(document).on('click', '#attributestreeselectapply', function(e){
            // if(this.props.fullscreenviewselect){
            //     $("#fullscreen_attributes").click();
            // }else{
            //     $("#parentview_attributes").click();
            // }
            $("#"+$("#attributestreeselectapply").parents().eq(5)[0]["childNodes"][1]["attributes"]["id"]["value"]).click();
            $(".textfield-outlined").removeClass("customselect-active");
            document.body.click()
            // eslint-disable-next-line
        }.bind(this));

        $(document).off('click', '#metricsapply');
        $(document).on('click', '#metricsapply', function(e){
            //  $(this).closest('input').find(".trigger-click").click();
           // return;
            // if(this.props.fullscreenviewselect){
            //     $("#fullscreen_metrics").click();
            // }else{
            //     $("#parentview_metrics").click();
            // }
            $("#"+$("#metricsapply").parents().eq(5)[0]["childNodes"][1]["attributes"]["id"]["value"]).click();
            $(".textfield-outlined").removeClass("customselect-active");
            document.body.click()
            // eslint-disable-next-line
        }.bind(this));

        $(document).off('click', '.treeselectcancel');
        $(document).on('click', '.treeselectcancel', function(e){
        document.body.click()
        $(".textfield-outlined").removeClass("customselect-active");
        // eslint-disable-next-line
        }.bind(this));
        this.setState({"cur_data":this.state.data});
        let cur_id = this.state.id;
        $("#"+cur_id+" .search").keyup(function(){
            setTimeout(() => {
            if($("span").hasClass("no-matches")){
                $(".custom-dropdown-action").remove();
            }else {
                setTimeout(() => {
                    if($(this).parents().eq(4)[0]["attributes"]["id"]["value"]==="chooseDatassetForDqError"){
                    if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false){
                        $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                        <button type="button" id="datasettreeselectcancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                        <button type="button" id="datasettreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                    } 
                    }
                    if($(this).parents().eq(4)[0]["attributes"]["id"]["value"]==="chooseAttributeForDqError"){
                        if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false){
                            $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                            <button type="button" id="attributestreeselectcancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                            <button type="button" id="attributestreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                        } 
                    }
                    if($(this).parents().eq(4)[0]["attributes"]["id"]["value"]==="chooseMetricsForDqError"){
                        if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false){
                            $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                            <button type="button" id="metricscancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                            <button type="button" id="metricsapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                                } 
                    }
                }, 10);
            }},500);    
        });
    }

    componentDidUpdate(){
        // let cur_id = this.state.id;
        if(this.props.name==="data_set" && this.props.data!==undefined){
            setTimeout(() => {
                if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false && this.props.data.length > 1){
                    $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                    <button type="button" id="datasettreeselectcancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                    <button type="button" id="datasettreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                } 
            }, "100");
        }
        if(this.props.name==="attributes" && this.props.data!==undefined){
            setTimeout(() => {
                if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false && this.props.data.length > 1){
                    $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                    <button type="button" id="attributestreeselectcancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                    <button type="button" id="attributestreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                } 
            }, "100");
        }

      

        if(this.props.name==="metrics" && this.props.data!==undefined){
            setTimeout(() => {
                if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false && this.props.data.length > 1){
                    $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                    <button type="button" id="metricscancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                    <button type="button" id="metricsapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                    } 
            }, "100");
        }
    }

    onFocus(){
        // While changing datasource need to render the dropdown with latest data
        if(this.props.name==="data_set"){
            this.setState({renderDataset:true,type:"data_set"})
        }
        if(this.props.name==="attributes"){
            this.setState({renderDataset:true,type:"attributes"})
        }
        let cur_id = this.state.id;
        if(this.state.data!==undefined){
            if(this.state.data.length > 0){
                setTimeout(() => {
                    if(this.props.name==="data_set"){
                        if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false && this.props.data.length > 1){
                            $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                            <button type="button" id="datasettreeselectcancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                            <button type="button" id="datasettreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                            $("#"+cur_id).parent().parent().addClass('customselect-active');   
                        }
                    }

                    if(this.props.name==="attributes"){
                        if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false && this.props.data.length > 1){
                            $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                            <button type="button" id="attributestreeselectcancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                            <button type="button" id="attributestreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                                $("#"+cur_id).parent().parent().addClass('customselect-active');   
                        }
                    }
                    if(this.props.name==="metrics"){
                        if($('.custom-dropdown-action').length===0 && $("span").hasClass("no-matches")===false && this.props.data.length > 1){
                            $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                            <button type="button" id="metricscancel" class="treeselectcancel btn btn-outline btn-grey btn-circle">Close</button>
                            <button type="button" id="metricsapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                                $("#"+cur_id).parent().parent().addClass('customselect-active');   
                        }
                    }    

                }, "100");
            }
        }
    }

    onChange = (currentNode, selectedNodes) => {
        if(this.props.name === "metrics"){
            let cur_data = this.props.data;
            if (currentNode.value === "*") {
                this.toggleAll(currentNode);
            } else {
                let index = -1;
                index = cur_data.findIndex(function(item){
                    return item.value === currentNode["value"];
                      });
                      if(index > -1){
                        cur_data[index]["checked"] = currentNode["checked"]
                    }

            }
            this.props.handleSelectedAttributes(cur_data);
            this.setState({renderDataset:false})
        }

        if(this.props.name==="data_set"){
            let cur_data = this.props.data;
            if (currentNode.value === "*") {
                this.toggleAll(currentNode);
            } else {
            let selected_id  = this.state.selected_id;
            let selected_id_index = this.state.selected_id_index;
            let index = -1;
            if(currentNode.parent_data_set_id===undefined){
                index = cur_data.findIndex(function(item){
                return parseInt(item.value) === parseInt(currentNode["value"]);
                  });
                  if(index > -1){
                    cur_data[index]["checked"] = currentNode["checked"]
                }
                if(currentNode["_children"]!==undefined){
                    for (let i = 0; i < cur_data.length; i++) {
                        if(cur_data[i]["value"]===currentNode.value){
                            for (let j = 0; j < cur_data[i]["children"].length; j++) {
                                // eslint-disable-next-line
                                if(cur_data[i]["children"][j].checked!==undefined){
                                    // eslint-disable-next-line
                                    cur_data[i]["children"][j].checked = cur_data[i]["children"][j].checked;
                                }else {
                                    cur_data[i]["children"][j].checked = false;
                                }
                            }
                        
                        }
                    }
                }

             let obj = {
                "type":this.props.name,
                "id":currentNode["value"],
                "label":currentNode["label"],
                "attribute_ids":currentNode["attribute_ids"]
              }
              if(currentNode["checked"] === true){
                if(currentNode["value"]!==null){
                    selected_id_index.push(currentNode["value"]);
                    selected_id.push({'id':currentNode["value"],"type":this.props.name,'detail':obj});
                }
              }
              else {
                let temp = [];
                for (var i = 0; i < selected_id.length; i++) {
                    if(selected_id[i].id!==currentNode["value"]){
                        temp.push({'id':selected_id[i]["id"],
                        "type":selected_id[i]["type"],
                        'detail':selected_id[i]["detail"]});
                    }
                }
                selected_id = temp;
              }

            }else {
                let childindex = -1;
                index = cur_data.findIndex(function(item){
                    return item.value === currentNode["parent_data_set_id"];
                  });
                if(index > -1){
                  childindex = cur_data[index]["children"].findIndex(function(item){
                    return item.value === currentNode["value"];
                  });
                }
                if(childindex > -1){
                    cur_data[index]['children'][childindex]["checked"] = currentNode["checked"]
                }
                }   
        this.props.handleSelectedAttributes(cur_data);
        this.setState({renderDataset:false,selected_id:selected_id})
        } 
        }

        if(this.props.name==="attributes"){
            
        if (currentNode.value === "*") {
            this.setState({renderDataset:false})
            this.toggleAll(currentNode);
        } else {
            let cur_data = this.props.data;
            if(currentNode["_depth"]===0){
                let index = cur_data.findIndex(function(item){
                    return item.value === currentNode["value"] && item.dataset_id === currentNode["dataset_id"];
                });
                if(index > -1){
                    cur_data[index]["checked"] = currentNode["checked"]
                    if (cur_data[index]["children"] !== undefined) {
                        for (let j = 0; j < cur_data[index]["children"].length; j++) {
                            cur_data[index]["children"][j].checked = currentNode.checked;
                        }
                    }
                }
            }
            else if(currentNode["_depth"]===1){
            let index = cur_data.findIndex(function(item){
                return  item.value === currentNode["dataset_id"];
            });

            cur_data = cur_data.map((data)=> {
                if(data.value === currentNode.data_set_id && currentNode["checked"] === false){
                    let checkAll = false
                    data.checked = checkAll
                    return data
                }

                else if(data.value === currentNode.data_set_id && currentNode["checked"] === true){
                
                    let checkedAll = false
                    if(data.children !== undefined){
                        let childAtt = data.children

                        let CheckedList = childAtt.filter((childData)=> childData.checked !== false)
                        
                        
                        if(data.children.length === CheckedList.length+1 ){
                            /** Length -1 is included because the current data have the selecting attribute value as false
                             *  As of now handled with -1 login further we can to check the logic
                             */
                            checkedAll = true
                        }
                        else {
                        }
                    }
                    data.checked = checkedAll
                    return data
                }
                else{
                    return data
                }
            })

            if(cur_data[index]["children"]!==undefined){
                let childindex = -1;
                if(index > -1){
                    childindex = cur_data[index]["children"].findIndex(function(item){
                    return item.value === currentNode["value"];
                });
                }
                if(childindex > -1){
                    cur_data[index]['children'][childindex]["checked"] = currentNode["checked"]
                }
            }
            }
            this.props.handleAttributesSelection(cur_data)
            return;

        }
        }

    }
    onAction = (node, action) => {
    

    }

    toggleAll = (node) => {

        if(this.props.name==="metrics"){
            let data = this.props.data;
            for (let i = 0; i < data.length; i++) {
                data[i].checked = node.checked;
            }
            this.props.handleSelectedAttributes(data);
            return data;
        }
        if(this.props.name==="data_set"){
            let data;
            if(this.props.data!==""){
            data = this.props.data.filter((data)=> 
                parseInt(data.integration_id) === parseInt(this.props.seletedDatasource) || data.value === "*"
            ) 
            if(node.is_child_dataset === true){
            for (let i = 0; i < data.length; i++) {

                if(data[i]["value"]===node.parent_data_set_id || data[i]["value"] === "*"){
                    if(data[i]["value"] === "*"){
                        data[i].checked = node.checked;
                    }else {
                    for (let j = 0; j < data[i]["children"].length; j++) {
                        data[i]["children"][j].checked = node.checked;
                    }}
                }
            }
            }else {
                for (let i = 0; i < data.length; i++) {
                    data[i].checked = node.checked;
                    if(data[i]["children"]!==undefined){
                        for (let j = 0; j < data[i]["children"].length; j++) {
                            data[i]["children"][j].checked = node.checked;
                        }
                    }
                }
            }
            this.setState({renderDataset:true,data:data})
            }
            this.props.handleSelectedAttributes(data);
            return data;
        
        }

        /*
        if(this.props.name==="attributes"){
            let data=this.props.data;
    if(node.value==="*" && node.dataset_id===undefined){
        for (let i = 0; i < data.length; i++) {
            if(data[i]["children"]===undefined) {
                data[i].checked = node.checked;
            }else{
                data[i].checked = node.checked;
                for (let j = 0; j < data[i]["children"].length; j++) {
                     data[i]["children"][j].checked = node.checked;
                }
            }
        }
    }
    else if(node.value==="*" && node.dataset_id > 0){
        for (let i = 0; i < data.length; i++) {

            if(data[i]["children"]===undefined && parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                if(parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id))
                {
                    data[i].checked = node.checked;
                }            }else{
                if(data[i]["children"]!==undefined  ){
                    data[i].checked = node.checked;
                    for (let j = 0; j < data[i]["children"].length; j++) {
                             data[i]["children"][j].checked = node.checked;
                    }
                }
                else {
                    if(parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id))
                    {
                        data[i].checked = node.checked;
                    }
                }
            }
        }
    }
    this.props.handleAttributesSelection(data)
        }*/
        if(this.props.name === "attributes"){
            let data = this.props.data;
            if (node.value === "*" && node.dataset_id === undefined) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i]["children"] === undefined) {
                        data[i].checked = node.checked;
                    } else {
                        data[i].checked = node.checked;
                        for (let j = 0; j < data[i]["children"].length; j++) {
                            data[i]["children"][j].checked = node.checked;
                        }
                    }
                }
            }
            else if (node.value === "*" && node.dataset_id > 0) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i]["children"] === undefined && parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                        if (parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                            data[i].checked = node.checked;
                        }
                    } else {
                        if (data[i]["children"] !== undefined && parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                            data[i].checked = node.checked;
                            for (let j = 0; j < data[i]["children"].length; j++) {
                                data[i]["children"][j].checked = node.checked;
                            }
                        }
                        else {
                            if (parseInt(data[i]["dataset_id"]) === parseInt(node.dataset_id)) {
                                data[i].checked = node.checked;
                            }
                        }
                    }
                }
            }
            this.props.handleAttributesSelection(data)
        }
    };

    onNodeToggle = currentNode => {
        let index = -1;
        if(this.props.name==="data_set"){
        let cur_data = this.props.data;
        index = cur_data.findIndex(function(item){
                return item.value === currentNode["value"];
              });
            if(index > -1){
                cur_data[index]["expanded"] = currentNode["expanded"]
            }
            this.setState({cur_data:cur_data});
            this.props.handleExpand(cur_data);
        }
        if(this.props.name==="attributes"){
            let cur_data = this.props.data;
            index = cur_data.findIndex(function (item) {
                return item.value === currentNode["value"];
            });
            if (index > -1) {
                cur_data[index]["expanded"] = currentNode["expanded"]
            }
            this.setState({ cur_data: cur_data });
            this.props.handleAttributesSelection(cur_data);       
        }
    }

    render() {
        let cur_data = [];
        let checkall = 0;
        let selectplaceholdercnt = 0;
        if (this.props.data !== undefined && this.props.name === "attributes") {
            if (this.props.data !== undefined) {
                cur_data = this.props.data;
                for (let i = 0; i < cur_data.length; i++) {
                    if (cur_data[i].checked === true && cur_data[i].value !== "*" && cur_data[i].value !== undefined && cur_data[i].children === undefined) {
                        selectplaceholdercnt++;
                    }
                    /** Handle un-checking of all option under dataset */
                    if (cur_data[i].value === "*" && cur_data[i].dataset_id !== undefined) {
                        let currentData = cur_data
                        let data = currentData.filter((data) => data.dataset_id === currentData[i].dataset_id && data.checked !== true)
                        if (data.length > 0) {
                            cur_data[i].checked = false
                        }
                        if (data.length === 1 && data[0].value === "*") {
                            cur_data[i].checked = true
                        }
                    }
                    if (cur_data[i]["children"] !== undefined) {
                        for (let j = 0; j < cur_data[i]["children"].length; j++) {
                            if (cur_data[i]["children"][j].value !== "*" && cur_data[i]["children"][j].checked === true && cur_data[i].value !== "*" && cur_data[i].value !== undefined) {
                                if (cur_data[i]["children"][j].value !== "*") {
                                    selectplaceholdercnt++;
                                }
                            }
                        }
                    }
                }
            }
            checkall = cur_data.filter((data) => data.checked !== true && data.value !== undefined);
            if (cur_data.length !== 1 && checkall.length === 1 && checkall[0].value === "*") {
                cur_data[0].checked = true
            }
            else {
                cur_data[0].checked = false
            }

        }
        if (this.props.data !== undefined && this.props.name === "data_set") {
            if (this.props.data !== "") {
                cur_data = this.props.data.filter((data) =>
                    parseInt(data.integration_id) === parseInt(this.props.seletedDatasource) || data.integration_id === "-1"
                )
                if (cur_data !== "") {
                    checkall = cur_data.filter((data) =>
                        data.checked === true && data.value !== "*"
                    ).length;

                }

                if (checkall + 1 !== cur_data.length) {
                    cur_data[0]["checked"] = "";
                } else {
                    if (cur_data.length > 0) {
                        if (checkall === cur_data.length + 1) {
                            cur_data[0]["checked"] = true;
                        }
                    }
                }
                for (let i = 0; i < cur_data.length; i++) {
                    if (cur_data[i].checked === true && cur_data[i].value !== "*") {
                        //&& String(cur_data[i].value).includes(")") === false
                        selectplaceholdercnt++;
                    }
                    if (cur_data[i]["children"] !== undefined) {
                        for (let j = 0; j < cur_data[i]["children"].length; j++) {
                            if (cur_data[i]["children"][j].checked === true && cur_data[i]["children"][j].value !== "*") {
                                //&& String(cur_data[i].value).includes(")") === false
                                selectplaceholdercnt++;
                            }
                        }
                    }
                }
            }
            // let checkedAll = cur_data.filter((data) =>data.integration_id !== "-1" &&  data.checked !== true ).length;
            let checkedAll = [];
            cur_data.map((data) => {
                if (data.integration_id !== "-1" && data.checked !== true) {
                    checkedAll.push(false)
                }
                if (data.children !== undefined) {
                    let childAtt = data.children.filter((childData) => childData.parent_data_set_id === data.value && childData.value !== "*" && (childData.checked === undefined || childData.checked !== true))//.length
                    if (childAtt.length === 0) {
                        data.children[0].checked = true
                    }
                    else {
                        data.children[0].checked = false
                        checkedAll.push(false)
                    }
                }
                return null
            }
            )

            if (checkedAll.includes(false)) {
                cur_data[0].checked = false
            }
            else {
                cur_data[0].checked = true
            }
        }

        if (this.props.data !== undefined && this.props.name === "metrics") {
            let _data = this.props.data;
            if (this.props.selectedGroup !== undefined && this.props.selectedGroup.label === 'Attribute Only') {
                cur_data = _data.filter((data) =>
                    data.label !== 'Row Count' && data.label !== 'Recency'
                )
                //  cur_data = _data
            }
            else {
                cur_data = _data
            }

            let checkall = cur_data.filter((data) =>
                data.value !== "*"
            ).length;

            for (let i = 0; i < cur_data.length; i++) {
                if (cur_data[i].checked === true && cur_data[i].value !== "*") {
                    selectplaceholdercnt++;
                }
            }
            if (checkall === selectplaceholdercnt) {
                if (selectplaceholdercnt > 0) {
                    cur_data[0]["checked"] = true;
                }
            } else {
                if (selectplaceholdercnt > 0) {
                    cur_data[0]["checked"] = "";
                }
            }
        }
        return (
            <div className="react-dropdown-tree">
                <div className="react-dropdown-tree-prepend">
                  <FontAwesomeIcon icon={this.props.prependIcon} />
                </div>
                <input  style={{display:'none'}}
                type="button" 
                className='trigger-click'
                data-type={this.props.fullscreenviewselect === true ? "fullscreen" : "parentview"}
                id={this.props.fullscreenviewselect === true ? "fullscreen_"+this.props.name : "parentview_"+this.props.name}
                onClick={this.loadFilterDataset}></input>
                
                <DropdownTreeSelect
                    id={this.props.id}
                    className="bootstrap-demo"
                    data={cur_data}
                    onChange={this.onChange}
                    onAction={this.onAction} 
                    onNodeToggle={this.onNodeToggle}
                    onFocus={this.onFocus}
                    texts={{ placeholder: selectplaceholdercnt === 0 ? this.props.placeholder : selectplaceholdercnt+" selected", noMatches: "No Options" }}
                />
            </div>
        );
    }
}
export default CustomTreeSelect;