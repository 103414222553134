import React from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import $ from 'jquery';


class DiscoverForm extends React.Component {
    constructor(props) {
        super(props);
        this.changeEnvironment = this.changeEnvironment.bind(this);
        this.changeDataSource = this.changeDataSource.bind(this);
        this.changeDataset = this.changeDataset.bind(this);
        this.changeAttribute = this.changeAttribute.bind(this);
        let environmentOptions = []
        let datasourceOptions = []
        let datasetOptions = []
        let attributeOptions = []
        let preSelectedDatasource = this.props.preSelectedDatasource
        let preSelectedEnv = this.props.preSelectedEnv
        let preSelectedDataset = this.props.preSelectedDataset
        let preSelectedAttribute = this.props.preSelectedAttribute
        let envId;
        let datasourceId;
        let datasetId;

        for(let [key, value] of Object.entries(this.props.nestedData)){
           environmentOptions.push({"label": value["title"], "value": key});
        }
         let environment = this.props.envCount
         if(environment === null) {
            let filteredEnvironment = this.props.filterResultData.filter(value => {
                return value.type === "Environment";
            });
            environment = filteredEnvironment.length
         }else if(preSelectedEnv){
              envId = preSelectedEnv["value"]
              let dataSources = this.props.nestedData[envId]["childNodes"]
              for(let [key, value] of Object.entries(dataSources)){
                datasourceOptions.push({"label": value["title"], "value": key});
             }
         }
         let datasource = this.props.datasourceCount
         if(datasource === null){
            let filterDatasource = this.props.filterResultData.filter(value => {
                return value.type === "Datasource";
            });
            datasource = filterDatasource.length
         }else if(preSelectedDatasource) {
              datasourceId = preSelectedDatasource["value"]
              let datasets = this.props.nestedData[envId]["childNodes"][datasourceId]["childNodes"]
              for(let [key, value] of Object.entries(datasets)){
                datasetOptions.push({"label": value["title"], "value": key});
              }
         }
         let dataset = this.props.datasetCount
         if(dataset === null){
            let filteredDataset = this.props.filterResultData.filter(value => {
                return value.type === "Dataset";
           });
           dataset = filteredDataset.length

         }else if(preSelectedDataset){
             datasetId = preSelectedDataset["value"]
             let attributes = this.props.nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]["childNodes"]
             for(let [key, value] of Object.entries(attributes)){
                attributeOptions.push({"label": value["title"], "value": key});
              }
         }
         let attribute = this.props.attributeCount
         if(attribute === null) {
            let filteredAttribute = this.props.filterResultData.filter(value => {
                return value.type === "Attribute";
              });
              attribute = filteredAttribute.length
         }


        this.state={
            environmentOptions:environmentOptions,
            datasetOptions: datasetOptions,
            datasourceOptions: datasourceOptions,
            attributeOptions: attributeOptions,
            selectedDatasource: preSelectedDatasource,
            selectedDataset: preSelectedDataset,
            selectedAttribute: preSelectedAttribute,
            selectedEnv: preSelectedEnv,
            totalenvironment:environment,
            totaldataset:dataset,
            totaldatasource:datasource,
            totalattibute:attribute
        }
    }

    changeEnvironment(event) {
         let envId = event.value;
         let dataSources = this.props.nestedData[envId]

         let datasourceOptions = []
         if(dataSources !== undefined){
           dataSources = dataSources["childNodes"]
           for(let [key, value] of Object.entries(dataSources)){
                datasourceOptions.push({"label": value["title"], "value": key});
           }
           this.props.applyFilter("Environment", event);



           let datasource = this.props.filterResultData.filter(value => {
             return value.type === "Datasource" && value.envId.toLowerCase().slice(0, envId) === envId;
           });
           let dataset = this.props.filterResultData.filter(value => {
             return value.type === "Dataset" && value.envId.toLowerCase().slice(0, envId) === envId;
           });
           let attribute = this.props.filterResultData.filter(value => {
             return value.type === "Attribute" && value.envId.toLowerCase().slice(0, envId) === envId;
           });
           this.props.changeCount(1, datasource.length, dataset.length, attribute.length)

           this.setState({datasourceOptions: datasourceOptions,
                          selectedDatasource: null,
                          selectedDataset: null,
                          selectedAttribute: null,
                          datasetOptions: [],
                          attributeOptions: [],
                          selectedEnv: event,
                          totalenvironment: 1,
                          totaldataset:dataset.length,
                          totaldatasource:datasource.length,
                          totalattibute:attribute.length
                        });
         }
         $("#filtericon").hide();
         $(".filter-count").html(1);
         $(".filter-count").show();
         $("#btn-filter").addClass("active");
    }
    changeDataSource(event) {
          let envId = this.state.selectedEnv.value;
          let datasourceId = event.value;
          let datasetOptions = []
          let datasets = this.props.nestedData[envId]["childNodes"][datasourceId]
          if(datasets !== undefined) {
             datasets = datasets["childNodes"]
             for(let [key, value] of Object.entries(datasets)){
                datasetOptions.push({"label": value["title"], "value": key});
             }
             this.props.applyFilter("Datasource", this.state.selectedEnv, event)
             
          let dataset = this.props.filterResultData.filter(value => {
            return value.type === "Dataset" && value.datasourceId.toLowerCase().slice(0, datasourceId) === datasourceId;
          });
          let attribute = this.props.filterResultData.filter(value => {
            return value.type === "Attribute" && value.datasourceId.toLowerCase().slice(0, datasourceId) === datasourceId;
          });

             this.props.changeCount(1, 1, dataset.length, attribute.length)
             this.setState({datasetOptions: datasetOptions,
                            selectedDatasource: event,
                            selectedDataset: null,
                            attributeOptions: [],
                            selectedAttribute: null,
                            totaldataset:dataset.length,
                            totaldatasource: 1,
                            totalattibute:attribute.length
                        })
          }
          $(".filter-count").html(2);

    }
    changeDataset(event) {
        
          let envId = this.state.selectedEnv.value;
          let datasourceId = this.state.selectedDatasource.value;
          let datasetId = event.value;
          let attributeOptions = []
          let datasets = this.props.nestedData[envId]["childNodes"][datasourceId]["childNodes"][datasetId]
          if(datasets !== undefined) {
             datasets = datasets["childNodes"]
             for(let [key, value] of Object.entries(datasets)){
                attributeOptions.push({"label": value["title"], "value": key});
             }
             this.props.applyFilter("Dataset", this.state.selectedEnv, this.state.selectedDatasource, event)


              let attribute = this.props.filterResultData.filter(value => {
                return value.type === "Attribute" && value.datasetId.toLowerCase().slice(0, datasetId) === datasetId;
              });
              this.props.changeCount(1, 1, 1, attribute.length)

             this.setState({attributeOptions: attributeOptions,
                            selectedDataset: event,
                            selectedAttribute: null,
                            totaldataset:1,
                            totalattibute:attribute.length
                        })
          }
          $(".filter-count").html(3);

    }
    changeAttribute(event) {
         this.props.applyFilter("Attribute", this.state.selectedEnv, this.state.selectedDatasource, this.state.selectedDataset, event)
         this.setState({selectedAttribute: event, totalattibute:1});
         this.props.changeCount(1, 1, 1, 1)
         $(".filter-count").html(4);
         this.props.closeAdvancedFilter();
    }

   
    render() {
       return(<><div className="qd_search-filter-form">
                    <Form className="form-label-right-md" data-select2-id="28">
                    <h4 className="qd_autosuggest-title">Advanced search</h4>
                    <button onClick={this.props.closeAdvancedFilter} type="button" className="btn btn-link">Close</button>
                    <div className="row row-sm" data-select2-id="27">
                        <Form.Group controlId="environment" className="col-6">
                            <Form.Label>Environment :</Form.Label>
                            <Select name="environment"
                                    filterOption={({label}, query) => label.includes(query)}
                                    id="environment"
                                    options={this.state.environmentOptions}
                                    onChange={this.changeEnvironment}
                                    placeholder="Select Environment"
                                    classNamePrefix='select-control'
                                    value={this.state.selectedEnv}
                            />
                        </Form.Group>
                        <Form.Group controlId="datasource" className="col-6">
                            <Form.Label>Datasource :</Form.Label>
                            <Select name="datasource"
                                    filterOption={({label}, query) => label.includes(query)}
                                    id="datasource"
                                    options={this.state.datasourceOptions}
                                    onChange={this.changeDataSource}
                                    placeholder="Select Datasource"
                                    classNamePrefix='select-control'
                                    value={this.state.selectedDatasource}
                            />
                        </Form.Group>
                        <Form.Group controlId="dataset" className="col-6">
                            <Form.Label>Dataset :</Form.Label>
                            <Select name="dataset"
                                    filterOption={({label}, query) => label.includes(query)}
                                    id="dataset"
                                    options={this.state.datasetOptions}
                                    onChange={this.changeDataset}
                                    placeholder="Select Dataset"
                                    classNamePrefix='select-control'
                                    value={this.state.selectedDataset}
                            />
                        </Form.Group>
                        <Form.Group controlId="attribute" className="col-6">
                            <Form.Label>Attributes :</Form.Label>
                            <Select name="attribute"
                                    filterOption={({label}, query) => label.includes(query)}
                                    id="attribute"
                                    onChange={this.changeAttribute}
                                    options={this.state.attributeOptions}
                                    placeholder="Select Attributes"
                                    classNamePrefix='select-control'
                                    value={this.state.selectedAttribute}
                            />
                        </Form.Group>
                    </div>
                    </Form>
                    <div className="qd_search-rst-footer">
                        <ol className="qd_tab-separated-block">
                        <li className="tab-separated-value"><span><strong>{this.state.totalenvironment}</strong> Environment</span></li>
                        <li className="tab-separated-value"><span><strong>{this.state.totaldatasource}</strong> Datasource</span></li>
                        <li className="tab-separated-value"><span><strong>{this.state.totaldataset}</strong> Dataset</span></li>
                        <li className="tab-separated-value"><span><strong>{this.state.totalattibute}</strong> Attributes</span></li>
                        </ol></div>
                        </div></>);




    }
}

export default DiscoverForm;
















