import React from 'react';
import Toast from 'react-bootstrap/Toast';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import BasicPortlet from '../components/basicPortlet';
import QualdoDataTable from '../components/bootstrapTable';
import URLWidget from './components/urlWidget';
import { getApi, postUIlogs, prepare_model_ab_data_for_a_config, get_api_call_cnt, get_api_call_complete_cnt, post_api_call_cnt, post_api_call_complete_cnt } from "../utils/event_handling";
import Nav from 'react-bootstrap/Nav';
import { CopyRightsFooter } from '../components/copyrights';
import MLModelEnvironment from './mlmodel/environment';
import AddModel from './mlmodel/addModel';
import DSEnvironment from './dataSources/dsEnvironment';
import AddDataSources from './dataSources/addDataSources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { formModelVersionMap, getFeatureAccess, toastObj } from "../utils/common_utils";

import Load from '../components/loadAction';
import ErrorHandler from '../components/error_500';
import RefreshesTable from "../components/refreshesTable";
import { connect } from "react-redux";
import { addMLModelMapping, setMLModel, setModelABDataToList } from "../redux/actions";

import ConfigureABTest from "./mlmodel/configureABTest";
import { ModelABEdit } from "./mlmodel/modelABEdit";
import {
    AB_MODELS_TABLE_TITLE, IS_NONE_CHECK,
    MAX_SLEEP_TIME, MAX_SLEEP_ERROR, NEG_SLEEP_ERROR, IS_NONE_SAAS_EDITION,
    // DQ_MONITOR_SECTIONS
} from "../utils/constant";
import { postMixpanel } from '../mixpanel';
import store from "../redux/store";
import Form from 'react-bootstrap/Form';
import RcTooltip from 'rc-tooltip';
import Button from "react-bootstrap/Button";
import {
    // preFetchRelationshipData,
    preFetchMetaData, 
    preFetchUserNotifications, prefetchDatasetDetails, prefetchRecencyDetails, prefetchRecentHistory,
    // prefetchModelABData, 
    prefetchDataSize, preFetchMetadataDetails
} from "../utils/dataFetch";

import {
    // preFetchMonitorPreview,
    // preFetchMonitorInfo,
    // preFetchMonitorModelInfo,
    // preFetchModelDetails,
    // preFetchMonitorListViewData,
    preFetchMetrics,
    // preFetchFollowAttributes,
    // fromMonitorData,
    getLastProfilingTime
} from "../utils/monitorDataFetch";
import {
    setDataSource,
    setMlDataSource,
    addRelationship,
    addMetaData,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setEndDate,
    setStartDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setAttribute,
    setDataSet,
    addMonitorPreview,
    addPerformancePreview,
    addCompletenessDataSetInfo,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    addModelPerformance,
    addModelDetails,
    // addFollowAttributes,
    addMonitorDQListViewData,
    addMetrics,
    addUserNotificationToState,
    addDatasetVersions,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyDetails,
    addRecentHistory,
    addDataSize,
    addRecencyPreview,
    prefetchMonitorState,
    noIntegration,
    storeMetadataDetails,
    mappedDatasetInfo,
    listViewTableData
} from "../redux/actions";
import NoErrorInPreview from "../monitor/components/noErrorPreview";

// import {
//     extractDatasetInfoForMonitorListView,
//     getMonitorTabDQPListViewTableData,
//     setDQShowMoreData
// } from "../utils/common_utils";

// let cur_data = null;
// let cur_datasource_updates=null;
// let cur_isAddDS = false;
// let pre_load = true;

class Configure extends React.Component {
    constructor(props) {
        super(props);
        let tab = "ds"
        if (this.props.route.navKey !== undefined && this.props.route.navKey !== null) {
            tab = this.props.route.navKey
        }
        this.pre_load = true;
        this.formDataOnce = 1;
        this.getApi = getApi.bind(this);
        this.setAddModel = this.setAddModel.bind(this);
        this.setModelVersion = this.setModelVersion.bind(this);
        this.setAddDS = this.setAddDS.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.setDataLimit = this.setDataLimit.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.getExistingABData = this.getExistingABData.bind(this);
        this.handleModelABAddition = this.handleModelABAddition.bind(this);
        this.modelHasModelABConfiguration = this.modelHasModelABConfiguration.bind(this);
        this.handleModelABDelete = this.handleModelABDelete.bind(this);
        this.showModelABConfigureSection = this.showModelABConfigureSection.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.updateEnvironmentList = this.updateEnvironmentList.bind(this);
        this.setEnvData = this.setEnvData.bind(this)
        this.renderRefreshTable = this.renderRefreshTable.bind(this)
        // this.preFetchMonitorGraph = this.preFetchMonitorGraph.bind(this);

        this.sleepIntervalChanged = this.sleepIntervalChanged.bind(this);
        this.cancelSleepInterval = this.cancelSleepInterval.bind(this);
        this.submitSleepInterval = this.submitSleepInterval.bind(this);
        this.renderScanIntervalComponents = this.renderScanIntervalComponents.bind(this);
        this.renderScanIntervalContent = this.renderScanIntervalContent.bind(this);

        // State variable => model_ab_data contains data for table rendering,
        // State variable => currModelABList contains AB data that is fetched from API
        this.state = {
            errorOccurred: false,
            toastStatus: false,
            current_tab: tab,
            featureAccess: getFeatureAccess(),
            data: null,
            integration_details: null,
            integration_option: null,
            mlDetails: null,
            mlData: null,
            mlLimitExceed: null,
            dataLimitExceed: null,
            isAddModel: false,
            isModelVersion: false,
            isAddDS: false,
            planName: null,
            environment: null,
            datasets: [],
            attributes: [],
            maxModel: null,
            datasource_updates: null,
            model_ab_data: null,
            currModelABList: [],
            model_ab_updated: false,
            model_updates: null,
            show: false,
            change_request_exists: false,
            env_id: null,
            datasourceErrorRefreshesId: "datasourceErrorRefreshes",
            modelRefreshesId: "modelRefreshes",
            intervalId: '',
            sleepComponentLoad: true,
            sleepInterval: 1,
            prevSleepInterval: 1,
            sleepIntervalClass: "d-none",
            isSleepIntervalErrMsg: "",
            updateStatus:false,
        }
    }

    sleepIntervalChanged(event) {
        let currInterval = event.target.value;
        let isSleepIntervalMaxErr = currInterval >= MAX_SLEEP_TIME;
        let sleepIntervalClass = Number(currInterval) === Number(this.state.prevSleepInterval) ? "d-none" : "";
        let errorMsg = "";
        if (isSleepIntervalMaxErr) {
            errorMsg = MAX_SLEEP_ERROR;
        } else if (Number(currInterval) <= 0) {
            errorMsg = NEG_SLEEP_ERROR;
        }
        if (errorMsg.length > 0) {
            sleepIntervalClass = "d-none";
        }
        this.setState({
            sleepInterval: currInterval,
            isSleepIntervalErrMsg: errorMsg,
            sleepIntervalClass: sleepIntervalClass
        });
    }
    submitSleepInterval() {
        this.getApi("save_dd_sleep_time");
    }
    cancelSleepInterval() {
        let prevValue = Number(this.state.prevSleepInterval);
        this.setState({
            sleepInterval: prevValue,
            sleepIntervalClass: "d-none"
        });
    }

    setEnvData(data) {
        this.setState({ env_id: data })
    }

    getExistingABData() {
        return this.state.currModelABList;
    }

    modelHasModelABConfiguration(model_id) {
        const modelABList = this.state.currModelABList;
        let hasModelABConfiguration = false;
        for (let currentAB of modelABList) {
            if (currentAB.model_1 === model_id) {
                hasModelABConfiguration = true;
                break;
            }
            if (currentAB.model_2 === model_id) {
                hasModelABConfiguration = true;
                break;
            }
            if (currentAB.model_3 === model_id) {
                hasModelABConfiguration = true;
                break;
            }
        }

        return hasModelABConfiguration;
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    handleModelABAddition(model_ab_data, model_info_map, isEdit) {
        let modelAbData = this.state.model_ab_data;
        let modelABList = this.state.currModelABList;

        let newData = [];
        if (isEdit) {
            let updatedABList = [];
            for (const currAB of modelABList) {
                if (currAB.model_ab_id !== model_ab_data.model_ab_id) {
                    updatedABList.push(currAB);
                    continue;
                }

                updatedABList.push(model_ab_data);
            }
            modelABList = updatedABList;

            // Update table row data
            let prevList = modelAbData["data"];
            for (const currRowData of prevList) {
                if (currRowData[6].model_ab_id !== model_ab_data.model_ab_id) {
                    newData.push(currRowData);
                    continue;
                }

                let abRowData = prepare_model_ab_data_for_a_config(model_ab_data, model_info_map);
                if (IS_NONE_CHECK.includes(abRowData)) {
                    continue;
                }

                newData.push(abRowData);
            }

        } else {
            // Adding the newly added model AB configuration to row data and state variable
            let abRowData = prepare_model_ab_data_for_a_config(model_ab_data, model_info_map)
            if (IS_NONE_CHECK.includes(abRowData)) {
                return;
            }

            modelABList.push(model_ab_data);

            let prevList = modelAbData["data"];
            newData = [abRowData, ...prevList];
        }

        modelAbData["data"] = newData;

        // Set the information to dataModule state
        this.props.setModelABDataToList(modelABList, model_info_map);

        // Setting information to Configure state
        this.setState({
            model_ab_data: modelAbData,
            currModelABList: modelABList,
            model_ab_updated: !this.state.model_ab_updated
        });
    }

    handleModelABDelete(model_ab_id) {
        let prevABData = this.state.model_ab_data;
        let modelABList = this.state.currModelABList.filter(x => String(x.model_ab_id) !== String(model_ab_id));

        // Updates the state variable for model AB table data so it will be removed from ui
        let newList = [];
        for (const rowData of prevABData["data"]) {
            const viewData = rowData[6];
            let currModel_ab_id = viewData["model_ab_id"];
            if (currModel_ab_id === model_ab_id) {
                // Skip the deleted Model AB id
                continue;
            }

            newList.push(rowData);
        }

        prevABData["data"] = newList;

        // Set the information to dataModule state
        this.props.setModelABDataToList(modelABList);

        this.setState({
            model_ab_data: prevABData,
            currModelABList: modelABList,
            model_ab_updated: !this.state.model_ab_updated
        });
    }

    componentDidMount() {
        this.getData()
        this.sectionNavigation();


        let prefetch = (Object.keys(this.props.dataModule.environmentDataSourceMapping).length !==
            Object.keys(this.props.dataModule.environments).length) ||
            (Object.keys(this.props.dataModule.integrations).length !==
                Object.keys(this.props.dataModule.relationship).length)
        let initial_prefetch = Object.keys(this.props.dataModule.environmentDataSourceMapping).length === 0 &&
            Object.keys(this.props.dataModule.environments).length === 0 &&
            Object.keys(this.props.dataModule.integrations).length === 0 &&
            Object.keys(this.props.dataModule.relationship).length === 0
        if (prefetch === true || initial_prefetch === true) {
            this.preFetch();
            // var intervalId = setInterval(this.preFetchMonitorGraph, 1000);
            // // store intervalId in the state so it can be accessed later:
            // this.setState({
            //     intervalId: intervalId
            // });
        }
        // this.preFetch();

    }


    /**Optimization changes */

    // preFetchMonitorGraph() {
    //     let teamID = localStorage.getItem('team_id')
    //     let tenantData = { "tenant_id": teamID };
    //     if (Object.keys(this.props.dataModule.integrations).length > 0) {
    //         clearInterval(this.state.intervalId);
    //         for (const key of Object.keys(this.props.dataModule.integrations)) {
    //             if (!(key in this.props.monitorModule.fetchInfo && this.props.monitorModule.fetchInfo[key])) {
    //                 preFetchMonitorInfo(
    //                     tenantData, this.props.addCompletenessDataSetInfo, this.props.addCompletenessAttributeInfo,
    //                     this.props.addTimelinessDataSetInfo, this.props.addTimelinessAttributeInfo,
    //                     this.props.addAccuracyDataSetInfo, this.props.addAccuracyAttributeInfo,
    //                     this.props.addConformityDataSetInfo, this.props.addConformityAttributeInfo,
    //                     this.props.addConsistencyDataSetInfo, this.props.addConsistencyAttributeInfo,
    //                     this.props.addDriftDataSetInfo, this.props.addDriftAttributeInfo, this.props.addMLDataSetInfo,
    //                     this.props.addMLAttributeInfo, this.props.addMonitorPreview, this.props.addUniquenessDataSetInfo,
    //                     this.props.addUniquenessAttributeInfo, this.props.addRecencyPreview,
    //                     this.props.prefetchMonitorState
    //                 );
    //             }
    //         }
    //     }
    // }

    // formData() {
    //     if (this.props.dataModule.metaData !== undefined) {
    //         const metaDataMapping = this.props.dataModule.metaData;
    //         const monitorDQListViewData = this.props.monitorModule.monitorDQListView;
    //         const timelinessShowMore = setDQShowMoreData(this.props.monitorModule.timelinessDataSetInfo,
    //             this.props.monitorModule.timelinessAttributeInfo, this.props.dataModule,
    //             null, DQ_MONITOR_SECTIONS.TIMELINESS);

    //         const mappedDatasetInfos = extractDatasetInfoForMonitorListView(metaDataMapping);
    //         const listViewTableDatas = getMonitorTabDQPListViewTableData(mappedDatasetInfos, monitorDQListViewData, this.props.monitorModule, timelinessShowMore);
    //         fromMonitorData(this.props.mappedDatasetInfo, this.props.listViewTableData, mappedDatasetInfos, listViewTableDatas, store)
    //         this.formDataOnce = 0
    //     }
    // }

    // preFetchMonitor() {
    //     let teamID = localStorage.getItem('team_id')
    //     let tenantData = { "tenant_id": teamID };

    //     // Monitor Prefetch
    //     // Prefetch happens for every Integrations 
    //     // Gets the result and iterate for all integrations
    //     if (!this.props.dataModule.integrations) {
    //         for (const key of Object.keys(this.props.dataModule.integrations)) {
    //             if (!(key in this.props.monitorModule.fetchInfo && this.props.monitorModule.fetchInfo[key])) {
    //                 preFetchMonitorInfo(
    //                     tenantData, this.props.addCompletenessDataSetInfo, this.props.addCompletenessAttributeInfo,
    //                     this.props.addTimelinessDataSetInfo, this.props.addTimelinessAttributeInfo,
    //                     this.props.addAccuracyDataSetInfo, this.props.addAccuracyAttributeInfo,
    //                     this.props.addConformityDataSetInfo, this.props.addConformityAttributeInfo,
    //                     this.props.addConsistencyDataSetInfo, this.props.addConsistencyAttributeInfo,
    //                     this.props.addDriftDataSetInfo, this.props.addDriftAttributeInfo, this.props.addMLDataSetInfo,
    //                     this.props.addMLAttributeInfo, this.props.addMonitorPreview, this.props.addUniquenessDataSetInfo,
    //                     this.props.addUniquenessAttributeInfo, this.props.addRecencyPreview,
    //                     this.props.prefetchMonitorState
    //                 );
    //             }
    //         }
    //     }

    // }

    // preFetchData() {
    //     let teamID = localStorage.getItem('team_id')
    //     let tenantData = { "tenant_id": teamID };

    //     // Data Prefetch
    //     // Single Api call and interating the result to get exact metadata values
    //     if (!this.props.dataModule.preFetchMetaData) {
    //         preFetchMetaData(
    //             tenantData, this.props.addMetaData, this.props.addEnvironment,
    //             this.props.addEnvironmentDataSourceMapping, this.props.addDataSourceDataSetMapping,
    //             this.props.addDataSetAttributeMapping, this.props.setDataSet, this.props.setAttribute,
    //             this.props.setDataSource, this.props.setMlDataSource, this.props.addRelationship, this.props.addDataSource,
    //             this.props.addInfo, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate, this.props.addDiscoveryStatus,
    //             this.props.addProcessingStatus, this.props.noIntegration
    //         );
    //     }
    // }

    /**Optimization changes Ends*/


    preFetch() {
        let teamID = localStorage.getItem('team_id')
        let tenantData = { "tenant_id": teamID };

        // Monitor Prefetch.
        // Single api call : /model_performance/results/info
        // if (!this.props.monitorModule.preFetchMonitorPreview) {
        //     preFetchMonitorPreview(tenantData, this.props.addMonitorPreview, this.props.addPerformancePreview,
        //         this.props.addModelPerformance);
        // }
        if (!this.props.monitorModule.lastProfilingTime){
            getLastProfilingTime(tenantData, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate,);
        }
        // // Data Prefetch
        // // Single Api call and interating the result to get exact metadata values
        if (!this.props.dataModule.preFetchMetaData) {
            preFetchMetaData(
                tenantData, this.props.addMetaData, this.props.addEnvironment,
                this.props.addEnvironmentDataSourceMapping, this.props.addDataSourceDataSetMapping,
                this.props.addDataSetAttributeMapping, this.props.setDataSet, this.props.setAttribute,
                this.props.setDataSource, this.props.setMlDataSource, this.props.addRelationship, this.props.addDataSource,
                this.props.addInfo, this.props.addLastProfilingTime, this.props.setStartDate, this.props.setEndDate, this.props.addDiscoveryStatus,
                this.props.addProcessingStatus, this.props.noIntegration
            );
        }
        // // Monitor Prefetch
        // // Prefetch happens for every Integrations 
        // // Gets the result and iterate for all integrations
        // if (!this.props.dataModule.integrations) {
            // for (const key of Object.keys(this.props.dataModule.integrations)) {
                    // if (!(key in this.props.monitorModule.fetchInfo && this.props.monitorModule.fetchInfo[key])) {
                    //     preFetchMonitorInfo(
                    //         tenantData, this.props.addCompletenessDataSetInfo, this.props.addCompletenessAttributeInfo,
                    //         this.props.addTimelinessDataSetInfo, this.props.addTimelinessAttributeInfo,
                    //         this.props.addAccuracyDataSetInfo, this.props.addAccuracyAttributeInfo,
                    //         this.props.addConformityDataSetInfo, this.props.addConformityAttributeInfo,
                    //         this.props.addConsistencyDataSetInfo, this.props.addConsistencyAttributeInfo,
                    //         this.props.addDriftDataSetInfo, this.props.addDriftAttributeInfo, this.props.addMLDataSetInfo,
                    //         this.props.addMLAttributeInfo, this.props.addMonitorPreview, this.props.addUniquenessDataSetInfo,
                    //         this.props.addUniquenessAttributeInfo, this.props.addRecencyPreview,
                    //         this.props.prefetchMonitorState
                    //     );
                    // }
            // }
        // }

        // // Monitor Model Prefetch : model/map
        // // store the value in redux
        // if (!this.props.monitorModule.preFetchMonitorModelInfo) {
        //     preFetchMonitorModelInfo(
        //         teamID, this.props.addMLModelMapping,
        //         this.props.addMLDataSetInfo, this.props.addMLAttributeInfo,
        //         this.props.addMLModel, this.props.setMLModel
        //     );
        // }

        // // ML Monitor prefetch : ml-models 
        // // Profile ML filter and ML tab
        // if (!this.props.monitorModule.preFetchModelDetails) {
        //     preFetchModelDetails(teamID, this.props.addModelDetails);
        // }

        // custom Metrics Prefetch used in custom metric page
        // Fetch and Store
        if (!this.props.monitorModule.preFetchMetrics) {
            preFetchMetrics(teamID, this.props.addMetrics);
        }

        //    Monitor Prefetch
        // Prefetch happens for every Integrations 
        // Gets the result and iterate for all integrations
        // if (!this.props.monitorModule.preFetchMonitorListViewData) {
        //     preFetchMonitorListViewData(teamID, this.props.addMonitorDQListViewData);
        // }

        //    Notification Prefetch: user_notification/unread
        //    Used in navigation header
        //    Fetch and store
        if (!this.props.dataModule.preFetchUserNotifications) {
            // Fetch first page result of user notifications
            preFetchUserNotifications(tenantData, addUserNotificationToState, store);
        }

        //  Data Prefetch: /datasets
        //  Used in charts discover, profile
        //  Fetch and store
        if (!this.props.dataModule.prefetchDatasetDetails) {// Fetch dataset version details
            prefetchDatasetDetails(tenantData, addDatasetVersions, store);
        }

        // Data Prefetch: recency/all 
        // Used in Discovery and profile
        // Fetch and store
        if (!this.props.dataModule.prefetchRecencyDetails) {// Fetch Recency Details
            prefetchRecencyDetails(tenantData, addRecencyDetails, store);
        }

        // Discover prefetch: recent_search
        // user in discover page    
        // fetch and store
        if (!this.props.dataModule.prefetchRecentHistory) { // Fetch recent search history - discover page
            prefetchRecentHistory(tenantData, addRecentHistory, store);
        }

        // Fetch and store datasize : /data_size
        // simple prefetch
        if (!this.props.dataModule.prefetchDataSize) {// Fetch data size - discover page
            prefetchDataSize(tenantData, addDataSize, store)
        }

        // Monitor Model Prefetch used in configure and monitor : model_ab_data
        // if (!this.props.dataModule.prefetchModelABData) { // Fetch Model AB configuration list
        //     prefetchModelABData(tenantData, setModelABDataToList, store);
        // }

        // Monitor Prefetch: follow_attributes
        // used in monitor page
        // fetch and store
        // if (!this.props.monitorModule.preFetchFollowAttributes) {
        //     preFetchFollowAttributes(tenantData, addFollowAttributes, store);
        // }

        //    Alert Prefetch : alert_applicable_metrics
        //    used on alert page
        if (!this.props.monitorModule.preFetchMetadataDetails) {
            preFetchMetadataDetails(tenantData, storeMetadataDetails, store)
        }
    }

    setDataLimit() {
        this.getApi("increaseDataLimit");
    }

    showModelABConfigureSection(tableRowProps, canShowABModal, closeModalFunction, handleModelABAddition) {
        return (
            <ConfigureABTest tableRowProps={tableRowProps}
                operation={"ab_add"}
                handleModelABAddition={handleModelABAddition}
                canShowABModal={canShowABModal}
                closeModalFunction={closeModalFunction}
            />
        );
    }

    getData() {
        this.getApi("accountDetails");
        this.getApi("environments");
        this.getApi("integrations");
        this.getApi("dataset");
        this.getApi("datasource_updates");

        // let teamID = localStorage.getItem('team_id')
        // let tenantData = { "tenant_id": teamID };

        let hideConfPageModelTab = false;
        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const confPageData = this.state.featureAccess["models"];
            hideConfPageModelTab = confPageData === false;
        }

        if (IS_NONE_SAAS_EDITION) {
            this.getApi("dd_sleep_time_fetch");
        }
        if (!hideConfPageModelTab) {
            // When models feature is disabled, no need to call model related apis.
            this.getApi("mlmodels");
            this.getApi("model_updates");
            this.getApi("modelABData");
        }
    }

    updateEnvironmentList(newEnvironmentData) {
        //Adds the newly added environment information to existing list
        const existingEnvironmentDetail = this.state.environment;
        if (existingEnvironmentDetail === undefined || existingEnvironmentDetail === null) {
            return;
        }

        if (newEnvironmentData === undefined || newEnvironmentData === null) {
            return;
        }

        let data = { "label": newEnvironmentData.env_name, "value": newEnvironmentData.env_id };
        existingEnvironmentDetail.push(data);
        this.setState({ environment: existingEnvironmentDetail });
    }

    setAddModel(value) {
        this.setState({ isAddModel: value });
        if (value) {
            this.setModelVersion(false);
        }
    }

    setModelVersion(value) {
        if (value) {
            this.setAddModel(false);
        }

        this.setState({ isModelVersion: value });
    }

    setAddDS(value) {
        this.setState({ isAddDS: value });
    }
    closeToast() {
        this.setState({ toastStatus: false });
    }

    updateTable() {
        this.setState({ updateStatus: false }, this.getApi("integrations"));
    }

    showToast(status, msg, link, bulk, reloadData = true) {
        if (reloadData) {
            this.getData();
        }

        this.setState({
            toastStatus: true,
            toastMsg: msg,
            toastClass: toastObj[status]["className"],
            toastIcon: toastObj[status]["icon"],
            toastLink: link,
            toastHeading: toastObj[status]["heading"],
            toastBulk: bulk
        });
    }

    getModelABTableComponent(actionType, headerValue, cell, tableProps) {
        // This is a call back function called from QualdoDataTable - convertData() function

        if (headerValue === "Model Name") {
            const names = cell;
            return (
                <>
                    <div className="abtested_cell abtested_start">
                        <span className="abtest_parent">A</span>
                        {names[0]}
                    </div>

                    <div className="abtested_cell abtested_end">
                        <span className="abtest_child">B</span>
                        {names[1]}
                    </div>

                    {names[2] === undefined ? '' :
                        <div className="abtested_cell abtested_end">
                            <span className="abtest_nextchild">C</span>
                            {names[2]}
                        </div>
                    }

                </>
            )

        }

        const divDupHeaders = ["Model Version", "Datasource", "Environment"];
        if (divDupHeaders.includes(headerValue)) {
            const modelDataStrings = cell;
            return (
                <>
                    <div>{modelDataStrings[0]}</div>

                    <div>{modelDataStrings[1]}</div>

                    {modelDataStrings[2] === undefined ? '' :
                        <div>{modelDataStrings[2]}</div>
                    }

                </>
            )

        }

        const emptyDivHeaders = ["Created By", "Created Time"];
        if (emptyDivHeaders.includes(headerValue)) {
            const cellValue = cell.value;
            const has3Models = cell.has3Models;
            return (
                <>
                    <div>{cellValue}</div>

                    <div />

                    {has3Models ? <div /> : ''}
                </>
            )

        }

        if (headerValue === "Actions") {
            return (
                <>
                    <ModelABEdit data={cell}
                        modelABTableProps={tableProps}
                        showToast={this.showToast}
                        handleModelABDelete={this.handleModelABDelete}
                    />

                    <div />

                    {cell.has3Models === true ? <div /> : ''}
                </>

            );
        }


        return cell;
    }

    getModelABTableIDFormatter(cell, row, rowIndex) {
        const has3Models = row["Created By"].has3Models;

        return (
            <>
                <div>{row["ID"]}</div>
                <div />
                {has3Models ? <div /> : ''}
            </>
        );
    }

    renderModelABTable(key) {
        if (this.state.mlData === null || this.state.mlDetails === null) {
            return '';
        }

        // Used in Model AB configurations
        const mlDropDown = formModelVersionMap(this.state.mlDetails);
        const tableData = this.state.model_ab_data;

        if (tableData === null) {
            return <BasicPortlet
                className="pb-0"
                id="configuredABModelsLoading"
                title={AB_MODELS_TABLE_TITLE}
                content={
                    <Load />
                }
            />
        }

        let reqKey = this.state.model_ab_updated ? "reloadAB" : "abTableNormal";
        return <div id="jump-to_configured-model-ab-testing" className="mb-15"><BasicPortlet
            className="pb-0"
            id="configuredABModelsLoading"
            title={AB_MODELS_TABLE_TITLE}
            content={
                <QualdoDataTable
                    key={reqKey}
                    handleModelABAddition={this.handleModelABAddition}
                    customGetActionComponent={this.getModelABTableComponent}
                    data={tableData}
                    tableIDFormatter={this.getModelABTableIDFormatter}
                    showToast={this.showToast}
                    paginationSize={3}
                    pageOptions={[3, 6]}
                    handleModelABDelete={this.handleModelABDelete}
                    component_name="modelABTestTable"
                    classNameForTable={"table table_custom-rowspan abtested-table"}
                    availableModels={mlDropDown["modelsDropDown"]}
                    modelsDataMap={mlDropDown["modelsDataMap"]}
                    getExistingABData={this.getExistingABData}
                    modelVersionMap={mlDropDown["modelVersionMap"]}
                    modelTypeMap={mlDropDown["modelTypeMap"]}
                    setEnvData={this.setEnvData}
                />
            }
        />
        </div>

    }

    shouldComponentUpdate() {
        if (get_api_call_cnt > 4 && get_api_call_cnt === get_api_call_complete_cnt && post_api_call_cnt === post_api_call_complete_cnt) {
            this.pre_load = false;
            return true;
        }
        else {
            return false;
        }
    }

    renderTableds(key) {
        let reqKey = 'empty';
        if (this.state.integration !== undefined && this.state.integration !== null) {
            reqKey = this.state.integration.length;
        }

        if (key === "ds" && this.state.data !== null) {
            return <div id="jump-to_configure-datasource" className="mb-15"><BasicPortlet
                className="pb-0"
                key={reqKey}
                id="configuredDatasources"
                title="Configured DataSources"
                content={
                    <QualdoDataTable
                        id="configuredDatasources"
                        data={this.state.data}
                        showToast={this.showToast}
                        environment={this.state.environment}
                        component_name="datasource"
                        updateTable={this.updateTable}
                        setEnvData={this.setEnvData}
                        setAddDS={this.setAddDS}
                    />
                }
            />
            </div>
        }
        else if (key === "ds" && this.state.data === null) {
            return <BasicPortlet
                className="pb-0"
                id="configuredDatasourcesLoading"
                title="Configured DataSources"
                content={
                    <Load />
                }
            />
        }
    }

    renderScanIntervalContent() {
        if (this.state.sleepComponentLoad) {
            return (
                <Load />
            );
        }

        return (
            <>
                <p className="mb-4">
                    Scan interval defines the minimum waiting time, in minutes,
                    between the successive scans to identify
                    refreshed datasets of the datasource
                </p>

                <Form.Group>
                    <Form.Label className="position-relative">
                        Time Interval
                        <RcTooltip
                            placement="top"
                            overlay={
                                <span>
                                    {`Set the time duration less than ${MAX_SLEEP_TIME} minutes`}
                                </span>
                            }
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                        >
                            <i className="icon-info-circle"></i>
                        </RcTooltip>
                    </Form.Label>

                    <div className="datarefresh-interval">
                        <Form.Control
                            type="number"
                            value={this.state.sleepInterval}
                            onChange={this.sleepIntervalChanged}
                            className="datarefresh-interval__form"
                        />
                        <span>minutes</span>
                    </div>

                    {
                        this.state.isSleepIntervalErrMsg.length > 0 ?
                            <span className="error-text">
                                {this.state.isSleepIntervalErrMsg}
                            </span>
                            :
                            ''
                    }
                </Form.Group>

                <div className={"mt-4 " + this.state.sleepIntervalClass}>
                    <Button
                        disabled={this.state.isSleepIntervalErrMsg.length > 0}
                        onClick={this.submitSleepInterval}
                        type="submit"
                        className="btn-primary btn-circle mr-2"
                    >
                        <i>
                            <FontAwesomeIcon icon={faCheck} />
                        </i>
                        Save Changes
                    </Button>
                    <Button
                        onClick={this.cancelSleepInterval}
                        type="submit"
                        className="btn-outline btn-grey btn-circle"
                    >Cancel</Button>
                </div>
            </>
        );
    }

    renderScanIntervalComponents() {
        return (
            <div id="jump-to_datarefresh-interval" className="mb-3">
                <BasicPortlet
                    title="Data Refresh Interval"
                    content={this.renderScanIntervalContent()}
                />
            </div>
        );
    }

    renderRefreshTable(data, key) {
        let reqKey = 'empty';
        if (this.state.integration !== undefined && this.state.integration !== null) {
            reqKey = this.state.integration.length;
        }
        if (key === 'data') {
            return (
                <div id="jump-to_processerror" >
                    <BasicPortlet
                        id={this.state.datasourceErrorRefreshesId}
                        key={reqKey}
                        title="Processing Errors & Data Refresh Status"
                        content={
                            (data === null) ?
                                <Load />
                                :
                                (data.length === 0 ?
                                    <NoErrorInPreview />
                                    :
                                    <RefreshesTable componentName="datasource"
                                        data={data}
                                        id={this.state.datasourceErrorRefreshesId}
                                        updateStatus={this.state.updateStatus}
                                    />
                                )
                        }
                    />
                </div>
            )
        }
        else if(key==='ml'){
            return(
                <div id="jump-to_model-processing-errors">
                    <BasicPortlet
                        id={this.state.modelRefreshesId}
                        title="Model Processing Errors & Refresh Status"
                        content={
                            (data === null
                                ?
                                <Load />
                                :
                                (data.length === 0 ?
                                    <NoErrorInPreview />
                                    :
                                    <RefreshesTable componentName="model"
                                        data={data}
                                        id={this.state.modelRefreshesId}
                                        updateStatus={this.state.updateStatus}

                                    />
                                )
                            )
                        }
                    />
                </div>
            )
        }

        if(data!== null && data.length>0){
            this.setState({updateStatus:false})
        }
    }

    renderTableml(key) {

        if (key === "ml" && this.state.mlData !== null) {
            const mlDropDown = formModelVersionMap(this.state.mlDetails);
            return <div id="jump-to_configuredModels" className="mb-15">
                <BasicPortlet
                    className="pb-0"
                    id="configuredModels"
                    title="Configured Models"
                    content={
                        <QualdoDataTable
                            showModelABPopup={this.showModelABConfigureSection}
                            handleModelABAddition={this.handleModelABAddition}
                            modelHasModelABConfiguration={this.modelHasModelABConfiguration}
                            data={this.state.mlData}
                            showToast={this.showToast}
                            integrationOption={this.state.integration_option}
                            environment={this.state.environment}
                            component_name="ml_model"
                            datasets={this.state.datasets}
                            availableModels={mlDropDown["modelsDropDown"]}
                            modelsDataMap={mlDropDown["modelsDataMap"]}
                            getExistingABData={this.getExistingABData}
                            modelVersionMap={mlDropDown["modelVersionMap"]}
                            modelTypeMap={mlDropDown["modelTypeMap"]}
                            attributes={this.state.attributes}
                        />
                    }
                />
            </div>

        }
        else if (key === "ml" && this.state.mlData === null) {
            return <BasicPortlet
                className="pb-0"
                id="configuredModelsLoading"
                title="Configured Models"
                content={
                    <Load />
                }
            />
        }
    }

    handleTab(key) {
        postMixpanel("Configure -> " + key + "tab Page")
        this.setState({ current_tab: key });
    }

    sectionNavigation() {
        document.querySelectorAll('.breadcrumb-direct-section li a[href*="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                var topOfElement = document.querySelector(this.getAttribute('href')).offsetTop;
                window.scroll({ top: topOfElement - 80, behavior: "smooth" });
            });
        });
    }

    render() {
        let hideConfPageModelTab = true;
        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const confPageData = this.state.featureAccess["models"];
            hideConfPageModelTab = confPageData === false;
        }
        return (
            this.state.errorOccurred ?
                <ErrorHandler />
                :
                <>
                    <div id="toastMsg">
                        <Toast className={this.state.toastClass}
                            onClose={() => this.closeToast()}
                            show={this.state.toastStatus}
                            delay={3000} autohide>
                            <div className="d-flex align-items-center">
                                <i className="toast-icon">
                                    {this.state.toastIcon ?
                                        <FontAwesomeIcon icon={this.state.toastIcon} /> :
                                        ""
                                    }
                                </i>
                                <Toast.Body>
                                    <strong className="toast-title">{this.state.toastHeading}</strong>
                                    {this.state.toastBulk === "bulk" ?
                                        this.state.toastMsg.map(function (row) {
                                            return <p>{row.message}</p>;
                                        }) : <p>{this.state.toastMsg}</p>
                                    }
                                </Toast.Body>
                                <button type="button" onClick={this.closeToast} className="close" data-dismiss="toast" aria-label="Close" />
                            </div>
                        </Toast>
                    </div>
                    <NavigationHeader page="configure" prefetch_load={this.pre_load} />
                    <main>
                        <BreadCrumb icon='cog' title='Configure' />
                        <Tab.Container defaultActiveKey={this.state.current_tab} onSelect={this.handleTab}>
                            <div className="qd-tab__header">
                                <div className="container-fluid">
                                    <div className="qd-tab__header-wrapper">
                                        <div className="qd-tab__header-menu">
                                            <Nav variant="tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="ds" >
                                                        <span className="qd-menu__link-text">Datasources</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {hideConfPageModelTab ?
                                                    '' :
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="ml">
                                                            <span className="qd-menu__link-text">Models</span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                }

                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="qd-container">
                                <div className="qd-body">
                                    <div className="qd-tab__content">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="ds">
                                                <nav aria-label="breadcrumb" className="id__nav-breadcrumb breadcrumb-direct-section">
                                                    <ol className="breadcrumb bg-transparent">
                                                        <li className="breadcrumb-item"><a href="#jump-to_datasource">Add Datasources</a></li>
                                                        <li className="breadcrumb-item"><a href="#jump-to_configure-datasource">Edit Datasource</a></li>

                                                        {IS_NONE_SAAS_EDITION ?
                                                            <li className="breadcrumb-item"><a href="#jump-to_datarefresh-interval">Data Refresh Interval</a></li>
                                                            :
                                                            ''
                                                        }

                                                        <li className="breadcrumb-item"><a href="#jump-to_processerror">Processing Errors &amp; Data Refresh Status</a></li>
                                                    </ol>
                                                </nav>
                                                <>
                                                    <div id="jump-to_datasource" className="mb-15">
                                                        <BasicPortlet
                                                            className="pb-0" title="Datasources"
                                                            id="datasourceIndex"
                                                            content={
                                                                <DSEnvironment
                                                                    environment={this.state.environment}
                                                                    updateEnvironmentList={this.updateEnvironmentList}
                                                                    setDataLimit={this.setDataLimit}
                                                                    setEnvData={this.setEnvData}
                                                                    planName={this.state.planName}
                                                                    showToast={this.showToast}
                                                                    router={this.props.router}
                                                                    setPage={this.setAddDS}
                                                                    dataLimitExceed={this.state.dataLimitExceed} />
                                                            }
                                                        />
                                                    </div>
                                                </>
                                                {

                                                    this.state.isAddDS ?
                                                        <AddDataSources
                                                            props={this.props}
                                                            showToast={this.showToast}
                                                            showCancel={true}
                                                            setPage={this.setAddDS}
                                                            setEnvData={this.setEnvData}
                                                            env_id={this.state.env_id}
                                                            isAddDS={this.state.isAddDS}
                                                            existingIntegrations={this.state.integration_details} />
                                                        : ''
                                                }
                                                {this.renderTableds(this.state.current_tab)}

                                                {
                                                    IS_NONE_SAAS_EDITION ?
                                                        this.renderScanIntervalComponents() : ''
                                                }

                                                {this.renderRefreshTable(this.state.datasource_updates, 'data')}

                                        </Tab.Pane>
                                        {hideConfPageModelTab ?
                                                    '' :
                                        <Tab.Pane eventKey="ml">
                                        <nav aria-label="breadcrumb" className="id__nav-breadcrumb breadcrumb-direct-section">
                                        <ol className="breadcrumb bg-transparent">
                                        <li className="breadcrumb-item"><a href="#jump-to_models">Add Model</a></li>
                                        <li className="breadcrumb-item"><a href="#jump-to_configuredModels">Edit Model</a></li>
                                        <li className="breadcrumb-item"><a href="#jump-to_configured-model-ab-testing">Add a Version</a></li>
                                        <li className="breadcrumb-item"><a href="#jump-to_configured-model-ab-testing">Add AB Testing</a></li>
                                        <li className="breadcrumb-item"><a href="#jump-to_model-processing-errors">Processing Errors &amp; Refresh Status</a></li>
                                        </ol>
                                        </nav>
                                            <div id="jump-to_models" className="mb-15">
                                            <BasicPortlet
                                                className="pb-0" title="Models" id="modelIndex"
                                                content={
                                                    <MLModelEnvironment
                                                        maxModel={this.state.maxModel}
                                                        planName={this.state.planName}
                                                        updateEnvironmentList={this.updateEnvironmentList}
                                                        environment={this.state.environment}
                                                        showToast={this.showToast}
                                                        router={this.props.router}
                                                        setPage={this.setAddModel}
                                                        setModelVersion={this.setModelVersion}
                                                        change_request_exists={this.state.change_request_exists}
                                                        mlLimitExceed={this.state.mlLimitExceed}/>
                                                }
                                            />

                                                </div>
                                                {
                                                    this.state.isAddModel ?
                                                        <BasicPortlet
                                                            className="pb-3" title="Add Model" id="addModel" bodyClassName="pb-0"
                                                            content={<AddModel showToast={this.showToast}
                                                                integrationOption={this.state.integration_option}
                                                                setPage={this.setAddModel}
                                                                prefetch={this.props}
                                                                datasets={this.state.datasets}
                                                                attributes={this.state.attributes} />
                                                            }
                                                        /> : ''
                                                }

                                            {
                                                this.state.isModelVersion ?
                                                    <BasicPortlet
                                                        className="pb-3"
                                                        title="Create another version of the existing model"
                                                        id="createModelVersion"
                                                        bodyClassName="pb-0"
                                                        content={<AddModel showToast={this.showToast}
                                                                           integrationOption={this.state.integration_option}
                                                                           setPage={this.setModelVersion}
                                                                           prefetch={this.props}
                                                                           datasets={this.state.datasets}
                                                                           attributes={this.state.attributes}/>
                                                        }
                                                    /> : ''
                                            }

                                                {this.renderTableml(this.state.current_tab)}

                                                {this.renderModelABTable(this.state.current_tab)}

                                            {this.renderRefreshTable(this.state.model_updates,'ml')}
                                        </Tab.Pane>
                                        }
                                        <Tab.Pane eventKey="widget">
                                            <URLWidget title="Dashboard"/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="api">
                                            <URLWidget title="API"/>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>

                </>
        );
    }
}

//export default Configure;

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps, {
    addMetaData,
    addMLModelMapping,
    setMLModel,
    setModelABDataToList,
    addEnvironment,
    addEnvironmentDataSourceMapping,
    addDataSourceDataSetMapping,
    addDataSetAttributeMapping,
    setDataSet,
    setAttribute,
    setDataSource,
    setMlDataSource,
    addRelationship,
    addDataSource,
    addInfo,
    addLastProfilingTime,
    setStartDate,
    setEndDate,
    addDiscoveryStatus,
    addProcessingStatus,
    addMonitorPreview,
    addPerformancePreview,
    addModelPerformance,
    addCompletenessDataSetInfo,
    addCompletenessAttributeInfo,
    addTimelinessDataSetInfo,
    addTimelinessAttributeInfo,
    addAccuracyDataSetInfo,
    addAccuracyAttributeInfo,
    addConformityDataSetInfo,
    addConformityAttributeInfo,
    addConsistencyDataSetInfo,
    addConsistencyAttributeInfo,
    addDriftDataSetInfo,
    addDriftAttributeInfo,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addUniquenessDataSetInfo,
    addUniquenessAttributeInfo,
    addRecencyPreview,
    addMLModel,
    addModelDetails,
    addMetrics,
    addMonitorDQListViewData,
    prefetchMonitorState,
    noIntegration,
    mappedDatasetInfo,
    listViewTableData
}
)(Configure);
